<template>
<div>
    <v-layout column>
        <v-card v-if="false" :height="vsh-250" color="b1">
            <mbs-page-spinner/> 
        </v-card>
        <v-layout v-else column>  
            <!-- PROFILE -->
            <v-container grid-list-lg v-if="us">
                <v-layout row wrap class="mt-3">
                    <!-- PROFILE PICTURE--> 
                    <v-flex sm12 md6    id="profilePicture">
                        <v-hover>
                            <template v-slot="{ hover}">
                                <v-card tile class=" " height="100%"
                                    :elevation="hover?12:''"> 
                                    <!--upload image -->
                                    <v-responsive  v-if="loadingImage"
                                        aspect-ratio="1" 
                                        class="b2" >   
                                        <v-layout   
                                            fill-height 
                                            align-center
                                            justify-center
                                            ma-0 > 
                                            <v-progress-circular  
                                                :rotate="-90"
                                                :size="100"
                                                :width="15"
                                                :value="processValue"
                                                color="white"
                                                >
                                                {{ processValue }}
                                            </v-progress-circular> 
                                        </v-layout>
                                    </v-responsive>

                                    <!-- IF IMAGE --> 
                                    <div v-else>
                                        <!-- IF IMAGE --> 
                                        <v-img v-if="false" 
                                            aspect-ratio="1" 
                                            :src="ud.photoURL"
                                            lazy-src="https://picsum.photos/10/6?image=3"  
                                            >
                                            <!-- img loader -->
                                            <v-layout
                                                slot="placeholder"
                                                fill-height
                                                align-center
                                                justify-center
                                                ma-0 >
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="grey lighten-5">
                                                </v-progress-circular>
                                            </v-layout> 

                                             
                                            <v-layout 
                                                column
                                                fill-height  >
                                                <v-card-title>
                                                    <v-spacer></v-spacer>
                                                    <v-btn 
                                                        dark  
                                                        @click="deleteUserProfilePicture()"
                                                        icon class="mr-3">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                    <v-btn 
                                                        dark @click="addImage()" 
                                                        icon class="mr-3">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn> 
                                                </v-card-title> 
                                                <v-spacer></v-spacer> 
                                                <v-card-title class="white--text pl-5 pt-5">
                                                    <div class="display-1 pl-5 pt-5">{{MBS.actions.TEXT_UP11(us?us.displayName:'..')}}</div>
                                                </v-card-title>
                                            </v-layout> 
                                            <v-overlay absolute z-index="-1">
                                            </v-overlay> 
                                        </v-img>

                                        <!-- //NO IMAGE  -->
                                        <v-responsive  v-else   
                                            class="primary px-3"
                                            aspect-ratio="1" > 
                                            <v-layout column fill-height  >
                                                <v-card-title>
                                                    <v-spacer></v-spacer> 
                                                    <!-- <v-btn 
                                                        dark @click="addImage()" 
                                                        icon class="">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>  -->
                                                </v-card-title>
                                                <v-layout fill-height justify-center align-center>
                                                    <!-- <span class="white--text headline">
                                                        {{MBS.actions.TEXT_UP11(us.displayName ? us.displayName[0] : "..")}}
                                                    </span>  -->
                                                </v-layout>
                                                <v-card-title class="white--text">
                                                    <div class="not-f8">{{SelectedCompany?SelectedCompany.displayName:'..'}}</div>
                                                </v-card-title>  
                                            </v-layout>
                                        </v-responsive> 
                                    </div> 

                                    <!-- INFO --> 
                                    <div  class="" >
                                        <v-toolbar v-if="false"  flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >Contacts</v-toolbar-title>
                                            <v-spacer></v-spacer> 
                                        </v-toolbar>
                                        <v-list two-line> 
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">event</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Registered on:</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{MBS.actions.convertDate(SelectedCompany.created_at?
                                                        SelectedCompany.created_at:us.metadata.creationTime)}}
                                                </v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset/> 

                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">event_available</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Updated on</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{MBS.actions.convertDate(SelectedCompany.updated_at?
                                                        SelectedCompany.updated_at:us.metadata.creationTime)}}
                                                </v-list-item-subtitle>
                                                </v-list-item-content> 
                                            </v-list-item>  
                                            <v-divider inset/> 
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-key-link</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Key</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.key}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                        </v-list>
                                    </div> 
                                </v-card> 
                            </template>
                        </v-hover>
                    </v-flex>

                    <!-- USER INFO -->
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0"> 
                            <!-- Contact -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >{{MBS.actions.TEXT_UP11('Company Information')}}</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-btn dark
                                                color="primary"  outlined
                                                icon="" @click="editCompanyInformation()"> 
                                                <v-icon>edit</v-icon>
                                            </v-btn> 
                                        </v-toolbar>
                                        <v-list two-line>  
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">domain</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Name</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_name}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">phone</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Phone number </v-list-item-title>
                                                <v-list-item-subtitle>(Cell): {{SelectedCompany.company_phone_number}}</v-list-item-subtitle>
                                                <v-list-item-subtitle>(Tell): {{SelectedCompany.company_phone_number2}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">email</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Email Address</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_email_address}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">inbox</v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Post Address</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_post_address}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-office-building-marker </v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>location Address</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_location_address}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-currency-usd </v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Company_tpin</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_tpin}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-account-group </v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Company Size</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_size}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item> 
                                            <v-divider inset></v-divider>
                                            
                                            <v-list-item  >
                                                <v-list-item-action>
                                                <v-icon color="primary">mdi-shield-home </v-icon>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Company Type</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_type}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item>  
                                            
                                            <v-list-item  v-if="SelectedCompany.company_logo" >
                                                <v-list-item-action>
                                                    <v-img con  :src="SelectedCompany.company_logo" width="50"/>
                                                </v-list-item-action> 
                                                <v-list-item-content>
                                                <v-list-item-title>Company Logo</v-list-item-title>
                                                <v-list-item-subtitle>{{SelectedCompany.company_logo}}</v-list-item-subtitle>
                                                </v-list-item-content>  
                                            </v-list-item>  
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                </v-layout> 
            </v-container>
 
        </v-layout> 
    </v-layout>  
    <!-- DIALOG -->
    <v-layout column justify-center v-if="us">
        <!-- image dialog -->
        <mbs-image-crop
            :height="250"
            :width="250"
            :noRotate='false'
            @crop-success="cropSuccess"
            v-model="dialogImageCrop"
            imgFormat="jpg"
            lang-type="en" />
            
         
        <!-- User Info dialog -->
        <v-dialog v-model="dialogCompanyInf" persistent max-width="800px"> 
            <v-card v-if="dialogCompanyInf">
                <v-card-title class="primary lighten-1 white--text"> 
                    <span class="headline">UPDATE COMPANY INFO</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogCompanyInf=false" color="white"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text > 
                    <v-container grid-list-md>
                        <form id="personalForm" @submit.prevent="submitUserInfo()">
                            <v-layout row wrap>
                                <v-flex xs12 >
                                    <v-text-field 
                                        filled  
                                        v-model="input.company_name" 
                                        type="text" 
                                        required 
                                        label="Company Name"/> 
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_phone_number"  
                                        type="text"
                                        required 
                                        label="Phone Number (Cell)"/>   
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_phone_number2"  
                                        type="text"
                                        required 
                                        label="Phone Number (Tell)"/>   
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_email_address"  
                                        type="text"
                                        required 
                                        label="Email Address"/>   
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_post_address"  
                                        type="text"
                                        required 
                                        label="Post Address"/>   
                                </v-flex>
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_location_address"  
                                        type="text"
                                        required 
                                        label="Location Address"/>   
                                </v-flex> 
                                <v-flex xs12 sm6>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_tpin"  
                                        type="text"
                                        required 
                                        label="Company Tpin"/>   
                                </v-flex> 
                                <v-flex xs12 sm6>
                                    <v-autocomplete  
                                        filled  
                                        :items="CompanyTypes"
                                        v-model="input.company_type"
                                        item-text="name"
                                        item-value="code"
                                        label="Company Type"  required  />  
                                </v-flex> 
                                <v-flex xs12 sm6>
                                    <v-autocomplete  
                                        filled  
                                        :items="CompanySizes"
                                        v-model="input.company_size"
                                        item-text="name"
                                        item-value="code"
                                        label="Company Size" required   />  
                                </v-flex> 
                                <v-flex xs12 sm12>
                                    <v-text-field 
                                        filled   
                                        v-model="input.company_logo"  
                                        type="text"
                                        required 
                                        label="Company Logo Link"/>   
                                </v-flex> 
                            </v-layout>  
                            <v-layout class="px-1">
                                <v-spacer></v-spacer>
                                <v-btn text
                                    @click.native="dialogCompanyInf=false">
                                    cancel 
                                </v-btn>
                                <v-btn 
                                    :loading="input.loading" 
                                    type="submit"
                                    color="primary">
                                    Update info 
                                </v-btn>  
                            </v-layout> 
                        </form> 
                    </v-container> 
                </v-card-text> 
            </v-card>
        </v-dialog>  
    </v-layout> 
</div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'  
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "COMPANY_PROFILE" 
    export default {
        name:PAGE_NAME, 
        data() {
            return { 
                page_current:1,
                page_size:10,
                DATA:DATA,
                processValue:0,
                loadingImage:false,

                dialogCompanyInf:false,
                dialogNextOfKin:false,
                dialogIdInfo:false,
                dialogUserPhoneNumber:false,
                dialogUserEmail:false,
                dialogUserPassword:false,
                dialogUserAddress:false,
                dialogImageCrop:false,
                menu_date_picker:false,
                menu_id_issued_date:false,
                menu_id_expiring_date:false,
                tab: null, 
                input:{}, 
                inputLoading:false,
                inputPhone:'',
                genderType:['Male','Female'],
                relationship:['Family Member','Friend'], 
                select:{
                    signOption:'',
                    accountType:'',
                    businessType:'',
                    country:''
                },
                mask: {
                    price:'########',
                    year:'####',
                    code:'######',
                    phone:'phone',
                    name:'Nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn',
                    date:'date',
                    yEmail:'nnnnnnnnnnnnnnnnnnnn'
                }, 
                map:{
                    latitude:['N','S'],
                    longitude:['W','E'],
                },
                BROCK_NO_STATUS:false,
                currentPassword:true,
                newPassword:true,
                confirmPassword:true,
            }
        },
        created() {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
            this.MBS.actions.setPAGE_LOAD(false)
        },
        mounted() {
            this.MBS.actions.header({
              show:true,
              type:'subToolbar',
              images:this.items,
              height:400 
            })
           this.FEEDBACKS(1000)
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
            }), 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },

            CompanyTypes(){ 
                const re = this.$store.getters.getCompanyTypes
                return re
            },
            CompanySizes(){ 
                const re = this.$store.getters.getCompanySizes
                return re
            },






            PAGE_LOAD(){
                let load = this.$store.getters.getPAGE_LOAD
                return load
            }, 
             
            ACTIONS(){
                return this.MBS.actions
            }, 
            EVENTS(){
                return this.MBS.events
            },
            IdTypes(){ 
                let items = this.$store.getters.getIdTypes
                return ["National ID","Passport","Driving License"]
            },
            getIdType(){ 
                let IdTypes = this.IdTypes
                if(!IdTypes){return null}
                return id_value =>{
                    let item = IdTypes.find(idType=>{
                        return idType.value == id_value
                    })
                    return item
                }
            },
            userGroup(){
                let ud = this.ud
                let user_group_code = ud?ud.user_group_code:null 
                let user_group = this.$store.getters.getUserGroupByCode(user_group_code)
                return user_group
            },
            districts(){ 
                const re = this.$store.getters.getDistricts
                return re
            },
            countryNames(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            countryNames2(){
                let names = []
                let countries = this.$store.getters.getCountries 
                for (const key in countries) {
                    const cn = countries[key]
                    if (cn.alpha2!== undefined) {
                        names.push(cn.alpha2+': '+cn.name) 
                    } 
                } 
                //names=['Mw: Malawi (+265)']
                return names
            }, 
            iLocation(){ 
                const i = this.$store.getters.getIpLocation
                return i
            },
             
            GET_PHONE_NUMBER(){
                try {
                    let us = this.us  
                    let input_country = ''+this.select.country
                    let input_phone = ''+this.inputPhone 
                    let countryNames = this.countryNames 
                    let PHONE = this.MBS.phone 
                    return all =>{
                        let current_phone = ''+input_phone 
                        let ip_phone = null 
                        let ip_countryPhone = null 

                        if (input_phone) {
                            input_phone = input_phone.replace(/[^0-9+]/g,'')
                        }
                        if (input_country) {
                            input_country = input_country.replace(/[^0-9]/g,'')
                        } 

                        if (input_phone) { 
                            ip_phone = PHONE(input_phone)
                        }
                        if (input_country) { 
                            ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                        } 

                        if (ip_phone) {
                            let possible = ip_phone.g?ip_phone.g.possible:null
                            if (possible) {
                                current_phone = ip_phone.g

                                if (countryNames) { 
                                    let regionCode = current_phone.regionCode
                                    let countryName = countryNames.find(item=>{
                                        return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                    })
                                    if (countryName != this.select.country) {
                                        this.select.country = countryName
                                    } 
                                }
                            }
                        }
                        if (ip_countryPhone) {
                            let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                            if (possible) {
                                current_phone = ip_countryPhone.g
                            }
                        } 

                        if (current_phone.valid) {
                            if (this.inputPhone != current_phone.number.international ) { 
                                this.inputPhone = current_phone.number.international
                            }
                        } 

                        let returnData = {
                            ...current_phone,
                            ...current_phone.number,
                            phone:current_phone.number?current_phone.number.e164:'',
                            phone_number:current_phone.number?current_phone.number.e164:'',
                        }
                        // console.log(input_phone,'input_phone.........');
                        // console.log(input_country,'input_country.........');  
                        // console.log(ip_countryPhone,'ip_countryPhone.........');
                        // console.log(ip_phone,'ip_phone.........');  
                        // console.log(current_phone,'current_phone.........'); 
                        // console.log(returnData,'returnData.........'); 

                        if (all) {
                            return returnData
                        } else {
                            return current_phone.number?current_phone.number.e164:''
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_PHONE_NUMBER',
                        page:PAGE_NAME, 
                    })
                }  
            },
        },
        methods: { 
            addImage(){ 
                this.dialogImageCrop = true
            },
            editCompanyInformation(){
                let company = this.SelectedCompany
                this.input = {}
                if (company) {
                    this.input.company_name=company?company.company_name:'' 
                    this.input.company_key=company?company.company_key:'' 
                    this.input.company_phone_number=company?company.company_phone_number:'' 
                    this.input.company_phone_number2=company?company.company_phone_number2:'' 
                    this.input.company_email_address=company?company.company_email_address:'' 
                    this.input.company_location_address=company?company.company_location_address:'' 
                    this.input.company_post_address=company?company.company_post_address:'' 
                    this.input.company_type=company?company.company_type:'' 
                    this.input.company_size=company?company.company_size:'' 
                    this.input.company_tpin=company?company.company_tpin:'' 
                    this.input.company_logo=company?company.company_logo:'' 
                    this.dialogCompanyInf = true   
                }
            }, 
            submitUserInfo(){ 
                let data = {
                    company_name:this.input.company_name,
                    company_phone_number:this.input.company_phone_number,
                    company_phone_number2:this.input.company_phone_number2,
                    company_email_address:this.input.company_email_address,
                    company_location_address:this.input.company_location_address,
                    company_post_address:this.input.company_post_address,
                    company_type:this.input.company_type,
                    company_size:this.input.company_size,
                    company_tpin:this.input.company_tpin,
                    company_logo:this.input.company_logo,
                }
                let mbs_text = this.MBS.text.update_item("Company Information")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-COMPANY-INFO",
                        data:data 
                    }

                })
                
            }, 
            cropSuccess(imgDataUrl, field){  
                this.imgDataUrl = imgDataUrl; 
                this.$store.dispatch('fi_updateUserProfilePicture',{
                    data:{image:imgDataUrl}
                }) 
            }, 

            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) { 
                        if (action.code ===PAGE_NAME+"=UPDATE-COMPANY-INFO") {
                            if (action.YES) { 
                                setTimeout(() => {  
                                        let f_detail = {
                                            action:DATA.ITEMS.COMPANIES.value,
                                            path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_info,true),
                                            data:action.data,
                                            us:this.us
                                        } 
                                        console.log(f_detail);
                                        this.MBS.actions.progressDialog({
                                            show:true,
                                            text:"Updating..."
                                        })
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_update",f_detail)
                                        .then(res=>{ 
                                            console.log(res,'r...');
                                            let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.COMPANIES.value)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes,  
                                            }) 
                                            this.input.loading = false
                                            this.dialog_add_item = false 
                                            this.generatedCode = null 
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses() 
                                        })
                                        .catch(error=>{ 
                                            console.log(error,'e...');
                                            let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.COMPANIES.value)
                                            this.MBS.actions.dialog({
                                                show:true,
                                                fixed:true,
                                                title:mbs_text.title,
                                                text:mbs_text.text,
                                                btnYes:mbs_text.btnYes, 
                                            })  
                                            this.input.loading = false
                                            this.MBS.actions.progressDialog() 
                                            this.MBS.actions.restartResponses()

                                        })
                                    }, this.MBS.data.ACTION_REFRESH_TIME);  
                                this.$store.dispatch('fi_updateUserProfile',{
                                    data:action.data
                                }) 
                            } 
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            },  
            FEEDBACKS(t){
                try {
                    if (!t) {
                        t=400
                    }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses  
                        let i = this.iLocation  
                        
                    },t)
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: {
            us(v){
                this.FEEDBACKS(1)
            },
            ud(v){
                this.FEEDBACKS(1)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 