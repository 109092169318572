import Vue from 'vue';  
import { store } from '../store';
import moment from 'moment';
import { toNumber } from './formatNumber';
let NAME = "ACTION-BUS"

export const EventBus = new Vue({
    data(){
        return{
            INPUT_TEXT : '',
            INPUT_SPACIAL_KEYS : [],
            INPUT_TIMER : true,
            INPUT_NEW_TEXT : true,
            QR_SCAN_STARTED : false,

        }
    },
    created(){ 
        this.ON_KEY_DOWN()
    },

    methods:{
        error(error){
            if(!error){return}
            let err = error.error?error.error:'...'
            let from = error.from?error.from:'...'
            let page = error.page?error.page:'...'
            store.dispatch('ERROR',{
                error:err,
                from:from,
                page:page
            })
        },
        ON_KEY_DOWN(){ 
            try {  
                if (!this.QR_SCAN_STARTED) { 
                    this.QR_SCAN_STARTED = true
                    // document.addEventListener("keydown", function(e) {
                    window.addEventListener("keydown", function(e) {
                        let textInput = e.key  
                        const timeStamp = e.timeStamp; 
                        
                        EventBus.$emit('ON_KEY_DOWN',textInput,timeStamp)
                        if (this.INPUT_TEXT == undefined||this.INPUT_TEXT == null||this.INPUT_TEXT == 'undefined') {
                            this.INPUT_TEXT = '' 
                        }if (this.INPUT_SPACIAL_KEYS == undefined||this.INPUT_SPACIAL_KEYS == null||this.INPUT_SPACIAL_KEYS == 'undefined') {
                            this.INPUT_SPACIAL_KEYS = []
                        }
                         


                        if (!this.INPUT_TIMER) {
                            this.INPUT_TIMER = moment().valueOf() 
                        } else {
                            let dif =toNumber(moment().valueOf())-toNumber(this.INPUT_TIMER) 
                            if (dif>100) {
                                this.INPUT_TEXT=''
                                this.INPUT_SPACIAL_KEYS=[]
                            } 
                            this.INPUT_TIMER = moment().valueOf() 
                        }
 
                        if (textInput==='Enter') { 
                            this.INPUT_SPACIAL_KEYS.push(textInput)
                            EventBus.$emit('ON_QR_SCAN',this.INPUT_TEXT,this.INPUT_SPACIAL_KEYS,timeStamp) 
                            this.INPUT_TEXT=''
                            this.INPUT_SPACIAL_KEYS=[]
                            this.INPUT_NEW_TEXT = true 
                        }else if (textInput==='Shift') {
                            this.INPUT_SPACIAL_KEYS.push(textInput)   
                        }else if (textInput==='NumLock') {
                            this.INPUT_SPACIAL_KEYS.push(textInput) 
                        }else if (textInput==='CapsLock') {
                            this.INPUT_SPACIAL_KEYS.push(textInput) 
                        }else{ 
                            this.INPUT_TEXT = this.INPUT_TEXT+textInput
                            this.INPUT_NEW_TEXT = false
                        } 
                        EventBus.$emit('ON_TYPING',this.textInput,timeStamp) 
                    })
                } 
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_KEY_DOWN',
                    page:NAME, 
                })  
            } 
        }
    }
});
 