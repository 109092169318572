<template>
    <div>   
        <v-container grid-list-xl> 
            <!-- <v-btn color="success"  @click="DELETE_ALL_SALES">DELETE_ALL_SALES</v-btn> -->
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="DayShiftsDATA"   
                            :path_item="SALES_PATH"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from "../../../../../plugins/DATA"
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.DAYS_SHIFTS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.DAYS_SHIFTS.names,
                NAME_ITEM:DATA.ITEMS.DAYS_SHIFTS.name,
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"btn",action:"start_day",color:"secondary", outlined:false,  text:"Start a Day"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
            }), 
            DayShiftsDATA(){ 
                let items = this.JoinedDayShifts?this.JoinedDayShifts:this.DayShifts
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                console.log(list,'list...');
                return list
            },  

            
             
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sales",3,true)  
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",tile:true,size:40,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Open",value:'local_created_at_',show:true},   
                    {id:0,name:"close",value:'time_closed',show:true},   
                    {id:0,name:"Operational",value:'operating_date_',show:true},     
                    {id:0,name:"Outlet",value:"outlet_name",show:false},      
                    {id:0,name:"code",value:"key",align:"center",show:false},         
                    {id:0,name:"User",value:"user_name",show:true},      
                    {id:0,name:"Floating",value:'total_floating_cash_',align:'right',show:true},    
                    {id:0,name:"Close Cash",value:'total_closing_cash_',align:'right',show:true},    
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Report",action:"view_report",icon:"mdi-eye",show_if:item=>item.closed}, 
                        {name:"X Read",action:"x_read",icon:"mdi-eye",show_if:item=>!item.closed}, 
                        {name:"Z Read",action:"z_read",icon:"link",show_if:item=>!item.closed},   
                    ]}, 
                ]
            }, 
            DayShifts(){ 
                let items = this.$store.getters.getDayShifts
                if(!items){return null}
                let join = this.MBS.actions.JOIN_DAY_SHIFTS(items)
                let list = [...join]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list
            },  
            MyCashups(){
                let orders = this.DayShifts 
                let us = this.us
                let uid = us?us.uid:null 
                if(!orders||!uid){return null}
                let filtered_items = orders.filter(cashup=>{
                    return !cashup.archive
                })
                return filtered_items
            }  
        },
        methods: { 
            async DELETE_ALL_SALES(){
                try {
                    let items = this.DayShiftsDATA
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                     
                    console.log(items,size,"no items.......");
                    if(!items){
                    } 

                    // return
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Deleting ("+size+"/"+progress+") ..."
                    })

                    // let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.,true)
                    // let path = "/MPOS/COMPANY_DATA/all-supplieers/ACCOUNTS/DAYS_SHIFT_SALES/"
                    // let path = "/MPOS/COMPANY_DATA/tcg-butchershop/ACCOUNTS/DAYS_SHIFT_SALES/"
                    let path = "/MPOS/COMPANY_DATA/genovese-investments/ACCOUNTS/DAYS_SHIFT_SALES/"
                    let path2 = "/MPOS/COMPANY_DATA/genovese-investments/ACCOUNTS/DAYS_SHIFTS/"

                    
                    for (const item of items) {
                        let res = await this.$store.dispatch('fi_delete',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path+item.key, 
                        }) 
                        await this.$store.dispatch('fi_delete',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path2+item.key, 
                        }) 
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"Deleting ("+size+"/"+progress+") ..."
                        })
                        console.log(res,'res....');
                    }
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            },
            TABLE_ACTION(action,item){
                try { 
                    if (action == "start_day") {
                        let link = this.MBS.actions.COMPANY_LINK("/sale/start-day",true)
                        this.MBS.actions.go(link)
                    }else if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.DAYS_SHIFTS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-DAY-SHIFTS", 
                            } 
                        })
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    if (action == "view_report") {
                        let link = "/day-shift-report/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }if (action == "x_read") {
                        let link = "/x-read/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }if (action == "z_read") {
                        let link = "/z-read/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RELOAD_DAY_SHIFTS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.DAYS_SHIFTS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.DAYS_SHIFTS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-DAY-SHIFTS') {
                            if (action.YES) {
                                this.RELOAD_DAY_SHIFTS()
                            } else {
                                
                            }
                        }    
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
