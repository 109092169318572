<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="Transactions"   
                            :path_item="QUOTATIONS_AND_INVOICES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"    
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- GIVEN AMOUNT -->
            <v-dialog
                v-model="dialog_pay_bill"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">Pay Bill</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_pay_bill=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text v-if="selected_item">
                        <br>
                        <v-list v-if="selected_item" dense class="mb-5" >
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Payee</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.payee_name}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Amount</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.amount_}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Paid</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.total_paid_}}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title>Balance</v-list-item-title>
                                    <v-list-item-subtitle>{{selected_item.balance_}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>  
                        <v-divider class="mx-2 mb-5"></v-divider>
                        <form @submit.prevent="MAKE_PAYMENT(selected_item,input)">
                            <v-layout row wrap class="ma-0 ">
                                <v-flex xs6 sm12>
                                    <div class="ml-2">Payment Option</div>
                                    <v-autocomplete required
                                        v-model="input.payment_method_code"
                                        :items="PaymentMethodsData"
                                        item-text="name"
                                        item-value="code"
                                        filled  label="Selected Payment Option"
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-autocomplete> 
                                </v-flex>
                                <v-flex xs6>
                                    <div class="ml-2">Pay amount</div>
                                    <v-text-field required
                                        v-model="input.amount"
                                        filled  
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-text-field> 
                                </v-flex>
                                <v-flex xs6>
                                    <div class="ml-2">Balance After Pay</div>
                                    <v-text-field   
                                        :value="MBS.actions.money(selected_item.balance-toNumber(input.amount))"
                                        filled   disabled
                                        v-mask="currencyMask"
                                        hint="0.00" class="mx-2"
                                    ></v-text-field>  
                                </v-flex> 
                            </v-layout> 
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Make Payment</v-btn>
                            </v-layout>
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.BILLS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.BILLS.names,
                NAME_ITEM:DATA.ITEMS.BILLS.name,
                VALUE_ITEMS:DATA.ITEMS.BILLS.values,
                VALUE_ITEM:DATA.ITEMS.BILLS.value,  
                dialog_pay_bill:false,
                input:{},
                input_amount:null,
                input_balance:null,
                inputLoading:null,
                selected_item:null,

                   
                table_actions:[ 
                    //{type:"btn",action:"add_invoice",color:"secondary", outlined:false,  text:"Add Received Invoice/Bill"},  
                    // {type:"btn",action:"manage_invoices",color:"secondary", outlined:false,  text:"Manage Invoice/Bill "},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                ],
                TOTAL_ITEM_VALUES:[  
                    {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
                    {name:'Total Paid',value:'total_paid',show_value:'total_paid_',money:true},
                    {name:'Total Balance',value:'balance',show_value:'balance_',money:true},
                ],
                tableTabs:[
                    {name:"Unpaid Bills",show_if:(this_,item)=>item.balance>0},
                    {name:"Paid Bills",show_if:(this_,item)=>item.balance<=0},
                    // {name:"Over Paid Bills",show_if:item=>item.balance<=0},
                    // {name:"Less Paid Bills",show_if:item=>item.balance<=0},
                ]
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 

            /////////
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payments,true) 
                return path
            },
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("office",1,true) 
                return thisLinks
            }, 
            QUOTATIONS_AND_INVOICES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon',color:"secondary",size:35,show:true},   
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"date",value:'created_at_',show:true},   
                    {id:0,name:"time",value:'created_at_time',show:false},   
                    {id:0,name:"Type",value:'order_type_name',show:true},     
                    {id:0,name:"code",align:"center",show:true},          
                    {id:0,name:"Payee",value:"payee_name",show:true},     
                    {id:0,name:"Due",value:"bill_due_date_",show:true},     
                    {id:0,name:"items",value:'total_quantity_',show:false},   
                    {id:0,name:"Amount",value:'amount_',align:'right',show:true},   
                    {id:0,name:"Paid",value:'total_paid_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'balance_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Pay Bill",action:"pay_bill",icon:"mdi-cash",show_if:item=>item.balance>0}, 
                        {name:"View Invoice",action:"view_invoice",icon:"mdi-eye",show_if:item=>item.order_type=="invoice"}, 
                        {name:"View Expense",action:"view_expense",icon:"mdi-eye",show_if:item=>item.order_type=="expense"}, 
                        {name:"View Payment Transactions",action:"view_transactions",icon:"mdi-receipt",show_if:item=>item.order_type=="invoice"}, 
                        // {name:"Archive Quotation/Invoice",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },  
            ////


            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()], 
                ExpenseAccounts: state=> state.items[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()],
                JoinedExpenseAccounts: state=> state.join[(DATA.ITEMS.EXPENSE_ACCOUNTS.values).toUpperCase()], 
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                JoinedSuppliers: state=> state.join[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                QuotationAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()], 
                DefaultBillAccount: state=> state.app[(DATA.ITEMS.DEFAULT_BILL_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }), 
            ExpensesData(){
                let items = this.JoinedExpenses?this.JoinedExpenses:this.Expenses 
                return items
            },
            ExpenseAccountsData(){
                let items = this.JoinedExpenseAccounts?this.JoinedExpenseAccounts:this.ExpenseAccounts
                return items
            }, 
            SuppliersData(){
                let items = this.JoinedSuppliers?this.JoinedSuppliers:this.Suppliers
                return items
            }, 
            PaymentMethodsData(){
                let items = []
                let DefaultBillAccount = this.DefaultBillAccount 
                console.log(DefaultBillAccount,'DefaultBillAccount.....');
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods= this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                // if(DefaultBillAccount){
                //     items = [...DefaultBillAccount]
                // }
                if(DefaultPaymentMethod){
                    items= [
                        ...items,
                        ...DefaultPaymentMethod
                    ]
                } 
                if(PaymentMethods){
                    items= [
                        ...items,
                        ...PaymentMethods
                    ]
                } 
                return items
            },
            QuotationAndInvoicesData(){
                let items  = this.JoinedQuotationAndInvoices?this.JoinedQuotationAndInvoices:this.QuotationAndInvoices 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },
            StockOrdersData(){
                let items  = this.JoinedStockOrders?this.JoinedStockOrders:this.StockOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },

            BillInvoices(){  
                let items = this.QuotationAndInvoicesData
                if(!items){return null}
                let filtered = items.filter(item=>{
                    return this.MBS.actions.TEXT_UP0(item.payment_method_name) == "bill" && item.order_type == DATA.ITEMS.INVOICES.value
                }) 
                return  filtered
            },
            BillStockOrder(){  
                let items = this.StockOrdersData
                if(!items){return null}
                let filtered = items.filter(item=>{
                    return this.MBS.actions.TEXT_UP0(item.payment_method_name) == "bill" && 
                    !item.invoice_code && item.order_type == DATA.ITEMS.STOCK_RECEIVES.value
                }) 
                return  filtered
            },
            


            AllInvoices(){  
                let items = this.$store.getters.getQuotationsAndInvoices
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },  
            Invoices(){
                let orders = this.AllInvoices
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return !order.archive && 
                        order.order_type == DATA.ITEMS.INVOICES.value &&
                        this.MBS.actions.TEXT_UP0(order.payment_method) == "bill"
                })
                return filtered_items
            },
            AllOnlyReceivedStock(){  
                let items = this.$store.getters.getStockOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },  
            OnlyReceivedStock(){
                let orders = this.AllOnlyReceivedStock 
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return !order.archive && 
                        !order.invoice_code &&
                        order.order_type == DATA.ITEMS.STOCK_RECEIVES.value &&
                        this.MBS.actions.TEXT_UP0(order.payment_method) == "bill"
                })
                return filtered_items
            },
             
            BillExpenses(){
                let orders = this.ExpensesData  
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return  order.payment_method_key == 'bill'||order.payment_method_code == 'bill' ||order.payment_method_code == '-3' 
                }) 
                return filtered_items
            },
            Transactions(){  
                let invoices = this.BillInvoices
                let stockOrder = this.BillStockOrder

                // let stockOrder = this.OnlyReceivedStock
                let BillExpenses = this.BillExpenses
                let items = []
                let moment = this.MBS.date.moment
                if (invoices) {
                    invoices.forEach(element => {
                        items.push({
                            ...element, 
                        })
                    });
                }
                if (stockOrder) {
                    stockOrder.forEach(element => {
                        items.push({
                            ...element, 
                        })
                    });
                }
                if (BillExpenses) { 
                    BillExpenses.forEach(element => {
                        items.push({
                            ...element,
                            order_type:DATA.ITEMS.EXPENSES.value, 
                        })
                    });
                }  
                if(this.MBS.actions.SIZE(items)==0){return null} 
                items.sort(this.MBS.actions.dynamicSort("created_at",false))  
                console.log(items,'------');
                return items
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try {
                    if (action == "pay_bill") {  
                        this.input_amount = '' 
                        this.input = {}
                        this.selected_item = item

                        this.dialog_pay_bill = true
                    }else if (action == "view_expense") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/"+item.key,true)
                        this.MBS.actions.go(link)
                        console.log(item,'expense....');
                    }else if (action == "view_invoice") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/"+item.key,true)
                        this.MBS.actions.go(link)
                        console.log(item,'invoice....');
                    }else if (action == "view_transaction") {
                        let mbs_text = this.MBS.text.option_not_available("View transactions")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                        })
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            MAKE_PAYMENT(item,input){
                try {
                    let amount = input?input.amount:null
                    let payment_method_code = input?input.payment_method_code:null
                    amount = this.toNumber(amount)
                    let transaction_key = item?item.key:null
                    let transaction_type = item?item.order_type:null
                    console.log(item);
                    let payment_details={
                        amount:amount,
                        payment_method_code:payment_method_code,
                        transaction_key:transaction_key,
                        transaction_type:transaction_type,
                    }
                    if (!transaction_key || !amount) {
                        console.log(item,amount,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action("Bill","Pay")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=PAY-BILL",
                                data:payment_details
                            }
                        })

                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=PAY-BILL') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_pay_bill = false
                                         this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Bill","Paying")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
