<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :field_items="DATA.FIELDS.expiries"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="ExpiriesData"   
                            :is_joined="true"
                            :path_item="EXPIRING_DATE"   
                            :select_items="Selectors"  

                            :table_actions="TableActions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.STOCK_ORDERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.EXPIRIES.names,
                NAME_ITEM:DATA.ITEMS.EXPIRIES.name,
                VALUE_ITEMS:DATA.ITEMS.EXPIRIES.values,
                VALUE_ITEM:DATA.ITEMS.EXPIRIES.value,   
                DATA:DATA,   
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                Expiries: state=> state.items[(DATA.ITEMS.EXPIRIES.values).toUpperCase()],
                JoinedExpiries: state=> state.join[(DATA.ITEMS.EXPIRIES.values).toUpperCase()], 
            }), 
            ExpiriesData(){ 
                let Expiries = this.Expiries
                let JoinedExpiries = this.JoinedExpiries
                let items = JoinedExpiries?JoinedExpiries:Expiries 
                console.log(items,'......'); 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("expiring_date",true)) 
                return list
            }, 
            ItemsData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems 
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },   
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            EXPIRING_DATE(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.expiries,true) 
                return path
            }, 
            
            TableActions(){
                let stockOrders = this.Expiries
                let actions =[ 
                    {type:"btn",action:"add_expiries",color:"secondary", outlined:false,  text:"Add Expiring Item"},  
                    
                ] 
                return actions
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon',color:"secondary",size:35,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Exp. Date",value:'expiring_date_',show:true},   
                    {id:0,name:"Mfg. Date",value:'manufacturing_date_',show:true},   
                    {id:0,name:"Days Left",value:'diff_days',align:"center",show:true},   
                    {id:0,name:"Item",value:'item_name',show:true},   
                    {id:0,name:"code",align:"center",show:false},         
                    {id:0,name:"Quantity",value:"quantity",align:"center",show:true},    
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Order",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            }, 
            Selectors(){ 
                let Items = this.ItemsData 
                return{
                    Items: Items, 
                }
            },
             
              
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "add_expiries") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/expiries/add",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(path,'...');
                    if (action == "view_order") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
