<template>
    <div v-if="true"> 
        <v-footer dark=""
            padless=""
            color="footer"
            >
            <v-container grid-list-xs class="footer py-1"> 
                <v-card 
                    class="" dark color="footer"
                    flat
                    tile > 
                        <a class="white--text " ref="/mberasolutions.com">
                            <v-layout align-center class="pl-9 ma-0" >
                                <div class="font-weight-bold font-italic secondary--text pr-5" v-if="AttachedDevice">
                                    <v-layout >
                                        <v-icon color="secondary">{{AttachedDevice.icon_image?AttachedDevice.icon_image.icon:'mdi-devices'}}</v-icon>
                                        <div class="ml-2">{{AttachedDevice.name}}</div> 
                                    </v-layout>
                                </div>

                                <v-layout justify-center align-center class="ma-2" >
                                    <v-icon color="primary">$kipcount-pos</v-icon>
                                </v-layout>

                                <!-- //powered  by -->
                                <v-layout justify-center="" align-center="" v-if="false">
                                    
                                    <strong class="not-f7 pr-1">&copy;</strong> 
                                    {{ new Date().getFullYear()}} -
                                    powered by
                                    <div class="white--text">
                                        <v-icon  class="mt-1 mr-1">$mbs</v-icon>
                                        <strong >Mbera Solutions</strong>. 
                                    </div>
                                    <!-- <div class="pl-1">All right reserved.</div> -->
                                    
                                </v-layout> 
                                <div class="font-weight-bold font-italic secondary--text pr-5">{{DATA.POS_TYPE.name}}</div>
                                
                                
                            </v-layout>
                        </a>    
                </v-card>
            </v-container>
        </v-footer>

    <!-- dialog -->
    <v-dialog v-model="dialogMessage" persistent max-width="500px"> 
      <v-card>
        <v-card-title dark class="primary white--text py-4"> 
          <span class="headline ">SEND US MESSAGE</span>
          <v-spacer></v-spacer>
          <v-btn icon="" dark="" @click="dialogMessage=!dialogMessage"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <form @submit.prevent="addSMS()">
          <v-card-text>
            <v-container >
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="input.name" prepend-icon="person" filled="" label="Full Name" required></v-text-field>
                </v-flex> 
                <v-flex xs12>
                  <v-text-field type="number" v-model="input.phone_number" prepend-icon="phone" filled="" label="Phone Number" required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field type="email" v-model="input.email" prepend-icon="email" filled="" label="Email Address" required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea v-model="input.message" prepend-icon="message" filled="" label="Message" required></v-textarea>
                </v-flex> 
              </v-layout>
            </v-container> 
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer> 
            <v-btn type="submit" :loading="input.loading" color="c5" dark="">SEND</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    </div>
</template>

<script> 
import DATA from "../../plugins/DATA"
import {mapState} from "vuex" 
export default {
    props:['notShow'],
    data(){
        return{
            show:true,
            dialogMessage: false,
            load:false,
            input:{}, 
            DATA:DATA
        } 
    },
    created(){
        this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
    },
    mounted(){  
        this.FEEDBACKS(1)
    },
    
    computed:{ 
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
            K_CONNECTOR: state=> state.items['K_CONNECTOR'],
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
            JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],

            LocalDevices: state=> state.locals[(DATA.ITEMS.LOCAL_DEVICES.values).toUpperCase()],
        }), 
        DeviceData(){
            return this.JoinedDevices?this.JoinedDevices:this.Devices
        },
        AttachedDevice(){
            let devices = this.LocalDevices
            console.log(devices,'local devices');
            if(!devices){return null}
            let filtered = devices.find(pc=>pc.key == 'attached_device')
            return filtered
        },
        LINKS(){ 
            return this.MBS.actions.WEB_LINKS
        },
        WEB_LINKS(){
            let links = this.LINKS
            if (links) {
                links = links.filter(link=>{
                    return link.important>=1
                })
            }
            return links
        },
        SOCIAL_MEDIA_LINKS(){
            let links = this.$store.getters.getSocialMediaLinks
            return links
        }   
    },
    methods:{ 
        LOGIN(){
            this.MBS.events.$emit('LOGIN')
        },
        goTo(link){ 
            this.MBS.actions.go(link)
        },
        DIALOG_YES(action){
            if (action.code) {
                if (action.code==="1") {
                    this.$store.dispatch("fi_delete",{
                        action:"Message",
                        path:"MBS/MESSAGES/"+action.key
                    }) 
                }
            }  
        }, 
        addSMS(){
                
            if (this.input) {
                this.$store.dispatch("fi_add_Message",this.input) 
                this.input.loading = true
            }else{
                this.MBS.actions.dialog({
                show:true,
                text:"Please, make sure you enter message first.",
                btnYes:"OK"
                })
            }
        },
        DIALOG_NO(action){ 
        },


        FEEDBACKS(time = 400){ 
              setTimeout(()=>{
                  let us = this.loggedUser 
                  let ps = this.processes
                  let ld = this.loading
                  let res = this.responses  

                  if (ps) { 
                      let adding = ps.find(process=>{
                          return process.name == 'adding/message'
                      })
                       
                      if (adding) {
                        this.input.loading = true
                        this.MBS.actions.progressDialog({
                          show:true,
                          text:"Sending..."
                        }) 
                      }else{ 
                      }  

                  }else{ 
                      
                  }
                  
                  //check response
                  if (res.res==='adding/message/successful') { 
                      this.MBS.actions.dialog({
                        show:true,
                        title:"SUCCESSFUL",
                        text:"Message, successfully sent.",
                        btnYes:"OK", 
                      }) 
                      this.dialogMessage = false
                      this.MBS.actions.progressDialog(false) 
                      this.MBS.actions.restartResponses()
                      this.input = {}
                  }else if (res.res==='adding/message/error'){ 
                      this.MBS.actions.dialog(null,true,true,'Not Successful','Error while sending message.','','OK','') 
                      this.MBS.actions.progressDialog(false)
                      this.MBS.actions.restartResponses()
                      this.input.loading = false
                  }
              },time)
        }
    },
    watch:{
        loading(value){
            this.FEEDBACKS(1)
        },
        processes(value){
            this.FEEDBACKS(1)
        },
        responses(value){
            this.FEEDBACKS(1) 
        } 
    } 
}
</script> 

