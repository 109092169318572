<template>
<div>
    <v-layout column>
        <v-card v-if="false" :height="vsh-250" color="b1">
            <mbs-page-spinner/> 
        </v-card>
        <v-layout v-else column>  
             
            <v-container grid-list-lg v-if="us">
                <v-layout row wrap class="mt-3"> 
                    <!-- SELLING SETTINGS AND TAXES -->
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0"> 
                            <!-- Contact -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >{{MBS.actions.TEXT_UP11('Selling Settings')}}</v-toolbar-title> 
                                        </v-toolbar>
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in CompanySellingSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                            <!-- Taxes -->
                            <v-spacer></v-spacer>
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('TAX SETTINGS')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in TaxSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                    
                    <!-- CASHUPS AND SALES RECEIPT --> 
                    <v-flex  sm12 md6 >
                        <v-layout column fill-height class="ma-0">
                            <!-- Cashup / Z-READ Settings-->
                            <v-hover>
                                <template v-slot="{ hover }">
                                    <v-card 
                                        tile height="100%"
                                        :elevation="hover?12:''"  >
                                        <v-toolbar color="primary lighten-5" flat="" >  
                                            <v-toolbar-title>Till / Cashup / Z-READ Settings</v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in CashupSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                        <br>
                                    </v-card> 
                                </template>
                            </v-hover>
                            <v-spacer></v-spacer>
                            <!-- Sales Receipt -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('Sales Receipt & Invoice Printing  Settings')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-list two-line>  
                                            <div v-for="(setting, index) in ReceiptSettings" :key="index">
                                                <v-divider inset v-if="index !=0"></v-divider>
                                                <v-list-item  >
                                                    <v-list-item-action>
                                                        <v-icon color="primary">{{setting.icon}}</v-icon>
                                                    </v-list-item-action> 
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{setting.name}}</v-list-item-title>
                                                        <v-list-item-subtitle>{{setting.description}}</v-list-item-subtitle>
                                                    </v-list-item-content> 
                                                    <v-list-item-action>
                                                        <v-switch
                                                            v-model="input"
                                                            @click="UPDATE_SETTING(setting)" 
                                                            :value="setting.key"
                                                            ></v-switch>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                            </div> 
                                        </v-list>
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                    
                     
                    
                    <!-- RECEIPT AND INVOICES --> 
                    <v-flex  sm12 md6 v-if="false" >
                        <v-layout column fill-height class="ma-0">
                            <!-- kipCount Subscription -->
                            <v-hover>
                                <template v-slot="{ hover }">
                                    <v-card 
                                        tile height="100%"
                                        :elevation="hover?12:''"  >
                                        <v-toolbar color="primary lighten-5" flat="" >  
                                            <v-toolbar-title>Receipt Settings</v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-card-text> 
                                        </v-card-text> 
                                        <v-layout justify-center class="ma-0" fill-height> 
                                            <div>Currently not available!..</div>
                                             
                                        </v-layout> 
                                        <br>
                                    </v-card> 
                                </template>
                            </v-hover>
                            <v-spacer></v-spacer>
                            <!-- Contact -->
                            <v-hover>
                                <template v-slot="{ hover}"> 
                                    <v-card tile class="mt-3" height="100%" :elevation="hover?12:''">
                                        <v-toolbar flat="" color="primary lighten-5" > 
                                            <v-toolbar-title >
                                                {{MBS.actions.TEXT_UP11('Invoice Settings')}}
                                            </v-toolbar-title> 
                                        </v-toolbar> 
                                        <v-layout justify-center class="ma-0" fill-height> 
                                            <div>Currently not available!..</div>
                                             
                                        </v-layout> 
                                    </v-card> 
                                </template>
                            </v-hover> 
                        </v-layout> 
                    </v-flex> 
                </v-layout> 
            </v-container> 
        </v-layout> 
    </v-layout>  
    <!-- DIALOG -->
    <v-layout column justify-center v-if="us">
        <!-- image dialog -->
         
    </v-layout> 
</div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'  
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "GENERAL_SETTINGS" 
    export default {
        name:PAGE_NAME, 
        data() {
            return { 
                page_current:1,
                page_size:10,
                DATA:DATA, 

                tab: null, 
                input:[], 
                inputLoading:false, 
            }
        },
        created() {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
            this.MBS.actions.setPAGE_LOAD(false)
            this.INITIALIZE_INPUT()
        },
        mounted() { 
            this.MBS.actions.header({
              show:true,
              type:'subToolbar',
              images:this.items,
              height:400 
            })
           this.FEEDBACKS(1000)
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
                CompanySellingSettings: state=> state.app["COMPANY_SELLING_SETTINGS"],  
                CashupSettings: state=> state.app["CASHUP_SETTINGS"],  
                ReceiptSettings: state=> state.app["RECEIPT_SETTINGS"],  
                TaxSettings: state=> state.app["TAX_SETTINGS"],  
            }), 
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },  
        },
        methods: {  
            CHECK_SELLING_SETTINGS(settings){
                let input = this.input
                let data = {
                    selling_list_descend:false,
                    duplicate_selling_item:false,
                    allow_selling_discount:false,
                    edit_selling_price:false,
                    both_selling_mode:false,
                    only_retail_model:false,
                    only_wholesale_model:false,
                    edit_opening_cash:false,
                    edit_closing_cash:false,
                    show_shortages:false,
                    fixed_closing_cash:false,
                } 
                let index_both_selling_mode = this.input.indexOf("both_selling_mode")
                let index_only_retail_model = this.input.indexOf("only_retail_model")
                let index_only_wholesale_model = this.input.indexOf("only_wholesale_model")

                if (settings.key === 'both_selling_mode') {
                    if (index_only_retail_model > -1) {  
                        this.input.splice(index_only_retail_model, 1);  
                    }
                    if (index_only_wholesale_model > -1) {  
                        this.input.splice(index_only_wholesale_model, 1);  
                    }
                }
                if (settings.key === 'only_retail_model') {
                    if (index_both_selling_mode > -1) {  
                        this.input.splice(index_both_selling_mode, 1);  
                    }
                    if (index_only_wholesale_model > -1) {  
                        this.input.splice(index_only_wholesale_model, 1);  
                    }
                }
                if (settings.key === 'only_wholesale_model') {
                    if (index_both_selling_mode > -1) {  
                        this.input.splice(index_both_selling_mode, 1);  
                    }
                    if (index_only_retail_model > -1) {  
                        this.input.splice(index_only_retail_model, 1);  
                    }
                }
                if (input) {
                    input.forEach(element => {
                        data[element] = true
                    });
                } 
                console.log(settings,'1111111');
                console.log(input,'input......');

                let mbs_text = this.MBS.text.update_item("Company Settings")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-COMPANY-SETTINGS",
                        data:data 
                    }

                })
            },  
            UPDATE_SETTING(settings){
                if(!settings){return false} 
                let CompanySettings = this.CompanySettings 
                let data = {}
                data[settings.key] = CompanySettings?!CompanySettings[settings.key]:true 

                let mbs_text = this.MBS.text.update_item("Company Settings")
                this.MBS.actions.dialog({
                    show:true,
                    title:mbs_text.title,
                    text:mbs_text.text,
                    btnYes:mbs_text.btnYes,
                    btnNo:mbs_text.btnNo,
                    action:{
                        code:PAGE_NAME+"=UPDATE-COMPANY-SETTINGS",
                        data:data 
                    }

                })
            },  
            INITIALIZE_INPUT(){
                let company_settings = this.CompanySettings
                if (company_settings) {
                    let active = []
                    for (const key in company_settings) {
                        if (company_settings[key]) {
                            active.push(key)
                        }
                    }
                    this.input = active
                } 
            },
 
            //----------------[MAIN FUNCTIONS]------------------//
            DIALOG_YES(action){ 
                try {
                    if (action) { 
                        if (action.code ===PAGE_NAME+"=UPDATE-COMPANY-SETTINGS") {
                            if (action.YES) { 
                                setTimeout(() => {  
                                    let f_detail = {
                                        action:DATA.ITEMS.COMPANY_SETTINGS.value,
                                        path:this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_settings,true),
                                        data:action.data,
                                        us:this.us
                                    }  
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        text:"Updating..."
                                    })
                                    this.input.loading = true 
                                    this.$store.dispatch("fi_update",f_detail)
                                    .then(res=>{  
                                        // let mbs_text = this.MBS.text.update_item_success(DATA.ITEMS.COMPANY_SETTINGS.value)
                                        // this.MBS.actions.dialog({
                                        //     show:true,
                                        //     fixed:true,
                                        //     title:mbs_text.title,
                                        //     text:mbs_text.text,
                                        //     btnYes:mbs_text.btnYes,  
                                        // }) 
                                        this.input.loading = false
                                        this.dialog_add_item = false 
                                        this.generatedCode = null 
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses() 
                                    })
                                    .catch(error=>{ 
                                        console.log(error,'e...');
                                        let mbs_text = this.MBS.text.update_item_fail(DATA.ITEMS.COMPANY_SETTINGS.value)
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes, 
                                        })  
                                        this.input.loading = false
                                        this.MBS.actions.progressDialog() 
                                        this.MBS.actions.restartResponses()

                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            }else{
                                this.INITIALIZE_INPUT()
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    })
                }
            },  
            FEEDBACKS(t){
                try {
                    if (!t) {
                        t=400
                    }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses  
                        let i = this.iLocation  
                        
                    },t)
                } catch (error) {
                   this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);   
        },
        watch: {
            us(v){
                this.FEEDBACKS(1)
            },
            ud(v){
                this.FEEDBACKS(1)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            CompanySettings(){ 
                this.INITIALIZE_INPUT()
            }
        },
  }
</script>
 