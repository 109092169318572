<template> 
    <v-dialog
        v-model="mbs_dialog.show"
        scrollable   
        :overlay="false"
        :max-width="mbs_dialog.size?mbs_dialog.size:700"
        :persistent='mbs_dialog.fixed' 
        transition="dialog-transition" >
        <v-card rounded="lg" v-if="mbs_dialog.show" > 
            <v-card-title class="primary">
                <div class="font-weight-bold b2--text"> 
                    <span >{{mbs_dialog.title}}</span> 
                </div> 
                <v-spacer></v-spacer> 
                <v-btn class="ml-9" @click="BTN_NO(mbs_dialog.action.silent_btn_no)" icon dark><v-icon >close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <br> 
                <v-layout   justify-center v-if="mbs_dialog.icon"> 
                    <v-icon size="150">{{mbs_dialog.icon}}</v-icon>
                </v-layout>
                <div class="mb-2 mt-5">{{mbs_dialog.text}}</div>
                <form @submit.prevent="CONFIRM(input.password)"> 
                    <v-layout row wrap class="mx-0 mb-6 mt-2"  > 
                        <v-flex xs12 md4 v-if="all_users" >
                            <v-layout class="mr-1"> 
                                <v-autocomplete
                                    v-model="select.country"
                                    :items="countryNames" 
                                    solo outlined
                                    flat tile  
                                    height="58"
                                    label="Country" >
                                    <template 
                                        slot="selection"
                                        slot-scope="data" >
                                        <v-chip 
                                            :input-value="data.selected"
                                            :disabled="data.disabled"
                                            :key="JSON.stringify(data.item)"
                                            class="v-chip--select-multi"
                                            
                                            @input="data.parent.selectItem(data.item)" >
                                            <v-avatar v-if="select.country!==''" 
                                                class="mr-2" >
                                                    <span 
                                                        style="font-size:30px"   >
                                                        <flag :iso="select.country.split(':')[0]" />   
                                                    </span> 
                                            </v-avatar>

                                            {{ data.item.split(': ')[1] }}
                                        </v-chip> 
                                    </template>
                                </v-autocomplete>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 md8 v-if="all_users" >
                            <v-text-field class="mr-1" 
                                filled required 
                                autocomplete="one-time-code"   
                                outlined  id="phone_number"  
                                v-model="inputPhone"
                                @focus="FOCUS_INPUT({value:'inputPhone',ref:'ref_phone_number'})"
                                append-icon="phone" 
                                :label="inputPhone?'':'Phone Number'"></v-text-field> 
                        </v-flex> 

                        <v-flex xs12 >
                            <v-text-field required prepend-inner-icon="lock"
                                v-model="input.password"
                                @focus="FOCUS_INPUT({value:'password',ref:'ref_password'})"
                                filled outlined
                                label="" autofocus clearable
                                :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                @click:append="() => (psw1 = !psw1)"
                                :type="psw1 ? 'password' : 'text'"
                                autocomplete="one-time-code"  
                                class="mr-1"  />  
                        </v-flex> 
                        <v-flex xs12 class="mr-2" outlined>  
                            <v-card > 
                                <mbs-keyboard2 @onChange="onChange" @onKeyPress="onKeyPress" /> 
                            </v-card>
                        </v-flex>

                        <v-flex xs12   class="my-3">
                            <v-layout row wrap class="ma-0 pr-1">
                                
                                <v-btn  @click="BTN_NO()"
                                    class="mr-2"
                                    :color="mbs_dialog.btnNoColor?mbs_dialog.btnNoColor:'primary'" 
                                    height="50"  >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1" mbs_dialog.btnNoIcon>{{mbs_dialog.btnNoIcon}}</v-icon>
                                        {{mbs_dialog.btnNo?mbs_dialog.btnNo:"CANCEL"}}
                                    </div>
                                </v-btn> 
                                <v-flex  >
                                    <v-btn 
                                        type="submit"   
                                        :class="all_users?!GET_PHONE_NUMBER(true).valid:false"  
                                        :loading="inputLoading" 
                                        :color="mbs_dialog.btnYesColor?mbs_dialog.btnYesColor:'secondary'" 
                                        height="50" width="100%" >
                                        <div class="not-f5">
                                            <v-icon class="pr-1 pb-1" mbs_dialog.btnYesIcon>{{mbs_dialog.btnYesIcon}}</v-icon>
                                            {{mbs_dialog.btnYes?mbs_dialog.btnYes:'CONFIRM PASSWORD'}}
                                        </div>
                                    </v-btn>      
                                </v-flex>    
                            </v-layout>
                        </v-flex> 
                    </v-layout>  
                    
                </form>
            </v-card-text>  
        </v-card> 
    </v-dialog>  
</template>

<script>
import {mapState} from "vuex" 
import DATA from '../../plugins/DATA'
let NAME = 'DIALOG_CONFIRM_PASSWORD'
export default {
    props:['notShow'], 
    data(){
        return{
            show:false,
            input:{},
            inputPhone:'',
            inputLoading:false,
            psw1:true,
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            },
            focused_field:null
        } 
    }, 
    mounted(){
        
    }, 
    computed:{
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()], 
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
        }),
        mbs_dialog(){ 
            return  this.$store.getters.getMBS_dialog_confirm_password
        },
        btn_no_text(){
            let dialog = this.mbs_dialog 
            let btnNo = dialog?dialog.btnNo:null
            if(!btn){return ""}
             
        },
        all_users(){
            let dialog = this.mbs_dialog
            let action = dialog?dialog.action:null
            return action?action.all_users:null
        },
        countryNames(){
            let names = []
            let countries = this.$store.getters.getCountries 
            for (const key in countries) {
                 const cn = countries[key]
                if (cn.alpha2!== undefined) {
                    names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                } 
            } 
            //names=['Mw: Malawi (+265)']
            return names
        }, 
        iLocation(){ 
            const i = this.$store.getters.getIpLocation
            return i
        },
        GET_PHONE_NUMBER(){
            try {
                let us = this.us 
                let input_country = ''+this.select.country
                let input_phone = ''+this.inputPhone 
                let countryNames = this.countryNames 
                let PHONE = this.MBS.phone 
                return all =>{
                    let current_phone = ''+input_phone 
                    let ip_phone = null 
                    let ip_countryPhone = null 

                    if (input_phone) {
                        input_phone = input_phone.replace(/[^0-9+]/g,'')
                    }if (input_country) {
                        input_country = input_country.replace(/[^0-9]/g,'')
                    } 

                    if (input_phone) { 
                        ip_phone = PHONE(input_phone)
                    }if (input_country) { 
                        ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                    } 

                    if (ip_phone) {
                        let possible = ip_phone.g?ip_phone.g.possible:null
                        if (possible) {
                            current_phone = ip_phone.g

                            if (countryNames) { 
                                let regionCode = current_phone.regionCode
                                let countryName = countryNames.find(item=>{
                                    return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                })
                                if (countryName != this.select.country) {
                                    this.select.country = countryName
                                } 
                            }
                        }
                    }
                    if (ip_countryPhone) {
                        let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                        if (possible) {
                            current_phone = ip_countryPhone.g
                        }
                    }else{

                    }

                    if (current_phone.valid) {
                        if (this.inputPhone != current_phone.number.international ) {
                            this.inputPhone = current_phone.number.international
                        }
                    }  
                    if (all) {
                        return {
                            ...current_phone,
                            phone:current_phone.number?current_phone.number.e164:''
                        }
                    } else {
                        return current_phone.number?current_phone.number.e164:''
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'GET_PHONE_NUMBER',
                    page:PAGE_NAME, 
                })
            }  
        },
    },
    methods:{
        FOCUS_INPUT(field){
            this.focused_field = field 
        },
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        },
        CONFIRM(password){ 
            try { 
                this.inputLoading = true   
                password = password?this.MBS.crypt.encrypt(password):null 
                let ud = null
                let company = this.SELECTED_COMPANY
                let company_key = company?company.key:null
                let PHONE = this.GET_PHONE_NUMBER(true)
                let phone_number = PHONE?PHONE.phone:null
                if (this.all_users) { 
                    let user = this.$store.getters.getUsersByPhoneNumber(phone_number)
                    ud = user
                    // if (company_key && user) {
                    //     let us_company = user[company_key]
                    //     let company_user_group_code = us_company?us_company.company_user_group_code:null

                    // } 
                } else {
                    ud = this.ud
                }
                setTimeout(() => {
                    if (!password) { 
                        this.inputLoading = false   
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    }else if (!ud) { 
                        this.inputLoading = false  
                        this.MBS.actions.dialog(null,true,false,"ERROR","User not found",null,"OK")
                    }else if (password!==ud.p_uid) { 
                        this.inputLoading = false  
                        this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                    } else { 
                        /// 
                        this.inputLoading = false  
                        this.MBS.events.$emit('DIALOG_YES',{
                            ...this.mbs_dialog.action,
                            password:password,
                            ud:ud,
                            NO:false,
                            YES:true
                        }) 
                        this.$store.dispatch('setMBS_dialog_confirm_password',{})
                    }  
                }, 500);
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CONFIRM_START_SELLING',
                    page:NAME, 
                }) 
            }  
        },
        BTN_NO(silent_btn_no){ 
            if (!silent_btn_no) {
                this.MBS.events.$emit('DIALOG_YES',{
                    ...this.mbs_dialog.action,
                    NO:true,
                    YES:false
                })   
            }
            this.$store.dispatch('setMBS_dialog_confirm_password',{}) 
        },
        onChange(input) {
            // this.input = input;
        },
        onKeyPress(button) { 
            let focused_field = this.focused_field
            const field_value = focused_field?focused_field.value:null 
            if (button ) {
                let value = button
                if (field_value) { 
                    if (field_value == "inputPhone") {
                        if (value=="{bksp}") {
                            this.inputPhone = (""+this.inputPhone).slice(0,-1)
                        } else if (value.indexOf('{')==-1) { 
                            this.inputPhone =this.inputPhone+value 
                        } else { 
                        }
                    }else{ 
                        if(!this.input){this.input={}}
                        if (value=="{bksp}") {
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                        }else if (value.indexOf('{')==-1) { 
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value 
                        }else{ 
                        } 
                        setTimeout(() => {
                            this.input = {...this.input}
                        }, 1); 
                    }
                     
                }
            }
        },
        onInputChange(input) { 
            // this.input = input.target.value;
        }
    },
    watch:{
        mbs_dialog(value){ 
            if (value) {
                this.RESET_ALL_DATA()
            }
        },   
    } 
}
</script>
