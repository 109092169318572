import Vue from 'vue';  
import router from '../router';
import { store } from '../store';
import {EventBus} from './eventBus'; 
import moment from 'moment'

let NAME = "DATE-BUS"

export const DateBus = new Vue({
    data(){
        return{ 
            LIVE_DATE : new Date(),
        }
    },
    computed:{ 
        moment(){
            return moment
        }, 
        today(){
            return moment().format('yyyy-MM-DD')
        },
        live(){
            return this.LIVE_DATE
        },
        tomorrow(){
            return moment().add(1,'day').format('yyyy-MM-DD')
        },
        yesterday(){
            return moment().subtract(1,'day').format('yyyy-MM-DD')
        } 
    },
    created(){
        // EventBus.$on('DIALOG_YES',this.DIALOG_YES) 
        // setInterval(this.statLiveDate(),1000)
    },
    methods:{  
        convert(date,format,type){ 
            if(!date){date="0000-00-00"} 
            if (!format) { 
                if (type===1) {
                    format = 'dd-mm-yyyyy'
                }else if (type===2) {
                    format = 'Do, MMMM YYYY'
                }else if (type===3) {
                    format = 'dddd,d0, MMMM YYYY'
                }else{
                    format = 'DD/MM/YYYY'
                }
            }
            return moment(date).format(format)
        },
        convertTime(time){ 
            if (!time) {
            return "00:00"
            }  
            let date =  new Date(time); 
            let newTime=(date.getHours())+":"+(date.getMinutes())
            return  newTime
        },
         
    },
    watch: { 
    },
});
 