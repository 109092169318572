<template>
    <div>   
        <v-container grid-list-xl> 

            <!-- <v-btn color="success" @click="DELETE_ALL_SALES">DELETE_ALL_SALES</v-btn> -->
            <v-layout align-center="" justify-center="">


                <v-col sm="12" cols="12">
                    <br> 
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="SalesOrdersData"   
                            :is_joined="true"  
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from "../../../../../plugins/DATA"
    let PAGE_NAME = DATA.ITEMS.SALES.values+"_transactions"    
    import {mapState,mapGetters } from "vuex"
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.SALES.names,
                NAME_ITEM:DATA.ITEMS.SALES.name,
                VALUE_ITEMS:DATA.ITEMS.SALE_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.SALE_ORDERS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'total_quantity'},
                    {name:'Total Cost Price',value:'total_cost_price',show_value:'total_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ], 
                filter_date:null
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: { 
             ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSalesOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
            }), 

            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sales",3,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Payment",value:"payment_method_name",show:true},  
                    {id:0,name:"Day Shift Code",value:'day_shift_key',show:false},   
                    {id:0,name:"Cashup Code",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number",align:"center",show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:true},         
                    {id:0,name:"Till",value:"till_name",show:false},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Payment",value:"payment_method",show:false},     
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:true},   
                    {id:0,name:"Sold Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        // {name:"log",action:"log",icon:"mdi-plus"},   
                    ]}, 
                ]
            }, 
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                console.log(list,'list...');
                return list
            },    
        },
        methods: { 
            async DELETE_ALL_SALES(){
                try {
                    let items = this.SalesOrdersData
                    let size = this.MBS.actions.SIZE(items)
                    let progress = 0

                     
                    console.log(items,size,"no items.......");
                    if(!items){
                    } 

                    // return
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Deleting ("+size+"/"+progress+") ..."
                    })

                    // let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.,true)
                    // let path = "/MPOS/COMPANY_DATA/all-supplieers/ACCOUNTS/DAYS_SHIFT_SALES"
                    let path = "/MPOS/COMPANY_DATA/mangochi-sun-supermarket/ACCOUNTS/DAYS_SHIFT_SALES/"

                    
                    for (const item of items) {
                        let res = await this.$store.dispatch('fi_delete',{
                            action:DATA.ITEMS.ITEMS.values+"__", 
                            path:path+item.day_shift_key+'/'+item.key, 
                        }) 
                        progress = progress+1
                        this.MBS.actions.progressDialog({ 
                            show:true,
                            text:"Deleting ("+size+"/"+progress+") ..."
                        })
                        console.log(path+item.day_shift_key+'/'+item.key,'path:, ....');
                        console.log(res,'res....');
                    }
                    this.MBS.actions.progressDialog()
                } catch (error) {
                    
                }

            },
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "return_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/return-stock",true))
                    }else if (action == "transfer_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/transfer-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    console.log(path,'...');
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } if (action == "log") {
                        console.log(item,'.item............');
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
