<template>
    <div>
        <v-menu 
            v-model="menuSign" 
            :close-on-content-click="false" 
            bottom="" 
            offset-y rounded="xl"
            :nudge-width="250">
            <template v-slot:activator="{ on }"> 
                <!-- <v-btn v-if="ud?ud.registered:false" -->
                <v-btn v-if="us"
                    color="white" 
                    :style="'--size:1px; --color:red;'"
                    v-on="on"  
                    :class="isUS?'mbs-border1':'mbs-border1'"
                    :small ="vs.smAndDown"
                    icon  > 
                    <v-avatar
                        :style="'--size:2px; --color:red;'" :tile="false"
                        :size="vs.smAndDown?'40':'50'"  
                        color="secondary" class="mbs-border1">
                        <div class="not-f4 white--text  " >
                            {{ud?ud.surname?ud.surname.charAt(0):us?us.email.charAt(0):'.':'.'}}
                        </div> 
                    </v-avatar>
                </v-btn> 
                <!-- <v-btn  v-else
                    class="mr-2"
                    :color="tb.textColor" 
                    outlined v-on="on" 
                    rounded dark=""
                    small>login
                </v-btn> -->
                <v-btn  v-else
                    :class="vs.smAndDown?'ml-2':'mr-2'"
                    :color="'primary'" 
                    outlined @click="MBS.events.$emit('LOGIN')"
                    rounded dark=""
                    small>login
                </v-btn>
                
            </template> 

            <v-card  v-if="us"
                color="lighten-1" class="" rounded="">   
                <v-card-title class="headline primary lighten-1 white--text">
                    <v-list-item  dark>
                        <v-list-item-action>
                            <v-avatar
                                size="50"  
                                color="secondary">
                                <div class="not-f4 " >
                                    {{ud?ud.surname?ud.surname.charAt(0):us?us.email.charAt(0):'.':'.'}} 
                                </div> 
                            </v-avatar> 
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{MBS.actions.TEXT_UP11(ud?ud.surname:'..')}} {{MBS.actions.TEXT_UP11(ud?ud.names:'..')}}    
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!isUS">
                                -- {{us?us.displayName:'..'}} --
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn @click="menuSign=!menuSign" dark="" icon=""><v-icon>close</v-icon></v-btn>
                        </v-list-item-action>
                    </v-list-item>   
                </v-card-title> 
                <!-- <v-subheader class="b1">MY ACCOUNT</v-subheader> -->
                <v-virtual-scroll 
                    v-if="menuSign"
                    :bench="2"
                    :items="accountLinks"
                    height="200"
                    item-height="60"  > 
                   <template v-slot="{ item,index }">
                        <v-divider inset></v-divider> 
                        <!-- <v-divider></v-divider> -->
                        <v-list-item  
                            :key="index" :to="item.link" @click="closeMenu()">
                            <v-list-item-action>
                                <v-btn 
                                    color="primary" 
                                     icon>
                                        <v-icon size="24px">{{item.icon}}</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item> 
                        
                   </template> 

                </v-virtual-scroll> 
                
                <v-divider></v-divider>
                <v-list-item v-if="!fullscreen"  @click="MBS.actions.SCREEN_FULL_MODE(true)" >
                    <v-list-item-action>
                        <v-btn 
                            color="primary" 
                              icon>
                                <v-icon size="24px">mdi-fullscreen</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Full Screen</v-list-item-title>
                </v-list-item> 
                <v-divider></v-divider>
                <v-list-item v-if="fullscreen"  @click="MBS.actions.SCREEN_FULL_MODE(false)" >
                    <v-list-item-action>
                        <v-btn 
                            color="primary" 
                              icon>
                                <v-icon size="24px">mdi-fullscreen-exit</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Exit Full Screen</v-list-item-title>
                </v-list-item> 
                <v-divider></v-divider>
                <v-list-item v-if="true"  @click="MBS.actions.SCREEN_RELOAD()" >
                    <v-list-item-action>
                        <v-btn 
                            color="primary" 
                              icon>
                                <v-icon size="24px">mdi-refresh</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-title>Reload System</v-list-item-title>
                </v-list-item> 
                
                <v-divider></v-divider>
                    
                <v-card-actions class="b1">
                    <v-layout class="pa-2  ">
                        <v-spacer></v-spacer>
                        <div class="mr-2">
                            
                        </div>
                        <v-btn 
                            :small = "true"
                            rounded outlined
                            color="primary"
                            @click="logout()" >
                            <div class="px-2">Logout</div> 
                        </v-btn>
                        
                    </v-layout> 
                </v-card-actions>
            </v-card> 

            <v-card v-else
                color="lighten-1" class="">  

                <v-card-title class="headline primary lighten-">
                    <v-subheader class="accent--text font-weight-bold">{{MBS.actions.TEXT_UP('Login with')}}</v-subheader>
                    <v-spacer></v-spacer>
                    <v-btn @click="menuSign=!menuSign" dark="" icon=""><v-icon>close</v-icon></v-btn>
                </v-card-title> 
                <v-card-text class="pt-2">
                    <v-list subheader dense>
                        <v-subheader>Select login option</v-subheader>
                        
                        <!-- signWithGoogle -->
                        <v-list-item @click ='signWithPhone()' >
                            <v-list-item-action>
                                <v-btn 
                                    color="primary" 
                                    outlined icon>
                                        <v-icon size="24px">phone</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-title>Phone Number</v-list-item-title>
                        </v-list-item> 
                        <!-- signWithGoogle -->
                        <v-list-item @click ='signWithGoogle()' >
                            <v-list-item-action>
                                <v-btn 
                                    color="primary" 
                                    outlined icon>
                                        <v-icon size="24px">fa fa-google</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-title>Gmail Account</v-list-item-title>
                        </v-list-item> 
                        <!-- signWithEmail -->
                        <!-- <v-list-item @click ='signWithYonse()'>
                            <v-list-item-action>
                                <v-btn 
                                    color="primary" 
                                    outlined icon>
                                        <v-icon size="24px">email</v-icon>
                                </v-btn>
                            </v-list-item-action>
                            <v-list-item-title>User Name</v-list-item-title>
                        </v-list-item> -->
                    </v-list> 
                </v-card-text>
                
                <v-divider></v-divider>
                    
                <v-card-actions >
                    <v-layout class="pa-2">
                        <v-spacer></v-spacer>
                        <div class="mr-2">
                            For new account
                        </div>
                        <v-btn 
                            :small = "true"
                            rounded outlined
                            color="primary"
                            @click="go('/signup')"
                        >
                            <div class="px-2">Sign up</div> 
                        </v-btn>
                        
                    </v-layout>

                </v-card-actions>
            </v-card>
        </v-menu> 
        <!-- // login dialog -->
        <v-dialog v-model="dialog" tile persistent max-width="700px"> 
            <form @submit.prevent="LOGIN_USER()">
                <v-card  tile >
                    <v-card-title class="py-5 primary lighten-1 white--text">
                        <span class="headline">LOGIN</span>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=!dialog" dark icon><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="mb-0 pb-0">
                        <v-container> 
                            <br> 
                            <v-layout row wrap >
                                <v-flex xs12 md4 >
                                    <v-layout class="mr-1"> 
                                        <v-autocomplete
                                            v-model="select.country"
                                            :items="countryNames" 
                                            solo outlined
                                            flat tile  
                                            height="58"
                                            label="Country" >
                                            <template 
                                                slot="selection"
                                                slot-scope="data" >
                                                <v-chip 
                                                    :input-value="data.selected"
                                                    :disabled="data.disabled"
                                                    :key="JSON.stringify(data.item)"
                                                    class="v-chip--select-multi"
                                                    
                                                    @input="data.parent.selectItem(data.item)" >
                                                    <v-avatar v-if="select.country!==''" 
                                                        class="mr-2" >
                                                            <span 
                                                                style="font-size:30px"   >
                                                                <flag :iso="select.country.split(':')[0]" />   
                                                            </span> 
                                                    </v-avatar>

                                                    {{ data.item.split(': ')[1] }}
                                                </v-chip> 
                                            </template>
                                        </v-autocomplete>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 md8>
                                    <v-text-field 
                                        @focus="FOCUS_INPUT({value:'inputPhone',ref:'ref_phone_number'})"
                                        outlined autofocus
                                        filled required
                                        id="phone_number"  
                                        ref="ref_phone_number"  
                                        v-model="inputPhone"
                                        append-icon="phone" 
                                        label="Phone number"></v-text-field> 
                                </v-flex> 
                                <v-flex xs12 >
                                    <v-text-field outlined
                                        @focus="FOCUS_INPUT({value:'password',ref:'ref_password'})"
                                        :ref="'password'"
                                        filled required autocomplete="one-time-code"  
                                        v-model="input.password"
                                        prepend-inner-icon="lock"
                                        :append-icon="psw1 ? 'visibility' : 'visibility_off'"
                                        @click:append="() => (psw1 = !psw1)"
                                        :type="psw1 ? 'password' : 'text'"
                                        name="input-10-1"
                                        label="Enter your password"
                                        hint="At least 8 characters"
                                        min="6" 
                                        counter></v-text-field>
                                </v-flex> 
                            </v-layout> 
                            <v-layout align-center>
                                <div>
                                    <v-btn 
                                        @click="onSignup()"
                                        text tile
                                        color="c1"><div>{{MBS.actions.TEXT_UP0("Don't have account")}}</div></v-btn> / 
                                    <v-btn 
                                        color="c1" 
                                        text tile
                                        @click="onForgotPassword()">forget password</v-btn>   

                                </div>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <div class="pl-3"> 
                        <v-subheader>Enter your <strong class="px-1">kipcount Account</strong>  Credentials.</v-subheader>
                        <v-card class="mr-2" outlined>
                            
                            <mbs-keyboard2 @onChange="onChange" @onKeyPress="onKeyPress" /> 
                        </v-card>
                    </div> 
                    <v-card-actions class="pb-5 px-5">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text to="/signup" @click="dialog = false">Signup </v-btn>
                        <v-btn
                           :disabled="!GET_PHONE_NUMBER(true).valid"
                           :loading="inputLoading" 
                           type="submit" tile  
                           color="primary" class="px-5" 
                        >Login</v-btn>
                    </v-card-actions> 
                </v-card> 
            </form>
        </v-dialog> 
        <!-- {{ AttachedDevice }} -->
    </div>
</template> 
<script>
import {mapState,mapGetters } from "vuex" 
import DATA from "./../../plugins/DATA"
let NAME = 'SIGN-WITH'
export default { 
    data(){
        return{ 
            menuSign:false,
            dialog:false,
            select:{
                signOption:'',
                accountType:'',
                businessType:'',
                country:''
            },
            psw1: true, 
            input:{ 
            },
            inputPhone:'',
            inputLoading:false,  
            focused_field:null
        } 
    },
    created(){
        this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES); 
        this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
        this.MBS.events.$on('LOGIN', this.LOGIN);
        this.FEEDBACKS(1000)
    },
    mounted(){
        this.input={}
        this.inputLoading=false
    },
    computed:{
        vs(){
            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        }, 
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'], 
        }),
        ...mapState({
            CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
            CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
            Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
            JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],

            LocalDevices: state=> state.locals[(DATA.ITEMS.LOCAL_DEVICES.values).toUpperCase()],
        }), 
        DeviceData(){
            return this.JoinedDevices?this.JoinedDevices:this.Devices
        },
        AttachedDevice(){
            let devices = this.LocalDevices
            console.log(devices,'local devices......');
            if(!devices){return null}
            let filtered = devices.find(pc=>pc.key == 'attached_device')
            return filtered
        },
        fullscreen(){
            return this.MBS.actions.Fullscreen
        },

        isUS(){
            let us = this.us
            let ud = this.ud
            let us_uid = us?us.uid:null
            let ud_uid = ud?ud.key:null
            if (!us_uid) {return null}
            if (!ud_uid) {return null}
            if (us_uid === ud_uid) {
                return true
            }else{
                return false
            }
        },
        
        tb(){ 
            return this.$store.getters.getMBS_toolbar
        }, 
         
        accountLinks(){
            let links = this.MBS.actions.FIND_SUB_LINKS("account",1) 
            return links
        },
        countryNames(){
            let names = []
            let countries = this.$store.getters.getCountries 
            for (const key in countries) {
                 const cn = countries[key]
                if (cn.alpha2!== undefined) {
                    names.push(cn.alpha2+': '+cn.name+' ('+cn.countryCallingCodes+')') 
                } 
            } 
            //names=['Mw: Malawi (+265)']
            return names
        }, 
        iLocation(){ 
            const i = this.$store.getters.getIpLocation
            return i
        },
        GET_PHONE_NUMBER(){
            try {
                let us = this.us 
                let input_country = ''+this.select.country
                let input_phone = ''+this.inputPhone 
                let countryNames = this.countryNames 
                let PHONE = this.MBS.phone 
                return all =>{
                    let current_phone = ''+input_phone 
                    let ip_phone = null 
                    let ip_countryPhone = null 

                    if (input_phone) {
                        input_phone = input_phone.replace(/[^0-9+]/g,'')
                    }if (input_country) {
                        input_country = input_country.replace(/[^0-9]/g,'')
                    } 

                    if (input_phone) { 
                        ip_phone = PHONE(input_phone)
                    }if (input_country) { 
                        ip_countryPhone = PHONE('+'+(input_country)+''+input_phone)
                    } 

                    if (ip_phone) {
                        let possible = ip_phone.g?ip_phone.g.possible:null
                        if (possible) {
                            current_phone = ip_phone.g

                            if (countryNames) { 
                                let regionCode = current_phone.regionCode
                                let countryName = countryNames.find(item=>{
                                    return this.MBS.actions.TEXT_UP(item).includes(this.MBS.actions.TEXT_UP(regionCode+':'))
                                })
                                if (countryName != this.select.country) {
                                    this.select.country = countryName
                                }
                                // console.log(this.select.country,'------',countryName);
                            }
                        }
                    }
                    if (ip_countryPhone) {
                        let possible = ip_countryPhone.g?ip_countryPhone.g.possible:null
                        if (possible) {
                            current_phone = ip_countryPhone.g
                        }
                    }else{

                    }

                    if (current_phone.valid) {
                        if (this.inputPhone != current_phone.number.international ) {
                            this.inputPhone = current_phone.number.international
                        }
                    } 
                    // console.log(input_phone,'input_phone.........');
                    // console.log(input_country,'input_country.........');  
                    // console.log(ip_countryPhone,'ip_countryPhone.........');
                    // console.log(ip_phone,'ip_phone.........');  
                    // console.log(current_phone,'current_phone.........'); 

                    if (all) {
                        return {
                            ...current_phone,
                            phone:current_phone.number?current_phone.number.e164:''
                        }
                    } else {
                        return current_phone.number?current_phone.number.e164:''
                    } 
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHECK_PHONE_NUMBER',
                    page:PAGE_NAME, 
                })
            }  
        },
         
    },
    methods:{
        RESET_ALL_DATA(){ 
            try { 
                Object.assign(this.$data, this.$options.data())  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'RESET_ALL_DATA',
                    page:PAGE_NAME, 
                }) 
            }
        }, 
        logout(){
            this.closeMenu()
            this.MBS.actions.logout()
        },
        login(){
            this.closeMenu
            this.dialog = true
        },
        LOGIN(v){ 
            this.login()
        },
        CHECK_PHONE_NUMBER(){
            try {
                this.MBS.actions.snackbar({
                    show:true,
                    time:5000,
                    y:"bottom",
                    color:"primary",
                    text:"Checking Phone Number..."
                })
                //us
                    
                let PHONE = this.GET_PHONE_NUMBER(true)
                if (PHONE.valid) {
                    return PHONE.phone
                }  
                this.MBS.actions.dialog({
                    show:true,
                    title:"ERROR",
                    text:"Please, make sure you enter valid phone number.",
                    btnYes:'OK'
                })
                return null
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'CHECK_PHONE_NUMBER',
                    page:PAGE_NAME, 
                })
            }  
        },
        CHECK_PASSWORD(){
            let p1=this.input.password
            let p2=this.input.password_confirm
             if (p1.length<8) {
                this.MBS.actions.snackbar({
                    show:true,
                    time:5000,
                    color:"secondary",
                    text:'password must at least have 8 characters'
                })
                return null
            } else {
                return p1
            }
        }, 
        LOGIN_USER(){
            this.inputLoading = true
            let phone = this.CHECK_PHONE_NUMBER()
            let password = this.CHECK_PASSWORD() 
            if (phone && password) { 
                this.MBS.actions.snackbar({
                    show:true,
                    time:12000,
                    y:"bottom",
                    color:"primary",
                    text:"Logging..."
                })
                this.$store.dispatch('fi_signWithEmail',{
                    email:phone+this.MBS.DATA.APP.EMAIL_SERVER,
                    password:password
                }).then(async res=>{
                    this.dialog = false 
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"SUCCESSFUL",
                        text:"You have successfully login.",
                        btnYes:"OK", 
                    } ) 
                    this.MBS.actions.progressDialog()  
                    this.MBS.actions.restartResponses()
                    this.MBS.actions.snackbar()

                    //check local device
                    let device = this.AttachedDevice
                    let company = device?device.company:null
                    console.log(company,'company......');
                    if (company) {
                        await this.MBS.actions.SELECT_COMPANY(company)
                        this.MBS.actions.go('/')
                    }else{
                        this.MBS.actions.go('/account')
                    }
                    
                    this.RESET_ALL_DATA() 
                }).catch(error=>{
                    console.log(error,1111);
                })
               
            }else{
                this.inputLoading = false
            }
        },
        go(to){
            this.closeMenu()
            this.MBS.actions.go(to)
        },
        closeMenu(){
            this.dialog=false
            this.menuSign=false
        },
        onSignup(){
            this.closeMenu()
            this.MBS.actions.go('/signup')
        }, 
        onForgotPassword(){
            this.closeMenu()
            this.MBS.actions.go('/recover-password')
        }, 
        signWithYonse(){
            this.closeMenu()
            this.$emit('onSignWithEmail') 
        }, 
        signWithPhone(){
            this.closeMenu()
            this.$emit('onSignWithPhone') 
            this.login()
        },
        signWithGoogle(){
            this.closeMenu()
            this.$emit('onSignWithGmail')  
        },
        signWithTwitter(){
            this.closeMenu()
            this.$emit('onSignWithTwitter') 
        },
        signWithFacebook(){
            this.closeMenu()
            this.$emit('onSignWithFacebook') 
        },
        FOCUS_INPUT(field){
            this.focused_field = field
            console.log(field,'field.........');
        },
        //----------------[PAGE]------------------//
        DIALOG_YES(action){ 
            if (action) {
                if (action.code ===NAME+"=ADD-SERVICE") {
                    if (this.input.check ) {
                        this.input.check = false 
                        this.$store.dispatch("fi_add",{
                            action:"Services",
                            path:"MBS/SETTINGS/Services/",
                            data:this.input
                        }) 
                    } 
                } 
                if (action.code ===NAME+"=REGISTER-TIMEOUT") {
                    
                }
                if (action.code ===NAME+"=GO-TO-PROFILE") {
                    this.MBS.actions.go('/profile')
                }
                if (action.code ===NAME+"=RELOAD-PAGE") {
                    this.RELOAD_PAGE()
                }
                if (action.code ===NAME+"=CANCEL-SIGNING") {
                    
                }
                
            }
        },  
        MBS_KEYBOARD(key){ 
            let focused_field = this.focused_field
            const field_value = focused_field?focused_field.value:null

            const ref_inputs = this.$refs
            if (key ) {
                let value = key.value 
                if (field_value) { 
                    if (field_value == "inputPhone") {
                        if (value=="BACK_SPACE") {
                            this.inputPhone = (""+this.inputPhone).slice(0,-1)
                        } else {
                            this.inputPhone =this.inputPhone+value 
                        }
                    }else{
                        if(!this.input){this.input={}}
                        if (value=="BACK_SPACE") {
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                        }else{
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                        }
                        setTimeout(() => {
                            this.input = {...this.input}
                        }, 1);

                        console.log(this.input,'input......');
                    }
                     
                }
            }
        },  
        FEEDBACKS(t){
            if (!t) {
                t=1000
            }
            setTimeout(()=>{
                let us = this.us
                let ud = this.ud
                let ps = this.processes
                let ld = this.loading
                let res = this.responses 
                let i = this.iLocation 
                this.page_show = false 

                //CHECK USER STATE
                //Check proccess 
                if (ps) {
                    let logging = ps.find(process=>{
                        return process.name == 'logging'
                    }) 
                    let signing = ps.find(process=>{
                        return process.name == 'signing/withEmail'
                    }) 

                    if (signing) {
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Logging...", 
                        })
                    }else{
                        
                    }     
                }else{
                    this.page_show =true
                    // this.MBS.actions.progressDialog()
                } 

                
                //get i and set country number 
                if (i) {
                    this.select.country = i.country.code+ ': '+i.country.name+' ('+i.country.callingCode+')' 
                }else{
                    //this.$store.dispatch('setILocation')
                } 

                //check response
                if (res.res==='signing/withEmail/successful') {
                    // this.dialog = false 
                    // this.MBS.actions.dialog({
                    //     show:true,
                    //     fixed:true,
                    //     title:"SUCCESSFUL",
                    //     text:"You have successfully login.",
                    //     btnYes:"OK",
                    //     // action:{
                    //     //     code:"=GO",
                    //     //     to:'/account'
                    //     // }
                    // } ) 
                    // this.MBS.actions.go('/account')
                    // setTimeout(() => {
                    //     this.MBS.actions.dialogInput({show:false}) 
                    // }, 1000);
                    // this.MBS.actions.progressDialog()  
                    // this.MBS.actions.restartResponses()
                    // this.MBS.actions.snackbar()
                    //this.MBS.actions.go('/my-account')
                    
                }else if (res.res==='signing/withEmail/error'){
                    this.inputLoading = false 

                    let sms = null
                    let code = null
                    if (res.val.code==="auth/user-not-found") {
                        sms = "Phone not registered. Please, make sure you enter a registered number and try again"
                        code = NAME+':LOGIN-FAILED'
                    }else if (res.val.code==="auth/network-request-failed") {
                        sms = "Network error, please make sure you have a working network and try again."
                        code = NAME+':LOGIN-FAILED-RETRY'
                    }else if (res.val.code==="auth/wrong-password") {
                        sms = "Wrong password."
                        code = NAME+':LOGIN-FAILED'
                    } else {
                        sms = "something went wrong while trying to login. please, try again later"
                        code = NAME+':LOGIN-FAILED-RETRY'
                    }   
                    this.MBS.actions.dialog(null,true,true,'ERROR',sms,'',null,'OK',{code:code}) 
                    this.MBS.actions.progressDialog()
                    this.MBS.actions.snackbar()
                    this.MBS.actions.restartResponses()
                }
            },t)
        },

        onChange(input) {
            // this.input = input;
        },
        onKeyPress(button) { 
            let focused_field = this.focused_field
            const field_value = focused_field?focused_field.value:null 
            if (button ) {
                let value = button
                if (field_value) { 
                    if (field_value == "inputPhone") {
                        if (value=="{bksp}") {
                            this.inputPhone = (""+this.inputPhone).slice(0,-1)
                        } else if (value.indexOf('{')==-1) { 
                            this.inputPhone =this.inputPhone+value 
                        } else {
                            console.log(value,'v-----');
                        }
                    }else{
                        console.log(this.input,'in......');
                        if(!this.input){this.input={}}
                        if (value=="{bksp}") {
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                        }else if (value.indexOf('{')==-1) { 
                            this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value 
                        }else{
                            console.log(value,'v-----');
                        }
                        console.log(this.input ,'this.input ....');
                        setTimeout(() => {
                            this.input = {...this.input}
                        }, 1); 
                    }
                     
                }
            }
        },
        onInputChange(input) {
            // console.log(input);
            // this.input = input.target.value;
        }
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
    },
    watch:{ 
        us(value){
            this.FEEDBACKS(1000)
        },
        processes(value){
            this.FEEDBACKS(1000)
        },
        loading(value){
            this.FEEDBACKS(1000)
        }, 
        iLocation(value){
            this.FEEDBACKS(1)
        },
        dialog(value){ 
            this.inputLoading = false
        }
    }
}
</script> 