<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <h1 class="grey--text text--darken-1">Transfer Stock</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row>
                        <v-col cols="12" md="4">
                            <h4 class="grey--text text--darken-1">Order Details</h4>
                            <div class="text-justify">
                                Stock transfer, is the act of moving goods from one part of the distribution chain (<strong>Outlet</strong>) to another. <br>
                                Adding details to this transfer helps your company to easily identify outgoing and incoming items in the company.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.transfer_stock"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Items</h4>
                    <v-row>
                        <v-col cols="12" md="3">
                            <div>
                                Select items you want to adjust by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="JOINED_ITEMS" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content>
                                            <v-list-item-action class="pr-2">
                                                <div>Current <br> Inventory</div>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        QTY
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0" >
                                                        Price  
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="80">
                                                    <v-layout class="ma-0" >
                                                        Discount
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                            <v-list-item-action>
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0 pl-2" justify-center>
                                                    <div class="text-center">Total Price (MWK)</div> 
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>
                                    <v-virtual-scroll v-else 
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading  "
                                                                v-mask="'####'" outlined
                                                                filled dense  required
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="100">
                                                            <v-text-field
                                                                :disabled="inputLoading " required
                                                                v-mask="'########'" filled dense  outlined
                                                                v-model="item.price" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="80">
                                                            <v-text-field
                                                                :disabled="inputLoading " required
                                                                v-mask="'#####'" filled dense  outlined
                                                                v-model="item.discount" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)" 
                                                            :disabled="inputLoading"
                                                            small icon>
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll> 

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(selected_items)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Submit</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL MWK {{
                                                MBS.actions.money(CALCULATE_TOTAL_QT_PRICE(selected_items))
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = "TRANSFER-STOCK"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                order_code:null, 
                show_input:true, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            ///////// 

            PaymentMethods(){
                return this.$store.getters.getDefaultPaymentMethods
            }, 
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },   
            COMPANY_DATA_PATH(){  
                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                if(!company_key){
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"No company is selected. Please, make sure you select the company.",
                        btnYes:"SELECT COMPANY",
                        action:{
                            code:PAGE_NAME+"=SELECT-COMPANY",
                        }
                    })
                    return null
                } 
                let P_DATA = (DATA.APP.SERVER+'/'+DATA.ITEMS.COMPANY_DATA.values).toUpperCase()+'/'+company_key+'/'  
                return P_DATA
            }, 
            ITEMS(){
                return this.$store.getters.getItems
            },  
            Suppliers(){ 
                return this.$store.getters.getSuppliers
            },  
            Outlets(){
                return this.$store.getters.getStockOutlets
            },  
            QuotationsAndInvoices(){
                return this.$store.getters.getQuotationsAndInvoices
            }, 
            StockOrders(){
                return this.$store.getters.getStockOrders
            }, 
            Joined_QuotationsAndInvoices(){
                let items = this.QuotationsAndInvoices 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.QUOTATIONS_AND_INVOICES.values
                })
                return joined
            }, 
            Joined_StockOrders(){
                let items = this.StockOrders 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.STOCK_ORDERS.values
                })
                return joined
            }, 
            RequestForQuotations(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Invoices(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                });
                return filtered_items
            },  
            ReceivedOrders(){
                let items = this.Joined_StockOrders
                if(!items){return null}
                let filtered_items = items.filter(item => {
                    return this.MBS.actions.TEXT_UP(item.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_RECEIVES.value)  
                });
                return filtered_items
            },  
            SupplierCategories(){ 
                return this.$store.getters.getSupplierCategories
            },
            JOINED_ITEMS(){ 
                let items = this.ITEMS 
                let joined = this.MBS.actions.JOIN({
                    items:items,
                    name:DATA.ITEMS.ITEMS.values
                })
                return joined
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let price = this.toNumber(item.price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let Invoices = this.Invoices
                let ReceivedOrders = this.ReceivedOrders
                let SupplierCategories = this.SupplierCategories
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                    Invoices:Invoices,
                    ReceivedOrders:ReceivedOrders,
                    SupplierCategories:SupplierCategories,
                }
            },
            DisabledFields(){
                let order_code = this.order_code 
                if (order_code) {
                    return ['supplier_code','payment_method','transferred_outlet_code']
                }
                // return ['supplier_code','payment_method','description','transferred_date','outlet_code','transferred_outlet_code']
            } 
        },
        methods: { 
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.JOINED_ITEMS
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                                discount:0,
                                price:item_selected.cost_price
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input 
                    let code = input?input.code:null 
                    let order_code = input?input.order_code:null
                    let supplier_code = input?input.supplier_code:null
                    let outlet_code = input?input.outlet_code:null  
                    let transferred_outlet_code = input?input.transferred_outlet_code:null  
                    let delivery_date = input?input.delivery_date:null  
                    let payment_method = input?input.payment_method:null
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        stock_order_items.push({
                            item_key:element.key, 
                            price:this.toNumber(element.price), 
                            discount:this.toNumber(element.discount), 
                            quantity:this.toNumber(element.quantity), 
                        })
                    });
                    let order_details = {
                        code:code,  
                        supplier_code:supplier_code,  
                        outlet_code:outlet_code,   
                        transferred_outlet_code:transferred_outlet_code,   
                        order_type:DATA.ITEMS.STOCK_TRANSFERS.value,
                        items:stock_order_items
                    }
                    if (payment_method) {
                        order_details.payment_method = payment_method
                    }  
                    if (order_code) {
                        order_details.order_code = order_code
                    }
                    if (order_code) {
                        order_details.order_code = order_code
                    }  
                    if (delivery_date) {
                        order_details.delivery_date = delivery_date
                    }  
                    if (description) {
                        order_details.description = description
                    }  
 
                    console.log(order_details,'order_details....');
                    if (outlet_code === transferred_outlet_code) {
                        this.MBS.actions.dialog({
                            show:true,
                            title:"SAME OUTLET",
                            text:"Select different Transfer out. You can not transfer to the same outlet.",
                            btnYes:'OK', 
                        })
                        return
                    }
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.STOCK_ORDERS.name,"Transfer")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_orders,true) 
                    if (!order || !path) {
                        console.log(order,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.STOCK_ORDERS.values,
                        data:order,
                        path:path
                    }).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.STOCK_ORDERS.value,"Transferred")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        // let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/stock-control/",true)
                        this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Transferred")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_REQUEST_FOR_QUOTATION(order_code){
                try {  
                    this.show_input = false
                    let invoices = this.ReceivedOrders  
                    console.log(invoices,order_code,'000');
                    if(!order_code){this.show_input = false; return}
                    if(!invoices){this.show_input = false; return}
                    let invoice = invoices.find(q=>{
                        return q.code == order_code
                    })  
                    console.log(invoices,'111');
                    if (invoice) {
                        this.selected_items = invoice.joined_items  
                        this.input = {
                            ...this.input, 
                            description : invoice.description,
                            supplier_code : invoice.supplier_code, 
                            transferred_outlet_code : invoice.deliver_to_outlet_code, 
                            payment_method : invoice.payment_method, 
                        } 
                            console.log(order_code,111);
                        setTimeout(() => {
                            console.log(invoices,222);
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_REQUEST_FOR_QUOTATION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let order_code =v?v.order_code:null 
                if ( order_code && order_code != this.order_code) {
                    this.order_code = order_code
                    this.ADD_REQUEST_FOR_QUOTATION(order_code) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
