<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <br> 
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            v-model="input_table"
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_only_display="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="FIELD_ITEMS"
                            :header_items="HEADER_ITEMS" 
                            :data_items="CalculatedItems"   
                            :is_joined="true"  
                            :path_item="SALES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from "../../../../../plugins/DATA"
    let PAGE_NAME = DATA.ITEMS.SALES.values    
    import {mapState,mapGetters } from "vuex"
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:"Sales Items",
                NAME_ITEM:"Sales Item",
                VALUE_ITEMS:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                VALUE_ITEM:DATA.ITEMS.DAYS_SHIFTS_SALES.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                tableTabs:[
                    {name:"Items Per Transaction",show_if:null},
                    {name:"Aggregated Items",show_if:null},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ],
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},   
                    // {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Item"},   
                ],
                TOTAL_ITEM_VALUES:[ 
                    {name:'Total Items',value:'sold_quantity'},
                    {name:'Total Cost Price',value:'sold_cost_price',show_value:'sold_cost_price_',money:true},
                    {name:'Total Sales Price',value:'total_sold_price',show_value:'total_sold_price_',money:true},
                ], 
                filter_date:null,
                filteredDate:null,
                currentTableTab:null,
                input_table:null, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: { 
             ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSalesOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
            }), 

            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("sales",3,true) 
                return thisLinks
            }, 
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            }, 
            moment(){  
                return this.MBS.date.moment
            }, 
            HEADER_ITEMS1(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"Barcode",value:'key',show:false},   
                    {id:0,name:"date",value:'local_created_at_',show:true},           
                    {id:0,name:"transaction",value:"transaction_key",show:true},     
                    {id:0,name:"Item",value:"name",show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'right',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            }, 
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Barcode",value:'code',show:false},           
                    {id:0,name:"item",value:"name",show:true},     
                    {id:0,name:"Transactions",value:"number_of_transactions",align:'center',show:true},     
                    {id:0,name:"quantity",value:'sold_quantity',align:'center',show:true},   
                    {id:0,name:"Discount",value:'sold_discount_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'sold_cost_price_',align:'right',show:true},   
                    {id:0,name:"Sale Price",value:'total_sold_price_',align:'right',show:true},   
                    {id:0,name:"Created",value:'created_at_',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"..",action:"view",icon:"mdi-eyeee"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.HEADER_ITEMS1
                }else{
                    return this.HEADER_ITEMS2
                }
            }, 
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 

            AggregatedItems(){
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {
                    let date = moment(order.local_created_at).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                let index = items.findIndex(it=>{
                                    return it.item_key  == item.item_key
                                }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount

                                if (index == -1) { 
                                    items.push({
                                        ...item, 
                                        sold_quantity:sold_quantity,
                                        sold_cost_price:sold_cost_price,
                                        sold_discount:sold_discount,
                                        sold_price:sold_price,
                                        total_sold_price:total_sold_price,
                                        number_of_transactions:1,
                                        
                                        sold_quantity_:this.MBS.actions.money( sold_quantity),
                                        sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                        sold_discount_:this.MBS.actions.money( sold_discount),
                                        sold_price_:this.MBS.actions.money( sold_price),
                                        total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    })
                                } else {
                                    items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                    items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                    items[index].sold_discount = items[index].sold_discount+sold_discount
                                    items[index].sold_price = items[index].sold_price+sold_price
                                    items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                    items[index].number_of_transactions = items[index].number_of_transactions+1
                                    
                                    items[index].sold_quantity_ = this.MBS.actions.money( items[index].sold_quantity)
                                    items[index].sold_cost_price_ = this.MBS.actions.money( items[index].sold_cost_price)
                                    items[index].sold_discount_ = this.MBS.actions.money( items[index].sold_discount)
                                    items[index].sold_price_ = this.MBS.actions.money( items[index].sold_price)
                                    items[index].total_sold_price_ = this.MBS.actions.money( items[index].total_sold_price)
                                }    
                            });
                            
                        }
                    }     
                });  
                return items
            },
            PerTransactionItems(){ 
                let SaleOrders = this.SalesOrdersData
                let moment = this.moment
                let filteredDate = this.filteredDate
                let difference = filteredDate?filteredDate.difference:null
                let to_date = filteredDate?filteredDate.to_date:null
                let from_date = filteredDate?filteredDate.from_date:null
                if(!SaleOrders){return null}
                let items = []
                SaleOrders.forEach(order => {  
                    let date = moment(order.local_created_at).format("yyyy-MM-D")
                    let momentDate = moment(date) 
                    let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                    if (isBetween) {
                        let sold_unjoined_items = order.items
                        let sold_joined_items = order.joined_items
                        let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                        let total_discount = this.toNumber(order.total_discount)
                        let item_size = this.MBS.actions.SIZE(sold_items)
                        let average_discount = total_discount/item_size
                        if (sold_items) {
                            sold_items.forEach(item => { 
                                // let index = items.findIndex(it=>{
                                //     return it.item_key  == item.item_key
                                // }) 
                                let sold_quantity = this.toNumber(item.quantity)
                                let sold_cost_price = this.toNumber(item.sold_cost_price)*sold_quantity
                                let sold_discount = (this.toNumber(item.discount)*sold_quantity)+average_discount
                                let sold_price = this.toNumber(item.sold_price)
                                let total_sold_price = (sold_price*sold_quantity)-sold_discount
                                

                                items.push({
                                    ...item, 
                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    
                                    sold_quantity_:this.MBS.actions.money( sold_quantity),
                                    sold_cost_price_:this.MBS.actions.money( sold_cost_price),
                                    sold_discount_:this.MBS.actions.money( sold_discount),
                                    sold_price_:this.MBS.actions.money( sold_price),
                                    total_sold_price_:this.MBS.actions.money( total_sold_price),
                                    
                                    transaction_key:order.key,

                                    local_created_at:order.local_created_at,
                                    local_created_at_:order.local_created_at_,
                                    local_created_at_date:order.local_created_at_date,
                                    local_created_at_time:order.local_created_at_time,
                                })
                                     
                            });
                            
                        }
                    }     
                });   
                return items
            },
            CalculatedItems(){
                let tap = this.currentTableTab
                if (tap==0) {
                    return this.PerTransactionItems
                }else{
                    return this.AggregatedItems
                }
            }  
        },
        methods: { 
            
            TABLE_ACTION(action,item){
                try { 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "return_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/return-stock",true))
                    }else if (action == "transfer_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/transfer-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{ 
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            input_table(value){ 
                if (value) {
                    this.filteredDate = value.filter_date
                    this.currentTableTab = value.current_table_tab
                } 
            } 

        },
    }
</script>
 
