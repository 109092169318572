<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <v-card v-if="ThisMoneyAccount">
                            <mbs-item-manager
                                :top_label="false"
                                :filter_date="true"
                                :filter_date_value="'local_created_at'"
                                :filter_default_days="'90'"
                                :name_items=" ThisMoneyAccount.name+' '+NAME_ITEM"
                                :name_item="NAME_ITEM"
                                :value_items="VALUE_ITEMS"
                                :value_item="VALUE_ITEM"
                                :field_items="FIELD_ITEMS"
                                :header_items="HEADER_ITEMS" 
                                :data_items="Transactions"   
                                :path_item="SALES_PATH"   
                                :total_item_values="TOTAL_ITEM_VALUES"   
    
                                :table_actions="TableAction" 
                                @SELECT_TABLE_ACTION="TABLE_ACTION"
                                @SELECT_ITEM_ACTION="ITEM_ACTION"
                            /> 
                        </v-card>
                        <v-card v-else :height="vsh-350" flat color="transparent">
                            <v-layout row wrap fill-height justify-center align-center>
                                <v-icon>warning</v-icon>
                                <div>Not Found</div>
                            </v-layout>
                        </v-card>
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- GIVEN AMOUNT -->
            <v-dialog
                v-model="dialog_pay_opening_balance"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">OPENING BALANCE</div> 
                        <v-spacer></v-spacer>
                        <v-btn icon  dark @click="dialog_pay_opening_balance=false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text >
                        <br>   
                        <form @submit.prevent="ADD_OPENING_BALANCE()">
                            <mbs-input-fields  
                                v-model="input"
                                :field_items="DATA.FIELDS.opening_balance" 
                                />  
                            <v-layout class="ma-0">
                                <v-spacer></v-spacer>
                                <v-btn :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Add Opening Balance</v-btn>
                            </v-layout>
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.INCOMES.values    
    export default {
        name:PAGE_NAME, 
        props:['id','money_account_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.CASHBOOKS.names,
                NAME_ITEM:DATA.ITEMS.CASHBOOKS.name,
                VALUE_ITEMS:DATA.ITEMS.CASHBOOKS.values,
                VALUE_ITEM:DATA.ITEMS.CASHBOOKS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],  
                TOTAL_ITEM_VALUES:[  
                    {name:'Total Deposits',value:'total_deposit',show_value:'total_deposit_',money:true},
                    {name:'Total Payments',value:'total_paid',show_value:'total_paid_',money:true},
                    // {name:'Total Balance',value:'total_balance',show_value:'total_balance_',money:true},
                ],
                dialog_pay_opening_balance:false,
                input:{},
                inputLoading:false,
                DATA:DATA,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
             
            /////////
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payments,true) 
                return path
            },
            back_MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("money-accounts",3,true) 
                return thisLinks
            }, 
            MY_LINKS(){  
                let accounts = this.MoneyAccountsData 
                if(!accounts){return null}
                let allLinks = []
                accounts.forEach(element => {
                    allLinks.push({
                        name:element.name,
                        link:this.MBS.actions.COMPANY_LINK("/office/accounts/money-accounts/"+element.code,true),
                        icon:'link',
                        image:'/future_tours/svg/SVG/my_parcel.svg',
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            },
            SALES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
                return path
            },  
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'created_at_',show:true},     
                    {id:0,name:"updated Date",value:'updated_at_',show:false},   
                    {id:0,name:"Payee",value:"payee_name",show:true},       
                    {id:0,name:"Transaction",value:"order_type_name",show:true},       
                    {id:0,name:"Transaction key",value:"transaction_key",align:"center",show:false},     
                    {id:0,name:"description",value:"description",show:true},         
                    {id:0,name:"Deposit",value:"total_deposit_",align:"right",show:true},       
                    {id:0,name:"Payment",value:"total_paid_",align:"right",show:true},       
                    {id:0,name:"Balance",value:"total_balance_",align:"right",show:true},       
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Sales Report",action:"view_sales_report",icon:"mdi-eye",show_if:item=>item.order_type==DATA.ITEMS.DAYS_SHIFTS_SALES.values}, 
                        {name:"View Transactions",action:"view_transaction",icon:"mdi-eye",show_if:item=>item.order_type!=DATA.ITEMS.DAYS_SHIFTS_SALES.values}, 
                        // {name:"Edit Receivable Account",action:"edit_item",icon:"edit"}, 
                        // {name:"Archive Receivable Account",action:"archive_items",icon:"mdi-delete"}, 
                    ]},  
                ]
            }, 
            TableAction(){
                // let Transactions = this.Transactions
                // if (this.MBS.actions.SIZE(Transactions)>0) {
                //     return []
                // }  
                return [ 
                    // {type:"btn",action:"add_opening_balance",color:"secondary", outlined:false,  text:"Add Opening Balance"},  
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},
                ]
            },  
            ThisMoneyAccount(){  
                let money_account_code = this.money_account_code
                let MoneyAccountsData = this.MoneyAccountsData
                if (!money_account_code) {
                    return  null
                }else if (money_account_code == 0) {
                    return {
                        name:"Cash Account",
                        code:0
                    }
                } else {
                    if(!MoneyAccountsData){return null}
                    let moneyAccount = MoneyAccountsData.find(account=>{
                        return account.code == money_account_code
                    })
                    return moneyAccount
                } 
            }, 

            //
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                MoneyAccounts: state=> state.items[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                JoinedMoneyAccounts: state=> state.join[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                Expenses: state=> state.items[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                JoinedExpenses: state=> state.join[(DATA.ITEMS.EXPENSES.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()],
            }),  
            MoneyAccountsData(){ 
                let items = []
                let DefaultMoneyAccount = this.DefaultMoneyAccount 
                let MoneyAccounts = this.JoinedMoneyAccounts?this.JoinedMoneyAccounts:this.MoneyAccounts
                if(DefaultMoneyAccount){
                    items = [...DefaultMoneyAccount]
                }
                if(MoneyAccounts){
                    items= [
                        ...items,
                        ...MoneyAccounts
                    ]
                } 
                return items
            },
            PaymentsData(){ 
                return this.JoinedPayments?this.JoinedPayments:this.Payments
            },
            SaleOrdersData(){ 
                return this.JoinedSaleOrders?this.JoinedSaleOrders:this.SaleOrders
            },
            ExpensesData(){ 
                return this.JoinedExpenses?this.JoinedExpenses:this.Expenses
            },
            QuotationsAndInvoicesData(){ 
                return this.JoinedQuotationsAndInvoices?this.JoinedQuotationsAndInvoices:this.QuotationsAndInvoices
            },
            DaysShiftsData(){ 
                return this.JoinedDaysShifts?this.JoinedDaysShifts:this.DaysShifts
            },  

            
            Invoices(){
                let orders = this.QuotationsAndInvoicesData
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return !order.archive && 
                        order.order_type == DATA.ITEMS.INVOICES.value &&
                        this.MBS.actions.TEXT_UP0(order.payment_method) != "bill"
                })
                return filtered_items
            },
            AllOnlyReceivedStock(){  
                let items = this.$store.getters.getStockOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                return list
            },  
            OnlyReceivedStock(){
                let orders = this.AllOnlyReceivedStock 
                if(!orders){return null} 
                let filtered_items = orders.filter(order=>{
                    return !order.archive && 
                        !order.invoice_code &&
                        order.order_type == DATA.ITEMS.STOCK_RECEIVES.value &&
                        this.MBS.actions.TEXT_UP0(order.payment_method) != "bill"
                })
                return filtered_items
            }, 
            
            DaysShiftsCashPayments(){
                let days = this.DaysShiftsData  
                if(!days){return null} 
                let cashes = []
                days.forEach(element => {
                    // console.log(element ,'element days..z..');
                    cashes.push({
                        key:element.key,
                        transaction_key:element.key,
                        transaction_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        order_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        name:element.close_date_time_,
                        amount:element.total_closing_cash,
                        operating_date:element.operating_date,
                        created_at:element.local_close_at,
                        created_uid:element.created_uid,
                        description:element.closed?"Closed":'Running.....',
                    })
                }); 
                
                return this.MBS.actions.SIZE(cashes)>0?cashes:null
            }, 
            DaysShiftsOtherPayments(){
                let days = this.DaysShiftsData 
                return code=>{
                    console.log(days,code,'///......')
                    let cashes = [] 
                    if(!days){return null}  
                    days.forEach(day => {
                        let total_cashup = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(day.joined_cashups)
                        let all_other_option_payments = total_cashup?total_cashup.all_other_option_payments:null
                        if (all_other_option_payments) {
                            let payments = all_other_option_payments.filter(option=>{
                                return option.money_account_code  == code
                            }) 
                            if (payments) {
                                let details = {
                                    key:day.key,
                                    transaction_key:day.key,
                                    transaction_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                                    order_type:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                                    name:day.close_date_time_,
                                    // amount:payment.amount,
                                    amount:0,
                                    operating_date:day.operating_date,
                                    created_at:day.local_close_at,
                                    created_uid:day.created_uid,
                                    description:day.closed?"Closed":'Running.....',
                                }
                                payments.forEach(payment => {
                                    details.amount = details.amount+payment.amount
                                });
                                cashes.push(details) 
                            }
                        }  
                    });


                    return this.MBS.actions.SIZE(cashes)>0?cashes:null
                }
            }, 
            DaysShiftsPayments(){
                let ThisMoneyAccount = this.ThisMoneyAccount
                let money_account_code = ThisMoneyAccount?ThisMoneyAccount.code:null 
                let cashes = null 
                if (this.MBS.actions.TEXT_UP0(money_account_code) == 'cash' || !money_account_code ) {
                    cashes = this.DaysShiftsCashPayments  
                }else if (this.MBS.actions.TEXT_UP0(money_account_code) == 'petty_cash') {
                    // cashes = this.DaysShiftsCashPayments  
                }else{
                    cashes = this.DaysShiftsOtherPayments(money_account_code)
                } 
                return cashes
            }, 

            FilteredCashExpense(){
                let orders = this.ExpensesData  
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return  this.MBS.actions.TEXT_UP0(order.payment_method_code) == 'cash' || !order.payment_method_code
                })
                return filtered_items
            }, 
            FilteredPettyCashExpense(){
                let orders = this.ExpensesData 
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return  this.MBS.actions.TEXT_UP0(order.payment_method_code) == 'petty_cash'  
                })
                return filtered_items
            }, 
            FilteredOtherPaymentExpense(){
                return code =>{
                    let orders = this.ExpensesData 
                    if(!orders){return null}
                    let filtered_items = orders.filter(order=>{
                        let payment_method_code = order.payment_method_code
                        let payment_method = order.payment_method
                        if(!payment_method){
                            payment_method = this.$store.getters.getPaymentMethodByCode(payment_method_code)
                        }
                        let money_account_code = payment_method?payment_method.money_account_code:null
                        return money_account_code == code
                    })
                    return filtered_items 
                }
            },
            FilteredExpenses(){
                let ThisMoneyAccount = this.ThisMoneyAccount
                let money_account_code = ThisMoneyAccount?ThisMoneyAccount.code:null 
                let Expenses = null 
                if (this.MBS.actions.TEXT_UP0(money_account_code) == 'cash' || !money_account_code ) {
                    Expenses = this.FilteredCashExpense  
                }else if (this.MBS.actions.TEXT_UP0(money_account_code) == 'petty_cash') {
                    Expenses = this.FilteredPettyCashExpense  
                }else{
                    Expenses = this.FilteredOtherPaymentExpense(money_account_code)
                } 
                return Expenses
            }, 
            
            FilteredCashPayments(){
                let orders = this.PaymentsData 
                console.log(orders,'PaymentsData.......*****');
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return  this.MBS.actions.TEXT_UP0(order.payment_method_code) == 'cash' || !order.payment_method_code
                })
                return filtered_items
            }, 
            FilteredPettyCashPayments(){
                let orders = this.PaymentsData 
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return  this.MBS.actions.TEXT_UP0(order.payment_method_code) == 'petty_cash'  
                })
                return filtered_items
            }, 
            FilteredOtherPayments(){
                return code =>{
                    let orders = this.PaymentsData 
                    if(!orders){return null}
                    let filtered_items = orders.filter(order=>{
                        let payment_method_code = order.payment_method_code
                        let payment_method = order.payment_method
                        if(!payment_method){
                            payment_method = this.$store.getters.getPaymentMethodByCode(payment_method_code)
                        } 
                        let money_account_code = payment_method?payment_method.money_account_code:null
                        return money_account_code == code
                    })
                    return filtered_items 
                }
            },
            FilteredPayments(){
                let ThisMoneyAccount = this.ThisMoneyAccount
                let money_account_code = ThisMoneyAccount?ThisMoneyAccount.code:null 
                let Expenses = null 
                if (this.MBS.actions.TEXT_UP0(money_account_code) == 'cash' || !money_account_code ) {
                    Expenses = this.FilteredCashPayments  
                }else if (this.MBS.actions.TEXT_UP0(money_account_code) == 'petty_cash') {
                    Expenses = this.FilteredPettyCashPayments
                }else{
                    Expenses = this.FilteredOtherPayments(money_account_code)
                } 
                return Expenses
            }, 
            
            Transactions(){  
                let DaysShiftsPayments = this.DaysShiftsPayments 
                let Expenses = this.FilteredExpenses 
                let Payments = this.FilteredPayments 
                let items = []
                let moment = this.MBS.date.moment

                 
                if (DaysShiftsPayments) { 
                    DaysShiftsPayments.forEach(element => {
                        items.push({
                            ...element,
                            local_created_at :  moment?moment(element.created_at).valueOf():element.created_at
                        })
                    }); 
                }if (Expenses) { 
                    Expenses.forEach(element => {
                        items.push({
                            ...element,
                            order_type:DATA.ITEMS.EXPENSES.values,
                            local_created_at :  moment?moment(element.created_at).valueOf():element.created_at
                        })
                    }); 
                }if (Payments) { 
                    Payments.forEach(element => {
                        items.push({
                            ...element,
                            order_type:DATA.ITEMS.PAYMENTS.values,
                            local_created_at :  moment?moment(element.created_at).valueOf():element.created_at
                        })
                    }); 
                }
                  
                if(this.MBS.actions.SIZE(items)==0){return null} 
                let join =  this.MBS.actions.JOIN_CASHBOOKS(items)  

                // console.log(join,'join.....');
                return join
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "add_opening_balance") {
                        this.input = {}
                        this.dialog_pay_opening_balance = true
                    }else if (action == "received_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    // let link = "/stock/stock-control/"+(item?item.key:null)
                    // const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    console.log(item,"item...-.");
                    let order_type = item?item.order_type:null
                    if (action == "view_sales_report") {
                        let link = "/day-shift-report/"+(item?item.key:null)
                        const path = this.MBS.actions.COMPANY_LINK(link,true)  
                        this.MBS.actions.go(path)
                    }
                    if (action == "view_transaction") {
                        if (order_type === DATA.ITEMS.PAYMENTS.value) {
                            let receipt_key = item?item.key:null
                            this.MBS.actions.go("/payment-receipt/"+receipt_key)
                        }else if (order_type === DATA.ITEMS.SALE_ORDERS.value) {
                            let receipt_key = item?item.key:null
                            this.MBS.actions.go("/sales-receipt/"+receipt_key)
                        } else {
                            let mbs_text = this.MBS.text.option_not_available("View transactions")
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes,
                            }) 
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_OPENING_BALANCE(){
                try {
                    let input = this.input
                    let amount = input?this.toNumber(input.amount):null
                    let code = input?input.code:null
                    let date = input?input.date:null
                    let description = input?input.description:null 
                    let payment_details={
                        code:code,
                        amount:amount,
                        transaction_key:code,
                        transaction_type:DATA.ITEMS.OPENING_BALANCE.value,
                    }
                    if (date) {
                        payment_details.date = date
                    }if (description) {
                        payment_details.description = description
                    }
                    
                    if (!code || !amount) {
                        console.log(this.input,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.OPENING_BALANCE.value,"Add")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=ADD-OPENING-BALANCE",
                                data:payment_details
                            }
                        })

                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_OPENING_BALANCE',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-OPENING-BALANCE') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_pay_opening_balance = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.OPENING_BALANCE.value,"Adding")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },   
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
