<template>
    <div>   
        <v-container grid-list-xl>   
            <v-card class="mb-5 mt- pb-5" color="transparent" flat >  
                <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark>  
                    <template > 
                        <v-toolbar-title> 
                            <v-list color="transparent" v-if="Joined_DayShift" >
                                <v-list-item>
                                    <v-list-item-action><v-icon>home</v-icon></v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>{{Joined_DayShift.outlet_name}}</v-list-item-title> 
                                        <v-list-item-subtitle>{{
                                            MBS.date.moment(Joined_DayShift.operating_date).format("dddd, Do, MMMM YYYY")
                                            }}</v-list-item-subtitle> 
                                    </v-list-item-content>                                                                                                             
                                </v-list-item>
                            </v-list>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog_shift_sales_report=!dialog_shift_sales_report"     
                            class="mt-" width="" color="secondary" >
                            DETAILED REPORT
                            <v-icon class="ml-2" size="35">mdi-clipboard-list-outline</v-icon>
                        </v-btn> 

                        <v-divider vertical></v-divider> 

                        <v-btn 
                            icon @click="ON_PDF()">
                            <v-icon>mdi-file-pdf-box </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EXCEL()">
                            <v-icon>mdi-microsoft-excel </v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_EMAIL()">
                            <v-icon>mdi-email-outline</v-icon>
                        </v-btn>
                        <v-btn 
                            icon @click="ON_PRINTING()">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                </v-toolbar>
  
                <mbs-epson-day-shift-temp1
                    class="mb-5"
                    report_type="SALES-REPORT"
                    :data="Joined_DayShift" /> 
            </v-card>
        </v-container>   
        <!-- //DIALOGs -->
        <div> 
            <mbs-auto-print
                v-if="printing"
                @finish_print="FINISH_PRINT"
                :show="true"  
                :auto_print="auto_print"
                :auto_printer_name="auto_printer_name"
                :type="print_type" 
                :title="print_title" 
                :data="print_data" 
                /> 
            <mbs-day-shift-sales-report
                v-model="dialog_shift_sales_report"
                :day_shift="ThisDayShift"
                /> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    let PAGE_NAME = "SALES-READ"   
    import {mapState} from "vuex"
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','type'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                dialog_shift_sales_report:false,

                printing:false,
                print_type:'z_read',
                print_title:'SALES-REPORT',
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
                DATA:DATA,  
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            ///////// 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()],   
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
            }),
            /////////   
            ThisDayShift(){ 
                let items = this.$store.getters.getDayShiftByKey(this.day_key) 
                return items
            },   
            Joined_DayShift(){ 
                let item = this.ThisDayShift 
                if(!item){return null}
                let joined = this.MBS.actions.JOIN_DAY_SHIFT(item) 
                return joined
            }, 
            
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            TillsData(){ 
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData 
                let cashup = this.LocalCashup 
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })
                return filled
            },
            CurrentReceiptPrinter(){
                let till = this.CurrentTill 
                let printer = till?till.printer:null
                let joined = this.MBS.actions.JOIN_PRINTER(printer) 
                return joined
            },
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "order_stock") {
                        let link = this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true)
                        this.MBS.actions.go(link)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FINISH_PRINT(action,payload){
                try {   
                    console.log(action,'action....');
                    console.log(payload,'payload....');
                    let canceled = payload?payload.canceled:null
                    if(!action){return}
                    if (action.code == PAGE_NAME+"=CLOSE-CASHUP") {
                        this.$store.dispatch("fi_upload_cashups")
                        // this.$store.dispatch("fi_uploadSales")
                        this.SUBMIT_CLOSE_CASHUP(action.data) 
                        this.REFRESH_DATA()  
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            // ON_PRINTING(){
            //     try { 
            //         let lastSale = this.ThisDayShift 
            //         this.autoPrint = false
            //         setTimeout(() => {
            //             this.itemPrint = lastSale
            //             this.typePrint = 'sales_report'
            //             this.autoPrint = true  
            //         }, 5);
                     
            //     } catch (error) {
            //         this.MBS.actions.error({
            //             error:error,
            //             from:'ON_EXCEL',
            //             page:PAGE_NAME, 
            //         }) 
            //     } 
            // },  
            async ON_PRINTING(){
                try {  
                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null
                    
                    let day_shift = this.Joined_DayShift 
                    this.printing = false
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print = true
                        }
                        this.auto_printer_name = printer_name
                        this.print_data = day_shift 
                        this.printing = true   
                    }, 5); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PRINTING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: {  
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
