<template>

  <div  
    class="pb-2"   
    v-if="tb.show">  
    <div v-observe-visibility='progress_visible'></div>
    <v-toolbar   
      dark="" 
      :height="tb.height"
      :color="tb.color"
      :flat="tb.flat" >
      <v-container grid-list-xs>
        <v-layout  align-center>
           
          <v-toolbar-title v-if="tb.side?tb.side==='left':true">
            <div :class="tb.textColor+'--text '">{{tb.title?tb.title:route.name}}</div>
          </v-toolbar-title> 
          <mbs-back-home/>
          <v-spacer></v-spacer>
          <v-toolbar-title v-if="tb.side==='right'">
            <div :class="tb.textColor+'--text '">
              <div v-if="tb.action.name">{{tb.action.name}}</div>
              <div v-else>{{tb.title?tb.title:route.name}}</div> 
            </div>
          </v-toolbar-title> 
          <!-- side menu -->
          <v-tooltip bottom  > 
            <template v-slot:activator ="{on}">
              <v-btn 
                @click="onMoreInfo()"          v-on="on" icon dark >
                  <v-icon >info_outline</v-icon>
              </v-btn>

            </template>
              <span>Info about page</span>
          </v-tooltip> 
          
        </v-layout>
        
      </v-container>
    </v-toolbar>
    <v-progress-linear  
        v-if="progressToolbar.show && online"  
        :color="progressToolbar.color"
        :height="progressToolbar.height" 
        :indeterminate="true"> 
    </v-progress-linear>   
    <mbs-alert-toolbar />
  </div> 
</template>

<script> 

let NAME = 'SUB-TOOLBAR'
export default {
    name:NAME,
    props:[],
    data(){
        return{ 
            show:true,  
            alert: true,
        }
       
    },
    mounted(){  
    },
    computed:{
      //commony
      
      vs(){
          return this.$vuetify.breakpoint
      },
      vsHeight(){ 
          let h = this.vs.height-270
          let size =  'min-height:  '+h+'px;' 
          return size
      },
      route(){
        return this.$route
      },
      us(){
          let returnUser = this.$store.getters.getUser
          return returnUser
      }, 
      ud(){
          let returnUser = this.$store.getters.getUserD
          return returnUser
      },
      tb(){ 
        return this.$store.getters.getMBS_sub_toolbar
      },
      progressToolbar(){ 
        return this.$store.getters.getMBS_progress_toolbar
      }, 
      alertToolbar(){ 
        return this.$store.getters.getMBS_alert_toolbar
      },
      online(){   
        return this.$store.getters.getOnlineStatus
      },
         
    },
    methods:{ 
      navOpen(){
          this.$emit('navOpen')    
      }, 
      navSearch(){
        this.$emit('navSearch')    
      },
      progress_visible(visible){ 
        this.MBS.events.$emit('SUBTOOLBAR_VISIBILITY',{
          visible:visible
        }) 
      },
      minimizeErrorToolbar(){
        this.MBS.actions.alertDialog({
            ...this.alertToolbar,
            min:this.alertToolbar.min?false:true
        })
      }
    },
    beforeDestroy(){
        this.progress_visible(false)  
    },
    watch:{
       
    }
    

}
</script> 

