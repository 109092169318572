<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.outlets"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="DataItems"   
                            :is_joined="true"   
                            :path_item="PATH_ITEM"  
                            :select_items="ItemSelector" 
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.STOCK_OUTLETS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.STOCK_OUTLETS.names,
                NAME_ITEM:DATA.ITEMS.STOCK_OUTLETS.name,
                VALUE_ITEMS:DATA.ITEMS.STOCK_OUTLETS.values,
                VALUE_ITEM:DATA.ITEMS.STOCK_OUTLETS.value,  
                DATA:DATA,

                FIELD_ITEMS:[
                    {type:"number",value:"code",label:"Code",required:true,sm:12,md:6}, 
                    {type:"text",value:"name",label:"Name",required:true,sm:12,md:6},   
                    {type:"text",value:"location_address",label:"Location Address",required:true,sm:12,md:12},   
                    // {type:"text",value:"default_tax",label:"Default Tax",required:true,sm:12,md:6},   
                    // {type:"text",value:"saleing_price",label:"Saleing",required:true,sm:12,md:12},   
                    {type:"textarea",value:"description",label:"Description",required:true,sm:12,md:12},   
                ],     
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Outlet"}, 
                    // {type:"btn",action:"received_stock",color:"secondary",  text:"Received Stock"}, 
                    // {type:"btn",action:"return_stock",color:"secondary",  text:"Return Stock"}, 
                    // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                ] 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }  
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                OutletTypes: state=> state.app['OUTLET_TYPES'], 
            }),  
            DataItems(){
                return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
            },
            ItemSelector(){
                let OutletTypes = this.OutletTypes
                return {
                    OutletTypes:OutletTypes
                }
            },
            
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },  
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let selectedCompany = this.selectedCompany
                let company_key = selectedCompany?selectedCompany.key:null
                if(!company_key){
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        title:"ERROR",
                        text:"No company is selected. Please, make sure you select the company.",
                        btnYes:"SELECT COMPANY",
                        action:{
                            code:PAGE_NAME+"=SELECT-COMPANY",
                        }
                    })
                    return null
                } 
                let P_DATA = (DATA.APP.SERVER+'/'+DATA.ITEMS.COMPANY_DATA.values).toUpperCase()+'/'+company_key+'/'
                let P_BRANDS = P_DATA+'SETTINGS/'+(DATA.ITEMS.STOCK_OUTLETS.values.toUpperCase())+'/'
 
                return P_BRANDS
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Location",value:"location_address",show:true},          
                    {id:0,name:"Name",value:"name",show:true},     
                    {id:0,name:"Type",value:"outlet_type_name",show:true},     
                    {id:0,name:"create",value:'created_at_',align:"right",show:true},    
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit Outlet",action:"edit_item",icon:"edit"}, 
                        {name:"View Items",action:"view_order_items",icon:"mdi-eye"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: { 
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
