<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :table_tabs="tableTabs"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="AllStockOrders"   
                            :is_joined="true"
                            :path_item="SOCK_ORDER_PATH"   


                            :table_actions="TableActions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.STOCK_ORDERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.STOCK_ORDERS.names,
                NAME_ITEM:DATA.ITEMS.ORDERS.name,
                VALUE_ITEMS:DATA.ITEMS.STOCK_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.STOCK_ORDERS.value,   
                tableTabs:[
                    {name:"Received Stock",show_if:(this_,item)=>item.order_type==DATA.ITEMS.STOCK_RECEIVES.value},
                    {name:"Transferred Stock",show_if:(this_,item)=>item.order_type==DATA.ITEMS.STOCK_TRANSFERS.value},
                    {name:"Returned Stock",show_if:(this_,item)=>item.order_type==DATA.ITEMS.STOCKS_RETURN.value},
                    {name:"Returned Sales",show_if:(this_,item)=>item.order_type==DATA.ITEMS.SALES_RETURN.value},
                    {name:"Adjusted Stock",show_if:(this_,item)=>item.order_type==DATA.ITEMS.STOCK_ADJUSTMENTS.value},
                    {name:"Invoices",show_if:(this_,item)=>item.order_type==DATA.ITEMS.INVOICES.value},
                    // {name:"Over Paid Invoices",show_if:item=>item.balance<0},
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()], 
            }), 
            AllStockOrders(){ 
                let StockOrders = this.StockOrders
                console.log(StockOrders,'.........StockOrders');
                let JoinedStockOrders = this.JoinedStockOrders
                let items = JoinedStockOrders?JoinedStockOrders:StockOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("created_at_server",false)) 
                console.log(list,'.........ss');
                return list
            }, 
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },   
            /////////
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            }, 
            SOCK_ORDER_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_orders,true) 
                return path
            }, 
            
            TableActions(){
                let stockOrders = this.StockOrders
                let actions =[ 
                    {type:"btn",btn:true,action:"order_stock",color:"secondary", outlined:false,  text:"Order Stock"}, 
                    {type:"btn",btn:true,color:"secondary", text:"Receive", min_width:"",action:"receive",
                    actions:[
                        {icon:"",action:"receive_stock",text:"Receive Stock"},
                        {icon:"",action:"receive_stock_with_invoice",text:"Receive Invoice With Stock"},
                        {icon:"",action:"receive_invoice",text:"Receive Invoice without Stock"},
                        // {icon:"",action:"receive_quotation",text:"Receive Quotation"},
                    ]} , 
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                    
                ]

                if (this.MBS.actions.SIZE(stockOrders)>0) {
                    actions = [
                        ...actions,
                        {type:"btn",color:"secondary", text:"Return", min_width:"",action:"receive",
                        actions:[
                            {icon:"",action:"return_stock",text:"Return Received Stock"},
                            {icon:"",action:"return_sales",text:"Return Sales"}, 
                        ]} ,  
                        
                        // {type:"btn",action:"transfer_stock",color:"secondary",  text:"Transfer Stock"}, 
                        {type:"btn",action:"adjust_stock",color:"secondary",  text:"Adjust Stock"}, 
                    ]
                }else{
                    actions = [
                        {type:"btn",action:"opening_stock",color:"secondary", outlined:false,  text:"Opening Stock"}, 
                        ...actions
                    ]
                }
                return actions
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon',color:"secondary",size:35,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"date",value:'created_at_',show:true},   
                    {id:0,name:"update date",value:'updated_at',show:false},   
                    {id:0,name:"Type",value:'type_name',show:true},   
                    {id:0,name:"code",align:"center",show:true},         
                    {id:0,name:"user",value:"user_name",show:false},     
                    {id:0,name:"From",value:"deliver_from",show:true},     
                    {id:0,name:"To",value:"deliver_to",show:true},     
                    {id:0,name:"Status",value:"status",show:false},     
                    {id:0,name:"items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Total Cost",value:'total_price_',align:'right',show:true},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"View Order",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Archive Order",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            }, 
             
              
        },
        methods: { 
            TABLE_ACTION(action,item){
                try { 
                    if (action == "opening_stock") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/opening-stock",true))
                    }else if (action == "order_stock") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/order-stock",true))
                    }else if (action == "receive_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "receive_invoice") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/receive-invoice",true))
                    }else if (action == "receive_quotation") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/receive-quotation",true))
                    }else if (action == "receive_stock_with_invoice") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock-with-invoice",true))
                    }else if (action == "return_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/return-stock",true))
                    }else if (action == "transfer_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/transfer-stock",true))
                    } else if (action == "adjust_stock") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/adjust-stock",true))
                    }else if (action == "return_sales") { 
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/sales-return",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try { 
                    let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  
                    if (action == "view_order") {
                        this.MBS.actions.go(path)
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            }, 
        },
        watch: { 
            us:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
