import users from '../users'   
import DATA from '../../plugins/DATA' 
import DB from '../../plugins/DB'  
import WORKER from 'simple-web-worker'
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  DEFINED_ONLY
} from '../../plugins/MBS_actions' 
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'      
const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
  
let NAME = "STORE-LOCALS"

export default{
    state: {  
      LOCAL_DEVICES:null,  
      LOCAL_DAYS_SHIFTS:null, 
      LOCAL_CASHUPS:null, 
      LOCAL_SALE_ORDERS:null, 
      LOCAL_ITEM_ORDERS:null, 
      LOCAL_SELECTED_SALES_ITEMS:null, 
      LOCAL_STOCK_TAKING_ITEMS:null,  

      LOCAL_QUOTATIONS:null, 
      LOCAL_JOINED_STOCK_TAKINGS:null,  
      LOCAL_WAREHOUSE_SESSIONS:null,  
      LOCAL_WAREHOUSE_ORDERS:null,

      UPDATE_DAY_SHIFT_LOADING:false,   
      UPDATE_DAY_SHIFT_WAITING:false,   
      UPDATE_CASHUP_LOADING:false,   
      UPDATE_CASHUP_WAITING:false,   
       
    },
    //___________________-GETTERS-_________________
    getters: {  
      get(state) { 
        return state
      },
      getLocalCashups(state) { 
        return state.LOCAL_CASHUPS
      },  

    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setLocals(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null 
          state[name]=data  
        } catch (error) {
          console.log(error,'error.....');
        }
      }, 
    },
    //____________________[ACTIONS]_____________________
    actions: {   
      initiate_locals({ commit,state}, payload) {
        let response = {
          from:'initiate_locals',
          page:NAME, 
          payload:payload
        }
        return new Promise(async (resolve,reject)=>{
          try {
            let names = payload?payload.names:null  
            
            if (!names) {
              names=[
                DATA.ITEMS.LOCAL_DEVICES.values,
                DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                DATA.ITEMS.LOCAL_CASHUPS.values,
                DATA.ITEMS.LOCAL_SALE_ORDERS.values,
                DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
                DATA.ITEMS.LOCAL_STOCK_TAKING_ITEMS.values,
                DATA.ITEMS.LOCAL_QUOTATIONS.values,
              ]
            }
            let names_data=[]
            if (names) {
              for (const name of names) {
                let db_res = await DB.getItems({
                  name:name
                })
                let data = db_res?db_res.data:null 
                names_data.push({
                  name:name,
                  data:data
                })
                commit("setLocals",{
                  name:TEXT_UP(name),
                  data:data,
                })  
              } 
            } 
            resolve({
              success:true,
              names_data:names_data
            }) 
          } catch (error) {
            response = {
              ...response,
              error:{
                error:error,
                data:payload
              }, 
            }
            commit("ERROR",response)
            reject(response)
          } 
        })
      }, 
      initiate_company_locals({ commit,state}, payload) {
        let response = {
          from:'initiate_company_locals',
          page:NAME, 
          payload:payload
        }
        return new Promise(async (resolve,reject)=>{
          try {
            let names = payload?payload.names:null  
            let company_key = payload?payload.company_key:null  
 
            if (!names) {
              names=[
                // DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                // DATA.ITEMS.LOCAL_CASHUPS.values,
                // DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                // DATA.ITEMS.LOCAL_QUOTATIONS.values,
                // DATA.ITEMS.LOCAL_SELECTED_SALES_ITEMS.values,
              ]
            }
            let names_data=[]
            if (names&&company_key) {
              for (const name of names) {
                let db_res = await DB.getItems({
                  name:name,
                  company_key:company_key,
                })
                let data = db_res?db_res.data:null 
                names_data.push({
                  name:name,
                  data:data,
                  company_key:company_key,
                }) 
                commit("setLocals",{
                  name:TEXT_UP(name),
                  data:data,
                })  
              } 
            } 
            resolve({
              success:true,
              names_data:names_data
            }) 
          } catch (error) {
            response = {
              ...response,
              error:{
                error:error,
                data:payload
              }, 
            }
            commit("ERROR",response)
            reject(response)
          } 
        })
      }, 
      on_update_locals({ commit,dispatch}, payload) {
        let response = {
          from:'on_update_locals',
          page:NAME, 
          payload:payload
        }
        return new Promise(async (resolve,reject)=>{
          try { 
            let name = payload?payload.name:null
            let data = payload?payload.data:null
            let data_after = payload?payload.data_after:null
            let company_key = payload?payload.company_key:null  
            commit("setLocals",{
              name:TEXT_UP(name),
              data:data_after,
              company_key:company_key,
            }) 
            let res = null
            let resWithError = null
            try {
              res = await dispatch("upload_locals",{
                name:name,
                names:[{value:name,data:data_after}], 
                company_key:company_key,
              })  
            } catch (error) {
              resWithError = error
            }
 
            if (resWithError) {
              resolve({
                success:false,
                res:res,
                errors:resWithError,
                error:"finish with error",
              }) 
            } else {
              resolve({
                success:true,
                res:res
              })  
            }
          } catch (error) {
            response = {
              ...response,
              error:{
                error:error,
                data:payload
              }, 
            }
            commit("ERROR",response)
            reject(response)
          } 
        })
      }, 
      upload_locals({ commit,dispatch}, payload) {
        let response = {
          from:'upload_locals',
          page:NAME, 
          payload:payload
        }
        return new Promise(async (resolve,reject)=>{
          try { 
            let names = payload?payload.names:null 
            if(!names){
              names = [
                {value:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values},
                {value:DATA.ITEMS.LOCAL_CASHUPS.values},
                {value:DATA.ITEMS.QUOTATIONS.value},
              ]
            }
            let successes = []
            let submit_errors = false
            let errors = [] 
            for (const name of names) {
              try {
                let res = {}
                if (name.value == DATA.ITEMS.LOCAL_DAYS_SHIFTS.values) {
                  res = await dispatch("fi_upload_day_shifts",{
                    data:name.data 
                  })  
                }else if (name.value == DATA.ITEMS.LOCAL_CASHUPS.values) {
                  res = await dispatch("fi_upload_cashups",{
                    data:name.data 
                  })  
                }else if (name.value == DATA.ITEMS.LOCAL_QUOTATIONS.values) {
                  res = await dispatch("fi_upload_quotations",{
                    data:name.data 
                  })  
                }else if (name.value == DATA.ITEMS.QUOTATIONS.values) {
                  res = await dispatch("fi_upload_quotations",{
                    data:name.data 
                  })  
                }else{
                  res = {error:'no name'}
                } 
                successes.push({
                  name:name,
                  res:res
                })
              } catch (error) {
                errors.push({
                  name:name,
                  error:error
                })
                submit_errors = true
              }
            } 
            if (submit_errors) {
              reject({
                error:"finish with error",
                errors:errors,
                successes:successes, 
              })
            } else {
              resolve({
                success:true,
                errors:errors,
                successes:successes, 
              })
            } 
             
          } catch (error) {
            response = {
              ...response,
              error:{
                error:error,
                data:payload
              }, 
            }
            commit("ERROR",response)
            reject(response)
          } 
        })
      }, 

      //-----FI UPLOAD
      fi_upload_day_shifts({ commit,dispatch,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'fi_upload_day_shifts',
            page:NAME, 
            payload:payload
          }
          try { 
             
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'uploading/day_shifts' 
            })  

            //initiate
            let data = payload?payload.data:null    
            if (state.UPDATE_DAY_SHIFT_LOADING) {
              state.UPDATE_DAY_SHIFT_WAITING = true
            } else {
              let submit_errors = false
              let uploaded_already = []
              let uploaded_items = []
              let successes = []
              let errors = []
              if (!data) {
                let res = await  DB.getItems({name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values})
                data = res?res.data:null 
                if (!data) {
                  resolve({
                    error:"no data"
                  })
                  return
                } 
              } 
              for (const item of data) {
                const order = DEFINED_ONLY({...item,uploaded:true})
                const order_key = item.key
                const company_key = item.company_key
                const created_uid = item.created_uid
  
                if (item.uploaded) {
                  uploaded_already.push(item)
                } else {
                  let path = await dispatch("company_link",{
                    link:DATA.PATHS.days_shifts+order_key,
                    company_key:company_key
                  }) 
                  if (order_key && path && company_key) {
                    try {
                      let upRes = await dispatch('fi_update',{
                        action:DATA.ITEMS.DAYS_SHIFTS.values,
                        data:order,
                        path:path,
                        uid:created_uid,
                      })  
    
                      //-------UPDATE LOCALS AFTER UPLOAD 
                      await DB.deleteItems({
                        key:order_key,
                        name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                        update_locals:true
                      }) 
                     
                      successes.push(upRes)
                      uploaded_items.push(item)
                    } catch (error) {
                      errors.push(error)
                      submit_errors = true
                    }
                  }else{
                    submit_errors = true
                    errors.push({
                      item:item,
                      error:"payload required"
                    })
                  }   
                } 
              } 
              state.UPDATE_DAY_SHIFT_LOADING = false
              if (state.UPDATE_DAY_SHIFT_WAITING) {
                state.UPDATE_DAY_SHIFT_WAITING = false
                dispatch('fi_upload_day_shifts')
              } 

              
              if (submit_errors) {
                reject({
                  error:"finish with error",
                  errors:errors,
                  successes:successes,
                  uploaded_items:uploaded_items,
                  uploaded_already:uploaded_already
                })
              } else {
                resolve({
                  success:true,
                  errors:errors,
                  successes:successes,
                  uploaded_items:uploaded_items,
                  uploaded_already:uploaded_already
                })
              }  
              commit('updateLoading', false)  
            }  
          } catch (error) {
            commit('updateLoading', false)
            response = {
              ...response,
              error:error
            }
            commit("ERROR",response)
            reject(response)
          }
        })
      }, 
      fi_upload_cashups({ commit,dispatch,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'fi_upload_cashups',
            page:NAME, 
            payload:payload
          }
          try { 
             
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'uploading/cashups' 
            })  

            //initiate
            let data = payload?payload.data:null    
            if (state.UPDATE_CASHUP_LOADING) {
              state.UPDATE_CASHUP_WAITING = true
            } else {
              let submit_errors = false
              let uploaded_already = []
              let uploaded_items = []
              let successes = []
              let errors = []
              if (!data) {
                let res = await  DB.getItems({name:DATA.ITEMS.LOCAL_CASHUPS.values})
                data = res?res.data:null 
                if (!data) {
                  resolve({
                    error:"no data"
                  })
                  return
                } 
              } 
              for (const cashup of data) {
                const order = DEFINED_ONLY({...cashup,uploaded:true})
                const cashup_key = cashup.key
                const day_shift_key = cashup.day_shift_key
                const company_key = cashup.company_key
                const created_uid = cashup.created_uid
  
                if (cashup.uploaded) {
                  uploaded_already.push(cashup)
                  if (cashup.close_at) {
                    await DB.addItems({
                      name:DATA.ITEMS.LOCAL_CASHUPS.values,
                      data:{...cashup,uploaded:false},
                      update_locals:true
                    }) 

                    console.log(cashup,'cashup re up:************-----------------------------********************');
                  }
                } else {
                  let path = await dispatch("company_link",{
                    link:DATA.PATHS.days_shifts+day_shift_key+"/cashups/"+cashup_key,
                    company_key:company_key
                  }) 
                  if (cashup_key && day_shift_key && path && company_key) {
                    try {
                      let upRes = await dispatch('fi_update',{
                        action:DATA.ITEMS.CASHUPS.values,
                        data:order,
                        path:path,
                        uid:created_uid,
                      })  
    
                      //-------UPDATE LOCALS AFTER UPLOAD  
                      if (cashup.close_at) {
                        console.log(cashup_key,'deleting............');
                        await DB.deleteItems({
                          key:cashup_key,
                          name:DATA.ITEMS.LOCAL_CASHUPS.values,
                        })   
                      }else{
                        console.log(cashup_key,'adding............');
                        await DB.addItems({
                          name:DATA.ITEMS.LOCAL_CASHUPS.values,
                          data:{...cashup,uploaded:true}
                        }) 
                      }
                     
                      successes.push(upRes)
                      uploaded_items.push(cashup)
                    } catch (error) {
                      errors.push(error)
                      submit_errors = true
                    }
                  }else{
                    submit_errors = true
                    errors.push({
                      cashup:cashup,
                      error:"payload required"
                    })
                  }   
                } 
              } 
              state.UPDATE_CASHUP_LOADING = false
              if (state.UPDATE_CASHUP_WAITING) {
                state.UPDATE_CASHUP_WAITING = false
                dispatch('fi_upload_cashups')
              } 

              
              if (submit_errors) {
                reject({
                  error:"finish with error",
                  errors:errors,
                  successes:successes,
                  uploaded_items:uploaded_items,
                  uploaded_already:uploaded_already
                })
              } else {
                resolve({
                  success:true,
                  errors:errors,
                  successes:successes,
                  uploaded_items:uploaded_items,
                  uploaded_already:uploaded_already
                })
              }  
              commit('updateLoading', false)  
            }  
          } catch (error) {
            commit('updateLoading', false)
            response = {
              ...response,
              error:error
            }
            commit("ERROR",response)
            reject(response)
          }
        })
      }, 
      fi_upload_quotations({ commit,dispatch}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'fi_upload_quotations',
            page:NAME, 
            payload:payload
          }
          try { 
             
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'uploading/quotation' 
            })  
            //initiate
            let data = payload?payload.data:null  
            
            let submit_errors = false
            let uploaded_already = []
            let uploaded_items = []
            let successes = []
            let errors = []
            if (!data) {
              let res = await  DB.getItems({name:DATA.ITEMS.LOCAL_QUOTATIONS.values})
              data = res?res.data:null 
              if (!data) {
                resolve({
                  error:"no data"
                })
                return
              } 
            }
            for (const item of data) {
              let key = item.key
              let day_shift_key = item.day_shift_key
              let company_key = item.company_key
              let uid = item.uid

              if (item.uploaded) {
                uploaded_already.push(item)
              } else {
                let path = await dispatch("company_link",{
                  link:DATA.PATHS.issued_quotations+day_shift_key+"/"+key,
                  company_key:company_key
                }) 
                if (key && path && company_key) {
                  try {
                    let upRes = await dispatch('fi_update',{
                      action:DATA.ITEMS.QUOTATIONS.values,
                      data:DEFINED_ONLY({...item,uploaded:true}),
                      uid:uid,
                      path:path,
                    })  
  
                    //-------UPDATE LOCALS AFTER UPLOAD 
                    DB.addItems({
                      name:DATA.ITEMS.LOCAL_QUOTATIONS.values,
                      data:{...item,uploaded:true}
                    }) 
                    successes.push(upRes)
                    uploaded_items.push(item)
                  } catch (error) {
                    errors.push(error)
                    submit_errors = true
                  }
                }else{
                  submit_errors = true
                  errors.push({
                    item:item,
                    error:"payload required"
                  })
                }   
              }

            }  
            // await dispatch("initiate_locals",{
            //   names:[DATA.ITEMS.LOCAL_QUOTATIONS.values], 
            // }) 
 

            if (submit_errors) {
              reject({
                error:"finish with error",
                errors:errors,
                successes:successes,
                uploaded_items:uploaded_items,
                uploaded_already:uploaded_already
              })
            } else {
              resolve({
                success:true,
                errors:errors,
                successes:successes,
                uploaded_items:uploaded_items,
                uploaded_already:uploaded_already
              })
            }  
            commit('updateLoading', false) 
          } catch (error) {
            commit('updateLoading', false)
            response = {
              ...response,
              error:error
            }
            commit("ERROR",response)
            reject(response)
          }
        })
      }, 


      //-----------------------[LOCAL SELECTED SALES ]---------
     


      add_local_item({ commit,state}, payload) {
        try {
          let key = payload.key
          let value_item = payload.value_item
          let data = payload.data 
          let items = []
 
          
          if (!key) {
            commit("ERROR",{
              error:{
                error:'No key',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          }if (!value_item) {
            commit("ERROR",{
              error:{
                error:'no value',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          }if (!data) {
            commit("ERROR",{
              error:{
                error:'no data',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          } 
          value_item = value_item.toUpperCase()

          if (value_item==='PROVINCES'||value_item==='PROVINCE') {
            items = [...state.PROVINCES]
          }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
            items = [...state.DISTRICTS]
          }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
            items = [...state.CONSTITUENCIES]
          }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
            items = [...state.POLLING_STATIONS]
          }else if(value_item==='REPORTS'||value_item==='REPORT') {
            items = [...state.REPORTS]
          }else if(value_item==='SMS'||value_item==='sms') {
            items = [...state.SMS]
          }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
            items = [...state.REPORT_CATEGORIES]
          }else if(value_item==='POLLS'||value_item==='POLL') {
            items = [...state.POLLS]
          }else if(value_item==='NEWS'||value_item==='NEWS') {
            items = [...state.NEWS]
          } 

          let item_size = items?items.length:0
          if (item_size===0) { 
             item = []
          }

          items.push({
            key:key,
            ...data
          })

          if (value_item==='PROVINCES'||value_item==='PROVINCE') {
            state.PROVINCES=items 
          }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
            state.DISTRICTS=items 
          }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
            state.CONSTITUENCIES=items 
          }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
            state.POLLING_STATIONS=items 
          }else if(value_item==='REPORTS'||value_item==='REPORT') {
            state.REPORTS=items 
          }else if(value_item==='SMS'||value_item==='sms') {
            state.SMS=items 
          }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
            state.REPORT_CATEGORIES=items 
          }else if(value_item==='POLLS'||value_item==='POLL') {
            state.POLLS=items 
          }else if(value_item==='NEWS'||value_item==='NEWS') {
            state.NEWS=items 
          }  
            
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'update_local_item',
            page:NAME, 
          })
        }
      },  
    },
}