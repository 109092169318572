<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <h1 class="grey--text text--darken-1">Starting a Day</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                <h4 class="grey--text text--darken-1">Details</h4>
                                By starting a day, you will be allowed to select the business day (Operating Date) for the transactions in the system. This helps you to stay on top of the game by making sure that as an admin you are tracking the float and tills operations at the beginning of a particular day.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.start_day"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    
                    <div >
                        <br><br><br>

                        <hr>
                        <br><br><br>
                        <h4 class="grey--text text--darken-1">Till</h4>
                        <v-row >
                            <v-col cols="12" md="3">
                                <div class="text-justify">
                                    Select tills to be used and allocate the floating cash
                                </div>
                            </v-col>
                            <v-col cols="12" md="9" >
                                <v-card :height="vsh-190" outlined rounded="sm">
                                    <v-layout fill-height column class="ma-0">
                                        <div>
                                            <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                                <v-autocomplete 
                                                    v-model="search_item"
                                                    :disabled="inputLoading"
                                                    :items="MixedDayShiftTills" dense
                                                    append-icon="search"
                                                    outlined :rounded="false"
                                                    label="Select Till"
                                                    color="blue-grey lighten-2" 
                                                    item-text="name"
                                                    item-value="key" 
                                                    :filter="(item, queryText, itemText) => { 
                                                        let index = (a,b)=>{
                                                            if(!a||!b){return false}
                                                            a = ''+a; b = ''+b
                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                        } 
                                                        let first = index(itemText,queryText)
                                                        let second = item?index(item.code,queryText):false
                                                        let third = item?index(item.outlet_name,queryText):false
                                                        if(!item){return first} 
                                                        return first || second || third 
                                                    }"
                                                    >
                                                    <template v-slot:selection="data">
                                                        {{ data.item.name }}
                                                    </template>
                                                    <template v-slot:item="data">
                                                        <template v-if="(typeof data.item !=='object')" >
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                        </template>
                                                        <template v-else>
                                                        <v-list-item-avatar tile color="grey">
                                                            <img :src="data.item.image" v-if="data.item.image">
                                                            <v-icon v-else>mdi-cash-register</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                            <v-list-item-subtitle >Till Code: {{data.item.code}}</v-list-item-subtitle>
                                                            <v-list-item-subtitle :class="data.item.running?'secondary--text':''" >Status: {{ data.item.running?"Running..":"Closed" }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        </template>
                                                    </template>
                                                </v-autocomplete>  
                                            </v-toolbar> 
                                        </div>
                                        <v-list dense class="font-weight-bold not-f3 ">
                                            <v-list-item dense >
                                                <v-list-item-action> <v-card flat width="45">
                                                    
                                                </v-card> </v-list-item-action>
                                                <v-list-item-content>Till Name</v-list-item-content>
                                                 
                                                <v-list-item-action>
                                                    <v-card flat width="150">
                                                        <v-layout class="ma-0 pl-2" justify-center>
                                                        <div class="text-center">Floating Cash (MWK)</div> 
                                                        </v-layout>
                                                    </v-card>
                                                </v-list-item-action>  
                                                 
                                                <v-list-item-action>
                                                    <v-card flat width="25">
                                                        <v-layout class="ma-0" justify-center>
                                                            
                                                        </v-layout>
                                                    </v-card>
                                                </v-list-item-action> 
                                            </v-list-item>
                                        </v-list>
                                        <v-divider></v-divider>
                                        <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                            No Item
                                        </v-layout>
                                        <v-virtual-scroll v-else 
                                            class=""
                                            :bench="2"
                                            :items="selected_items"
                                            :height="vsh-300"
                                            item-height="70"  > 
                                            <template v-slot="{ item,index }">
                                                    <v-list-item  
                                                        :key="index" :to="item.link">
                                                        <v-list-item-action> 
                                                            <v-avatar
                                                                size="45" tile rounded="lg"
                                                                color="grey"  >
                                                                <v-img v-if="item.image"></v-img>
                                                                <v-icon v-else>local_offer</v-icon>
                                                            </v-avatar>
                                                        </v-list-item-action>
                                                        <v-list-item-content>{{item.name}}</v-list-item-content>
                                                         
                                                        <v-list-item-action class="pr-2">
                                                            <v-card flat color="" width="150">
                                                                <v-text-field autofocus
                                                                    :disabled="inputLoading" required
                                                                    v-mask="currencyMask" filled dense  outlined
                                                                    v-model="item.floating_cash" />
                                                            </v-card>
                                                        </v-list-item-action>
                                                         
                                                        <v-list-item-action >
                                                            <v-btn @click="REMOVE_ITEM(item)" :disabled="inputLoading"
                                                        small icon><v-icon>delete</v-icon></v-btn></v-list-item-action> 
                                                    </v-list-item> 
                                                    <v-divider ></v-divider> 
                                            </template>  
                                        </v-virtual-scroll> 
    
                                        <v-spacer></v-spacer>
                                        <v-divider></v-divider>
                                        
                                        <input ref="btn_submit_order" type="submit" v-show="false">
                                        <v-card @click="SUBMIT()"  
                                            :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                            :flat="MBS.actions.SIZE(selected_items)<1"  
                                            class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                            <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                                <div class="font-weight-bold not-f7">Start a Day</div>
                                                <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Tills</div>
                                                <v-spacer></v-spacer>
                                                <div class="font-weight-bold not-f7">TOTAL MWK {{
                                                    MBS.actions.money(CALCULATE_TOTAL_FLOATING_CASH(selected_items))
                                                    }}</div> 
                                            </v-layout> 
                                            <v-layout v-else class="ma-0 pa-2" justify-center>
                                                <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                            </v-layout>
                                        </v-card>
                                    </v-layout> 
                                </v-card>   
                                
                            </v-col> 
                        </v-row> 
                    </div>
                </form>
            </div> 
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = "RECEIVE-STOCK"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                invoice_code:null, 
                show_input:true, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.CHECK_DAY_SHIFT()
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////  
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                CashupOptions: state=> state.app['CASHUP_OPTIONS'], 
            }), 
            

            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            DaysShiftsData(){
                return this.JoinedDaysShifts?this.JoinedDaysShifts:this.DaysShifts
            }, 
            // AllDaysShifts(){
            //     let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
            //     let dayShifts = this.DaysShiftsData
            //     let data = []
            //     if(!dayShifts && !locals){return null}
            //     if (locals) {
            //         data = [...locals]
            //     }if (dayShifts) {
            //         data = [...data,...dayShifts]
            //     }  
            //     return data
            // }, 
            AllDaysShifts(){
                let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
                let dayShifts = this.DaysShiftsData

                let data = []
                if(!dayShifts && !locals){return null}
                
                if (dayShifts) {
                    data = [...dayShifts] 
                }if (locals) {
                    locals.forEach(local => {
                        let index = data.findIndex(item=>item.key == local.key)
                        if (index ==-1 ) {
                            data.push(local)
                        } else {
                            data[index] = {...data[index],...local}
                        }
                        
                    }); 
                 }
                return data
            }, 
            OutletTills(){
                let input = this.input
                let outlet_code = input?input.outlet_code:null 
                let tills = this.TillsData  
                if(!tills||!outlet_code){return null}
                let filtered  = tills.filter(till=>till.outlet_code == outlet_code) 
                return filtered
            }, 
            MixedDayShiftTills(){
                let all_tills = this.OutletTills
                let this_tills = this.ThisTills
                if(!all_tills){return null}
                if(!this_tills){return all_tills}
                let new_tills = []
                all_tills.forEach(till => {
                    let this_till = this_tills.find(t=>t.key == till.key)
                    if (this_till) {
                        new_tills.push({
                            ...till,
                            ...this_till
                        })
                    }else{
                        new_tills.push(till)
                    } 
                }); 
                return new_tills
            }, 
            RunningDayShifts(){
                let days = this.AllDaysShifts
                if(!days){return null}
                return days.filter(day=>!day.closed)
            },
            ThisRunningDayShifts(){
                let days = this.RunningDayShifts
                let input = this.input
                let outlet_code = input?input.outlet_code:null  
                if(!days||!outlet_code){return null} 
                let filtered = days.filter(day=>day.outlet_code == outlet_code)
                return this.MBS.actions.SIZE(filtered)?filtered:null
            }, 
            ThisTills(){
                let days = this.ThisRunningDayShifts 
                if(!days){return null}
                let tills = []
                days.forEach(day => {
                    let d_tills = day.joined_tills?day.joined_tills:day.tills
                    if (d_tills) {
                        tills.push(...d_tills)
                    }
                }); 
                return this.MBS.actions.SIZE(tills)?tills:null
            }, 
            SelectedOutlet(){
                let input = this.input
                let outlet_code = input?input.outlet_code:null 
                let Outlets = this.Outlets
                if(!Outlets||!outlet_code){return null} 
                return Outlets.find(outlet=>outlet.code==outlet_code)
            }, 
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },   
             
            CALCULATE_TOTAL_FLOATING_CASH(){
                return items=>{
                    let total_floating_cash = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let floating_cash = this.toNumber(item.floating_cash) 
                            total_floating_cash = total_floating_cash + floating_cash
                        });  
                        return total_floating_cash
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_FLOATING_CASH',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){ 
                let Outlets = this.Outlets
                let Tills = this.TillsData 
                let CashupOptions = this.CashupOptions 
                return { 
                    Outlets:Outlets,
                    Tills:Tills, 
                    CashupOptions:CashupOptions, 
                }
            },
            DisabledFields(){
                let order_code = this.invoice_code
                if (order_code) {
                    return ['supplier_code','payment_method']
                }
                return null
            } 
        },
        methods: { 
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.MixedDayShiftTills
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 

                        if (item_selected.running) {
                            let ThisRunningDayShifts = this.ThisRunningDayShifts
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"TILL IS RUNNING",
                                text:"The selected till is still running. (Teller: "+item_selected.teller_name+"). You can click Manage Day Shift Button or Cancel and select another till which is free.",
                                btnYes:"MANAGE DAY SHIFTS",  
                                btnNo:"CANCEL",  
                                action:{
                                    code:PAGE_NAME+"=RUNNING-DAY-SHIFT",
                                    data:ThisRunningDayShifts?ThisRunningDayShifts[0]:null
                                }
                            })  
                        } else {
                            if (item) {
                                // item.quantity = (item.quantity*1)+1
                            } else {
                                this.selected_items.push({
                                    ...item_selected, 
                                    floating_cash:''
                                    // quantity:1,
                                })  
                            } 
                        } 
                    } 
                    this.REST_SEARCHING()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    this.REST_SEARCHING()
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            REST_SEARCHING(){
                try {
                    setTimeout(() => {
                        this.search_item = null
                    }, 500);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADREST_SEARCHING_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CREATE_ORDER(){
                try {
                    let us = this.us
                    let uid = us?us.uid:null
                    let input = this.input 
                    let selectedCompany = this.selectedCompany 
                    // let code = input?input.code:null  
                    let company_key = selectedCompany?selectedCompany.key:null  
                    let outlet_code = input?input.outlet_code:null
                    let operating_date = input?input.operating_date:null
                    let cashup_option = input?input.cashup_option:null
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)
                    let moment = this.MBS.date.moment
                    let key = moment?moment().valueOf():null

                    if (items_size<1 || !company_key|| !key) {
                        console.log("error..");
                    }
                    
                    let tills_items = []
                    let total_floating_cash = 0
                    selected_items.forEach(element => {
                        let float = this.toNumber(element.floating_cash )
                        total_floating_cash = total_floating_cash+float
                        tills_items.push({
                            till_key:element.key, 
                            floating_cash:float
                        })
                    });
                    let order_details = {
                        key:key,    
                        local_created_at:key,    
                        company_key:company_key,    
                        outlet_code:outlet_code,  
                        total_floating_cash:total_floating_cash,  
                        order_type:DATA.ITEMS.DAYS_SHIFTS.value,
                        tills:tills_items,
                        created_uid:uid,
                    } 
                    if (operating_date) {
                        order_details.operating_date = operating_date
                    }  
                    if (cashup_option) {
                        order_details.cashup_option = cashup_option
                    }  
                    if (description) {
                        order_details.description = description
                    }  
  
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.DAYS_SHIFTS.name,"Start")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SAVE_DAY_SHIFT(order){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts,true) 
                    if (!order || !path) {
                        console.log(order,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                        data:order,
                        update_locals:true,   
                    }).then(res=>{  
                        this.inputLoading = false
                        this.MBS.actions.go(-1) 
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.DAYS_SHIFTS.value,"Starting")
                        setTimeout(() => {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                            })   
                        }, 500);
                    }).catch(error=>{  
                        this.MBS.actions.error({
                            error:error,
                            from:'SAVE_DAY_SHIFT',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.DAYS_SHIFTS.value,"Starting")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SAVE_DAY_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CHECK_DAY_SHIFT(){
                try {  
                    let ThisRunningDayShifts = this.ThisRunningDayShifts
                    let SelectedOutlet = this.SelectedOutlet 
                    if (ThisRunningDayShifts) {
                        let outlet_name = SelectedOutlet?SelectedOutlet.name:'...'
                        let mbs_text = this.MBS.text.item_action_success("action","Sending")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:"HAVE RUNNING DAY SHIFT",
                            text:"You are already having a running day shift in the selected outlet ("+outlet_name+").",
                            btnYes:"MANAGE DAY SHIFTS",  
                            btnNo:"CONTINUE",  
                            action:{
                                code:PAGE_NAME+"=RUNNING-DAY-SHIFT",
                                data:ThisRunningDayShifts[0]
                            }
                        })  
                    }else{
                        this.MBS.actions.dialog()
                        this.selected_items=[]
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_DAY_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },

             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SAVE_DAY_SHIFT(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=RUNNING-DAY-SHIFT') {
                            if (action.YES) {
                                setTimeout(() => {
                                    let day_shift = action.data
                                    let day_shift_key = day_shift?day_shift.key:null
                                    let link = "/manage-shift/"+day_shift_key
                                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                                    this.MBS.actions.go(path)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            ThisRunningDayShifts(value){   
                this.CHECK_DAY_SHIFT()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
