
import Vue from 'vue'
import Vuex from 'vuex'
import browser from 'browser-detect' 
import users from './../users'
import { mdiPrinterPosCog } from '@mdi/js';
import { mdiCartPercent } from '@mdi/js';

const countries = require('node-countries') 

Vue.use(Vuex)

export default {
  state: {
    app: {
      name:'Future Tours'
    },
    countries:countries, 
    WEB_LINKS:[
      {link:'/',icon:'home',name:'Home',important:1},
      
      //Sales
      {
        company_link:true,
        link:'/sale',
        // icon:'mdi-point-of-sale',
        icon:'mdi-cash-register',
        image:'/future_tours/svg/SVG/message.svg',
        image_background_color:'primary lighten-2',
        name:'Sale',
        subheader:'Point of Sale',
        important:1
      },{
        company_link:true,
        link:'/sale/',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Sale', 
        important:2
      },{
        company_link:true,
        link:'/sale/quotations',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Issued Quotations',
        shortname:'Quotations',
        important:2
      },{
        company_link:true,
        link:'/sale/history',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Sales History',
        shortname:'History',
        important:2
      },{
        company_link:true,
        link:'/sale/cashup',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Cashup',
        shortname:'Cashup',
        important:2
      },{
        company_link:true,
        link:'/sale/days-shifts',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Days Shifts',
        shortname:'Shifts',
        important:2
      },{
        company_link:true,
        link:'/sale/settings',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Settings',
        shortname:'Settings',
        important:2
      },  

      //Orders
      {
        company_link:true,
        link:'/orders',
        icon:'mdi-cart',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Orders',
        subheader:'Orders',
        important:1
      },
      // {
      //   company_link:true,
      //   link:'/orders/processing-display',
      //   icon:'mdi-coffee-maker',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Processing Order(s)',
      //   important:2
      // },
      {
        company_link:true,
        link:'/orders/customer-display',
        icon:'mdi-face-agent',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Customer Order(s)',
        important:2
      },{
        company_link:true,
        link:'/orders/customer-display',
        icon:'mdi-monitor-eye',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Display Order(s)',
        important:2
      },

      //Catalog
      {
        company_link:true,
        link:'/catalog',
        icon:'local_offer',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Catalog',
        subheader:'Product & Services',
        important:1
      },{
        company_link:true,
        link:'/catalog/price-book',
        icon:'mdi-clipboard-list',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Price Book',
        important:2
      },{
        company_link:true,
        link:'/catalog/item-categories',
        icon:'mdi-shape-plus',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Item Categories',
        important:2
      },{
        company_link:true,
        link:'/catalog/item-departments',
        icon:'mdi-format-list-group',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Item Department',
        important:2
      },{
        company_link:true,
        link:'/catalog/brands',
        icon:'mdi-package-check',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Brands',
        important:2
      },{
        company_link:true,
        link:'/catalog/manufacturers',
        icon:'mdi-factory',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manufacturers',
        important:2
      },
      // {
      //   company_link:true,
      //   link:'/catalog/gift-cards',
      //   icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Gift Cards',
      //   important:2
      // },

      

      //stock
      {
        company_link:true,
        link:'/stock',
        icon:'widgets',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stock',
        subheader:'Inventory',
        important:1
      },
      {
        company_link:true,
        full:true,
        link:'/stock/outlets',
        // icon:'mdi-source-branch',
        icon:'mdi-sitemap',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'secondary',
        image_size:'80',
        name:'Outlets Settings',
        important:2
      },
      {
        company_link:true,
        full:true, 
        link:'/stock/settings',
        icon:'mdi-store-cog',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary',
        image_size:'80',
        name:'Stock Settings',
        important:2
      },
      {
        company_link:true,
        link:'/stock/manage',
        icon:'mdi-tag-multiple',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stock & Items',
        important:2
      },
      {
        company_link:true,
        full:true,
        link:'/stock/manage/items',
        link2:'/stock/items',
        icon:'mdi-tag-text',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'secondary ',
        image_size:'80',
        name:'Manage Items',
        important:3
      },
      
      {
        company_link:true,
        full:true,
        link:'/stock/manage/item-kit',
        link2:'/stock/item-kit',
        icon:'mdi-group',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary',
        image_size:'80',
        name:'Item Kits / Services',
        important:3
      },
      {
        company_link:true,
        full:true,
        link:'/stock/manage/departments',
        link2:'/stock/departments',
        icon:'mdi-format-list-group',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'secondary darken-2',
        image_size:'80',
        name:'Item Departments',
        important:3
      },
      
      {
        company_link:true,
        link:'/stock/stock-control',
        icon:'mdi-contrast-box',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stock Control',
        important:2
      },
      {
        company_link:true,
        link:'/stock/stock-control/order-stock',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Order Stock',
        important:3
      },{
        company_link:true,
        link:'/stock/stock-control/receive-stock',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Receive Stock',
        important:3
      },{
        company_link:true,
        link:'/stock/stock-control/return-stock',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Return Stock',
        important:3
      },{
        company_link:true,
        link:'/stock/stock-control/transfer-stock',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Transfer Stock',
        important:3
      },{
        company_link:true,
        link:'/stock/stock-control/adjust-stock',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Adjust Stock',
        important:3
      },{
        company_link:true,
        link:'/stock/stock-control/sales-return',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Sales Return',
        important:3
      },
      
      // {
      //   company_link:true,
      //   link:'/stock/quotations&invoices',
      //   icon:'mdi-file-document',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Quotations & Invoices',
      //   important:2
      // },
      {
        company_link:true,
        link:'/stock/quotations&invoices/request-quotation',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Request Quotation',
        important:3
      },{
        company_link:true,
        link:'/stock/quotations&invoices/receive-quotation',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Received Quotation',
        important:3
      },{
        company_link:true,
        link:'/stock/quotations&invoices/receive-invoice',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Received Invoice',
        important:3
      },
      {
        company_link:true,
        link:'/stock/stock-taking',
        icon:'mdi-tag-search',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stocking Taking',
        important:2
      },
      {
        company_link:true,
        link:'/stock/expiries',
        icon:'mdi-tag-heart',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Expiries',
        important:2
      },{
        company_link:true,
        link:'/stock/expiries/add',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Add Expiries',
        important:3
      },
      
      
      
      //Warehouse
      {
        company_link:true,
        link:'/warehouse',
        // icon:'mdi-point-of-sale',
        // icon:'mdi-dolly',
        icon:'mdi-warehouse',
        image:'/future_tours/svg/SVG/message.svg',
        image_background_color:'primary lighten-2',
        name:'Warehouse',
        subheader:'Manage Warehouse',
        important:1
      },{
        company_link:true,
        link:'/warehouse/',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Warehouse', 
        important:2
      }, {
        company_link:true,
        link:'/warehouse/history',icon:'link',
        image:'/future_tours/svg/SVG/my_bus_tickets.svg',
        image_background_color:'light-blue',
        image_size:'',
        name:'Sales History',
        shortname:'History',
        important:2
      }, 
      

      //hospital
      {
        company_link:true,
        link:'/hospital',
        icon:'mdi-hospital-building',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Hospital',
        subheader:'Hospital Operations',
        important:1
      }, 
      {
        company_link:true,
        link:'/hospital/reception',
        icon:'mdi-card-account-details-star',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Reception',
        important:2
      },{
        company_link:true,
        link:'/hospital/triage',
        icon:'mdi-medical-cotton-swab',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Triage / Assessments',
        important:2
      },{
        company_link:true,
        link:'/hospital/appointments',
        icon:'mdi-book-clock',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Appointments',
        important:2
      },{
        company_link:true,
        link:'/hospital/consultations',
        icon:'mdi-stethoscope',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Consultations',
        important:2
      },{
        company_link:true,
        link:'/hospital/laboratory',
        icon:'mdi-test-tube',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Laboratory',
        important:2
      },{
        company_link:true,
        link:'/hospital/scanning',
        icon:'mdi-heart-pulse',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Scanning',
        important:2
      },{
        company_link:true,
        link:'/hospital/dental',
        icon:'mdi-tooth',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Dental Department',
        important:2
      },{
        company_link:true,
        link:'/hospital/maternity',
        icon:'mdi-human-pregnant',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Maternity Ward',
        important:2
      },{
        company_link:true,
        link:'/hospital/admission',
        icon:'mdi-bed',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Admission',
        important:2
      },{
        company_link:true,
        link:'/hospital/pharmacy',
        icon:'mdi-pill-multiple',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Pharmacy',
        important:2
      },{
        company_link:true,
        link:'/hospital/doctors',
        icon:'mdi-doctor',image:'/future_tours/svg/SVG/my_parcel.svg',
        
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Doctors',
        important:2
      },{
        company_link:true,
        link:'/hospital/patients',
        icon:'mdi-human-wheelchair',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Patients ',
        important:2
      },
      
      //office
      {
        company_link:true,
        link:'/office',
        icon:'mdi-chair-rolling ',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Office',
        subheader:'Office Operations',
        important:1
      }, {
        company_link:true,
        link:'/office/reports',
        icon:'mdi-finance',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Reports',
        important:2
      },
      
      {
        company_link:true,
        link:'/office/reports/profit&loss',
        icon:'mdi-point-of-sale',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Profit & Loss',
        important:3
      }, // sales
      {
        company_link:true,
        link:'/office/reports/sales',
        icon:mdiCartPercent,image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Sales Reports',
        important:3
      },{
        company_link:true,
        link:'/office/reports/sales/items',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Sales Items',
        important:4
      },{
        company_link:true,
        link:'/office/reports/sales/transactions',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Transactions',
        important:4
      },{
        company_link:true,
        link:'/office/reports/sales/day-shifts',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Day Shifts',
        important:4
      },{
        company_link:true,
        link:'/office/reports/sales/cashups',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Cashups',
        important:4
      },
      // {
      //   company_link:true,
      //   link:'/office/reports/income',
      //   icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Other Income',
      //   important:3
      // }, 
      {
        company_link:true,
        link:'/office/reports/purchases',
        icon:'mdi-human-dolly',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Purchases Report',
        important:3
      },{
        company_link:true,
        link:'/office/reports/expenses',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Expenses Report',
        important:3
      },{
        company_link:true,
        link:'/office/reports/debtors',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Debtors Report',
        important:3
      },{
        company_link:true,
        link:'/office/reports/creditors',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Creditors Report',
        important:3
      },{
        company_link:true,
        link:'/office/reports/vat',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'VAT Report',
        important:3
      },
      //account
      {
        company_link:true,
        link:'/office/accounts',
        icon:'mdi-bank',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Accounts',
        important:2
      }, 
      // {
      //   company_link:true,
      //   link:'/office/accounts/payable',
      //   icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Payable Accounts',
      //   important:3
      // },
      {
        company_link:true,
        link:'/office/accounts/payment-methods',
        icon:'mdi-account-cash',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Payment Methods',
        important:3
      },
      {
        company_link:true,
        link:'/office/accounts/money-accounts',
        icon:'mdi-cash-sync',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Money Accounts',
        important:3
      }, 
      {
        company_link:true,
        link:'/office/accounts/receivable',
        icon:'mdi-cash-clock',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Receivable Accounts',
        important:3
      },{
        company_link:true,
        link:'/office/accounts/expenses',
        icon:'mdi-cash-marker',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Expenses Accounts',
        important:3
      },
      // {
      //   company_link:true,
      //   link:'/office/accounts/income',
      //   icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
      //   image_background_color:'primary lighten-2',
      //   image_size:'80',
      //   name:'Income',
      //   important:3
      // },
      {
        company_link:true,
        link:'/office/accounts/money-accounts/0',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Cash Account',
        important:4
      },
      
      //SETUP ORDER OPTIONS
      {
        company_link:true,
        link:'/office/order-options',
        icon:'mdi-cart-heart',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Order Options',
        important:2
      },
      //Bill
      {
        company_link:true,
        link:'/office/bills',
        icon:'mdi-cash-clock',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Bills',
        important:2
      },
      //Bill
      {
        company_link:true,
        link:'/office/invoices',
        icon:'mdi-clipboard-text-clock',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Invoices',
        important:2
      },
      //Expense
      {
        company_link:true,
        link:'/office/expenses',
        icon:'mdi-cash-refund',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Expenses',
        important:2
      },
      {
        company_link:true,
        link:'/office/assets',
        icon:'mdi-sofa-single',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Assets',
        important:2
      },
      { 
        company_link:true,
        link:'/office/assets//types',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Asset Types',
        important:3
      },
      //people
      {
        company_link:true,
        link:'/office/people',
        icon:'mdi-clipboard-account',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'People',
        important:2
      },{
        company_link:true,
        link:'/office/people/employs',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Employs',
        important:3
      },{
        company_link:true,
        link:'/office/people/customers',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Customers',
        important:3
      },{
        company_link:true,
        link:'/office/people/customers/groups',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Customer Groups',
        important:4
      },{
        company_link:true,
        link:'/office/people/suppliers',
        icon:'link',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Suppliers',
        important:3
      },{
        company_link:true,
        link:'/office/people/suppliers/categories',
        icon:'link',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Supplier Categories',
        important:4
      }, 
      //Manage Cards 
      {
        company_link:true,
        link:'/office/cards',
        icon:'mdi-credit-card-multiple',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Manage Cards',
        important:2
      },
      
      //archived
      {
        company_link:true,
        link:'/office/archive',
        icon:'mdi-archive-cancel',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Archives',
        important:2
      },
      
      {
        company_link:true,
        link:'/office/archive/expenses',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Expenses',
        important:3
      },{
        company_link:true,
        link:'/office/archive/expenses-accounts',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Expenses Accounts',
        important:3
      },{
        company_link:true,
        link:'/office/archive/receivables',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Receivable Accounts',
        important:3
      },{
        company_link:true,
        link:'/office/archive/stock-orders',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stock Orders',
        important:3
      },{
        company_link:true,
        link:'/office/archive/quotations&invoices',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Quotations & Invoices',
        important:3
      },{
        company_link:true,
        link:'/office/archive/outlets',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Outlets',
        important:3
      },{
        company_link:true,
        link:'/office/archive/items',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Items',
        important:3
      },{
        company_link:true,
        link:'/office/archive/item-kits',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Item Kits',
        important:3
      },{
        company_link:true,
        link:'/office/archive/item-categories',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Item Categories',
        important:3
      },{
        company_link:true,
        link:'/office/archive/brands',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Brands',
        important:3
      },{
        company_link:true,
        link:'/office/archive/suppliers',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Suppliers',
        important:3
      },{
        company_link:true,
        link:'/office/archive/employees',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Employees',
        important:3
      },{
        company_link:true,
        link:'/office/archive/customers',
        icon:'link',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Customers',
        important:3
      },
      
      //Setup
      {
        company_link:true,
        link:'/settings',
        icon:'settings',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Settings',
        subheader:'System Setup',
        important:1
      },  

      //Company Settings
      {
        company_link:true,
        link:'/settings/company',icon:'mdi-office-building-cog',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Company Settings',
        important:2
      },{
        company_link:true,
        link:'/settings/company/profile',icon:'mdi-table-cog',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Company Profile',
        important:3
      },{
        company_link:true,
        link:'/settings/company/general',icon:'mdi-tune',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'General',
        important:3
      },
      {
        company_link:true,
        full:true,
        link:'/settings/company/outlets',
        link2:'/stock/outlets',
        // icon:'mdi-source-branch',
        icon:'mdi-sitemap',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Outlets Settings',
        important:3
      },
      {
        company_link:true,
        link:'/settings/company/stock',
        link2:'/stock/settings',
        icon:'mdi-store-cog',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Stock Settings',
        important:3
      },
      {
        company_link:true,
        full:true,
        link:'/settings/company/template',
        // link2:'/stock/outlets', 
        icon:'mdi-file-cog',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Template Settings',
        important:3
      },

      //Security
      {
        company_link:true,
        link:'/settings/security',icon:'mdi-security',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Security',
        important:2
      }, {
        company_link:true,
        link:'/settings/security/manage-users',icon:'mdi-account-cog',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Company Users',
        important:3
      },{
        company_link:true,
        link:'/settings/security/user-roles',icon:'mdi-lock-check',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Roles & Permissions',
        important:3
      }, 
      {
        company_link:true,
        link:'/settings/security/register-user',icon:'mdi-account-multiple-plus',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Register User',
        important:3
      }, 

      //Manage TILLS 
      {
        company_link:true,
        link:'/settings/tills',
        icon:'mdi-printer-pos',image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'Config Tills',
        important:2
      },
      
      //Devices 
      {
        company_link:true,
        link:'/settings/devices',icon:'mdi-video-input-component',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Devices',
        important:2
      },{
        company_link:true,
        link:'/settings/devices/printers',icon:mdiPrinterPosCog ,
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Printers',
        important:3
      },{
        company_link:true,
        link:'/settings/devices/computers',icon:'mdi-desktop-classic',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Computers',
        important:3
      },{
        company_link:true,
        link:'/settings/devices/scales',icon:'mdi-scale',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Scales',
        important:3
      },{
        company_link:true,
        link:'/settings/devices/vfd-displays',icon:'mdi-clock-digital',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'VFD Display',
        important:3
      },

      //Cards 
      {
        company_link:true,
        link:'/settings/cards',icon:'mdi-credit-card-settings',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Cards',
        important:2
      },{
        company_link:true,
        link:'/settings/cards/swiping-cards',icon:'mdi-credit-card',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Swiping Cards',
        important:3
      },{
        company_link:true,
        link:'/settings/cards/magnetic-cards',icon:'mdi-credit-card-wireless',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Magnetic Cards',
        important:3
      },

      //Imports
      {
        company_link:true,
        link:'/settings/import-export',icon:'mdi-cog-transfer-outline',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Import And Export',
        important:2
      },
      //Old Data
      {
        company_link:true,
        link:'/settings/old-data',icon:'mdi-filter-cog',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Convert Old Data',
        important:2
      },
      
      

      //accounts
      {
        link:'/account',
        icon:'account_circle',
        image:'/future_tours/svg/SVG/my_parcel.svg',
        image_background_color:'primary lighten-2',
        image_size:'80',
        name:'My Account',
        shortname:'Account',
        subheader:'Manage Account',
        important:1
      },{
        link:'/account/profile',
        icon:'mdi-account',
        name:'My Profile',
        important:2
      }, 
      {
        link:'/account/companies',
        icon:'mdi-domain',
        image:'/future_tours/svg/SVG/message.svg',
        image_background_color:'primary lighten-2',
        name:'Companies',
        important:2
      },{
        link:'/account/messages',
        icon:'mdi-message-bulleted',
        image:'/future_tours/svg/SVG/message.svg',
        image_background_color:'primary lighten-2',
        name:'Messages',
        important:2
      },

       

      //Cpanel
      {
        link:'/cpanel',icon:'tune',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'cPanel',
        subheader:'Admin Option',
        important:1
      }, //permission
      {
        link:'/cpanel/users&permissions',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Users & Permissions',
        important:2
      }, {
        link:'/cpanel/users&permissions/users',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Users',
        important:3
      },{
        link:'/cpanel/users&permissions/user-groups',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'User Groups',
        important:3
      }, {
        link:'/cpanel/users&permissions/permissions',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Permissions',
        important:3
      }, 
      
      {
        link:'/cpanel/companies',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Companies',
        important:2
      },  //setup
      {
        link:'/cpanel/system-setup',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Setup',
        important:2
      },
      {
        link:'/cpanel/system-setup/company-types',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Company Types',
        important:3
      },
      {
        link:'/cpanel/system-setup/company-sizes',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Company Sizes',
        important:3
      }, {
        link:'/cpanel/system-setup/measurement-units',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Measurement Units',
        important:3
      },
      //location
      {
        link:'/cpanel/locations',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Locations',
        important:2
      },
      {
        link:'/cpanel/locations/countries',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Countries',
        important:3
      },
      {
        link:'/cpanel/locations/regions',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Regions',
        important:3
      },
      {
        link:'/cpanel/locations/districts',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Districts',
        important:3
      },
      {
        link:'/cpanel/errors',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Errors',
        important:2
      },{
        link:'/cpanel/visiters',icon:'mdi-account',
        image:'/future_tours/svg/SVG/cpanel.svg',
        image_background_color:'primary lighten-2',
        image_size:'70',
        name:'Visiters',
        important:2
      }, 
    ], 
    SOCIAL_MEDIA_LINKS: [
      {name:'Facebook',icon:'fa-facebook',link:''},
      {name:'Twitter',icon:'fa-twitter',link:''},
      {name:'Google plus',icon:'fa-google-plus',link:''},
      {name:'Linkedin',icon:'fa-linkedin',link:''},
      {name:'Instagram',icon:'fa-instagram',link:''}, 
    ],
    OUTLET_TYPES: [
      {code:"shop",name:'Shop',icon:'mdi-store',link:''}, 
      {code:"warehouse",name:'Warehouse',icon:'mdi-warehouse',link:''},  
    ],
    DEVICE_TYPES: [
      {code:"1",name:'Computers',icon:'fa-facebook',link:''}, 
      {code:"2",name:'Printers',icon:'fa-facebook',link:''},  
    ],
    COMPUTER_TYPES: [
      {code:"1",name:'Desktops',icon:'fa-facebook',link:''}, 
      {code:"2",name:'Laptops',icon:'fa-facebook',link:''}, 
      {code:"3",name:'Phone',icon:'fa-facebook',link:''}, 
    ],
    PRINTER_TYPES: [
      {code:"11",name:'PDF',description:'PDF Printers',icon:'mdi-printer',link:''}, 
      {code:"1",name:'Fiscal (FP700)',description:'MRA Thermal Receipt Printer',icon:'mdi-printer-pos-sync',link:''}, 
      {code:"2",name:'Fiscal (FP700X)',description:'MRA Thermal Receipt Printer',icon:'mdi-printer-pos-sync-outline',link:''}, 
      {code:"3",name:'Epson TM series',description:'Thermal Receipt Printer',icon:'mdi-printer-pos',link:''}, 
      // {code:"4",name:'Seiko Instruments RP series',icon:'fa-facebook',link:''}, 
      // {code:"5",name:'Citizen CT series',icon:'fa-facebook',link:''}, 
      // {code:"6",name:'Fujitsu FP series',icon:'fa-facebook',link:''}, 
    ],
    DOCUMENT_DISPLAY_OPTIONS: [
      {code:"1",key:"pdf_view",name:'PDF View',description:'PDF A4 Paper',icon:'mdi-file-pdf-box',link:''},   
      {code:"2",key:"thermal_view",name:'Thermal View',description:'Thermal Paper View (80mm)',icon:'mdi-receipt',link:''},   
    ],
    TAX_GROUPS: [
      {code:"A",name:'Taxable (16.5%)',icon:'fa-facebook',link:''}, 
      {code:"B",name:'Nontaxable (0%)',icon:'fa-facebook',link:''}, 
      {code:"E",name:'Exempted (0%)',icon:'fa-facebook',link:''}, 
    ],
    COMPANY_SELLING_SETTINGS: [
      {key:"activate_stock_level",name:'Activate Stock Level',description:"This option might slow the system.",icon:'fa-wrench',link:''},   
      {key:"require_void_to_remove_item",name:'Require Void to Remove Item',description:"Cashier Request void when removing selected item",icon:'fa-wrench',link:''},   
      {key:"only_click_mode",name:'Click Model',description:"Activate Click Model",icon:'fa-wrench',link:''},   
      {key:"Sell_without_printer",name:'Sell Without Printer',description:"Allow selling without print",icon:'fa-wrench',link:''},   
      {key:"selling_list_descend",name:'Selling Item List Descend',description:"List the recent selected item on the top",icon:'fa-wrench',link:''},   
      {key:"duplicate_selling_item",name:'Allow Duplicate Item',description:"Item can be selected and listed more than 1",icon:'fa-wrench',link:''},   
      {key:"allow_selling_discount",name:'Allow Item Discount',description:"Cashier can give Item discount",icon:'fa-wrench',link:''},   
      {key:"allow_total_discount",name:'Allow Total Discount',description:"Cashier can give Total discount",icon:'fa-wrench',link:''},   
      {key:"edit_selling_price",name:'Edit Selling Price',description:"Cashier can change selling price",icon:'fa-wrench',link:''},   
      {key:"both_selling_mode",name:'Both Selling Modes',description:"Cashier can change Selling Mode",icon:'fa-wrench',link:''},   
      {key:"only_retail_model",name:'Only Retail',description:"Cashier can only sell in Retail Mode",icon:'fa-wrench',link:''},   
      {key:"only_wholesale_model",name:'Only Wholesale',description:"Cashier can only sell in Wholesale Mode",icon:'fa-wrench',link:''},   
    ],
    CASHUP_SETTINGS: [  
      {key:"cashup_before_selling",name:'Cashup Before Start Selling',description:"Allow Cashier to cashup & submit Opening Cash",icon:'fa-wrench',link:''},   
      {key:"print_opening_cashup",name:'Print Opening Cashup',description:"Allow Cashier to print opening cashup",icon:'fa-wrench',link:''},   
      {key:"cashup_before_closing_till",name:'Cashup before closing Till (Register)',description:"Allow Cashier to submit Closing Cash",icon:'fa-wrench',link:''},   
      {key:"print_closing_cashup",name:'Print Closing Cashup',description:"Allow Cashier to print closing cashup",icon:'fa-wrench',link:''},   
      {key:"show_shortages",name:'Show Shortages & Overages ',description:"Auto Calculate Shortages",icon:'fa-wrench',link:''},     
      {key:"fixed_floating_cash",name:'Fixed Floating Cash ',description:"Floating Cash Never Change",icon:'fa-wrench',link:''},     
      {key:"till_require_no_password",name:'No Required Password to Open or Close Till',description:"Cashiers will open or close till with required to enter password",icon:'fa-wrench',link:''},     
      {key:"open_drawer_without_password",name:'Open Drawer Without Password',description:"Allow opening cash drawer without password",icon:'fa-wrench',link:''},     
    ],
    RECEIPT_SETTINGS: [  
      {key:"receipt_show_company_info",name:'Company Info',description:"Show Company name , contacts and address information.",icon:'fa-wrench',link:''},     
      {key:"receipt_show_company_tpin",name:'Company TPIN',description:"Show Company TPIN",icon:'fa-wrench',link:''},     
      {key:"receipt_show_receipt_title",name:'Receipt Title',description:"Show Receipt type",icon:'fa-wrench',link:''},     
      {key:"receipt_show_item_numbering",name:'Item Numbering',description:"Show item position number.",icon:'fa-wrench',link:''},   
      {key:"receipt_show_till",name:'Till Information',description:"Show Outlet, Cashier & Till information.",icon:'fa-wrench',link:''},     
      {key:"receipt_show_buyer_info",name:'Buyers Information',description:"Show Customer / Buyer's info.",icon:'fa-wrench',link:''},     
      {key:"receipt_show_barcode",name:'Barcode',description:"Show receipt number barcode.",icon:'fa-wrench',link:''},   
      {key:"receipt_show_change_amount",name:'Change & Given Amount',description:"Show given money from customer and change amount.",icon:'fa-wrench',link:''},     
    ],
    TAX_SETTINGS: [      
      {key:"items_are_vat_exclusive",name:'Item are VAT Exclusive',description:"All Selling Item Prices Have no VAT ",icon:'fa-wrench',link:''},     
      {key:"calculate_vat",name:'Calculate VAT',description:"Calculate and show VAT",icon:'fa-wrench',link:''},     
    ],
    STOCK_TRANSFER_OPTIONS: [  
      {code:"to_outlet",key:"to_outlet",name:'Transfer To Outlet',description:"Default Account",icon:'',link:''},       
      {code:"to_supplier",key:"to_supplier",name:'Transfer To Supplier',description:"Default Account",icon:'',link:''},       
    ],
    DEFAULT_MONEY_ACCOUNTS: [  
      {code:"cash",key:"cash",name:'Cash Account',description:"Default Account",icon:'',link:''},       
      {code:"petty_cash",key:"petty_cash",name:'Petty Cash Account',description:"Default Account",icon:'',link:''},       
    ],
    DEFAULT_PAYMENT_METHODS: [  
      {code:"cash",key:"cash",name:'Cash',description:"Default Payment Method",money_account_code:"cash",money_account_name:"Cash Account",icon:'',link:''},       
      {code:"petty_cash",key:"petty_cash",name:'Petty Cash',description:"Default Payment Method",money_account_code:"petty_cash",money_account_name:"Petty Account",icon:'',link:''},        
    ],
    DEFAULT_BILL_METHODS: [         
      {code:"bill",key:"bill",name:'Bill',description:"Default Payment Method",money_account_code:"bill",money_account_name:"Bill Account",icon:'',link:''},        
    ],
    CASHUP_OPTIONS: [         
      {code:"submit_cash_at_end_of_shift",key:"submit_cash_at_end_of_shift",name:'Submit Cash At The End of Day Shift.',description:'Cash is transferred to next cashier until the end of day shift.'},        
      {code:"submit_cash_after_every_cashup",key:"submit_cash_after_every_cashup",name:'Submit Cash After Every Cashup.',description:'Cashier submit cash after every cashup.'},        
    ],
    BARCODE_ROLES: [         
      {code:"prefix_code_quantity",key:"prefix_code_quantity",name:'Prefix, Item Code & Quantity .',description:'.'},        
      {code:"prefix_code_price",key:"prefix_code_price",name:'Prefix, Item Code & Price.',description:'.'},        
    ],
    CURRENCY_LIST:[
      {value:1,name:"1.00",icon:"mdi-cash"},
      {value:5,name:"5.00",icon:"mdi-cash"},
      {value:10,name:"10.00",icon:"mdi-cash"},
      {value:20,name:"20.00",icon:"mdi-cash"},
      {value:50,name:"50.00",icon:"mdi-cash"},
      {value:100,name:"100.00",icon:"mdi-cash"},
      {value:200,name:"200.00",icon:"mdi-cash"},
      {value:500,name:"500.00",icon:"mdi-cash"},
      {value:1000,name:"1,000.00",icon:"mdi-cash"},
      {value:2000,name:"2,000.00",icon:"mdi-cash"},
      {value:5000,name:"5,000.00",icon:"mdi-cash"},
    ], 
    MEDICAL_SCHEMES:[ 
      {code:1, key:"MASM", name:"MASM", full_name:"MEDICAL AID SOCIETY OF MALAWI"},
      {code:2, key:"MEDHEALTHY", name:"MEDHEALTHY", full_name:"MEDHEALTHY MALAWI"},
      {code:3, key:"WEMAS", name:"WEMAS", full_name:"WELLA MEDICAL AID SOCIETY"},
      {code:4, key:"LIBERTY", name:"LIBERTY", full_name:"LIBERTY HEALTHY MALAWI"},
      {code:5, key:"HORIZON", name:"HORIZON", full_name:"HORIZON HEALTHY MALAWI"}
    ],
    PRINTER_OPTIONS:[ 
      {code:'print_all', key:"print_all", name:"Print All"}, 
      {code:'print_legal_receipt', key:"legal_receipt", name:"Print Legal Receipt"}, 
      {code:'print_nonlegal_receipt', key:"nonlegal_receipt", name:"Print Non-Legal Receipt"}, 
    ], 
    COMPUTER_USAGE_OPTION:[ 
      {code:'till_computer', key:"till_computer", name:"Till Computer",description:"Only used as till for a company."},  
      {code:'order_computer', key:"order_computer", name:"Orders Computer",description:"Only used for sales orders."},  
      {code:'sales_computer', key:"sales_computer", name:"Sales Computer",description:"Used for till, orders and all other selling option."},  
      {code:'warehouse_computer', key:"warehouse_computer", name:"Warehouse Computer",description:"Only used to manage warehouse."},  
      {code:'stock_computer', key:"stock_computer", name:"Stock Computer",description:"Only used to manage stock."},  
      {code:'stock_master_computer', key:"stock_master_computer", name:"Stock Master Computer",description:"Used to manage stock & warehouse."},  
      {code:'office_computer', key:"office_computer", name:"Office Computer",description:"Used for all available company options and modules."},  
      {code:'personal_computer', key:"personal_computer", name:"Personal Computer",description:"For all your companies."},  
    ], 
    UP_TIME:null, 
    LOGGED_TIME:null,  
  },
  getters:{
    getBrowserInfo(){
      return browser
    },
    getApp(state) {
      return state.app
    }, 
    getWebLinks(state){ 
      let us = users?users.state.USER:null
      let ud = users?users.state.USER_DETAILS:null 
      let links = state.WEB_LINKS

      let uid = us?us.uid:null
      let admin = ud?ud.admin:null

      if(!links){return null} 
      let myLinks = links.filter(link=>{
        //IF NOT ADMIN RETURN FALSE
        // if (!admin) {
        //     if (link.important===0.2) {
        //         return false
        //     }
        // }
        return true 
      })
      return myLinks  
      
       
    },
    getSocialMediaLinks(state){
      return state.SOCIAL_MEDIA_LINKS
    }, 
    getCountries(state) {
      return state.countries
    }, 
    getCurrencyList(state) {
      return state.CURRENCY_LIST
    }, 
    getUpTime(state) {
      return state.UP_TIME
    }, 
    getLoggedTime(state) {
      return state.LOGGED_TIME
    }, 
    getOutletTypeByCode(state) {
      let types = state.OUTLET_TYPES
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    },
    getDeviceTypeByCode(state) {
      let types = state.DEVICE_TYPES
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    },
    getComputerTypeByCode(state) {
      let types = state.COMPUTER_TYPES
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    },
    getPrinterTypeByCode(state) {
      let types = state.PRINTER_TYPES
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    }, 
    getTaxGroupByCode(state) {
      let types = state.TAX_GROUPS
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    },
    getPrinterOptionByCode(state) {
      let types = state.PRINTER_OPTIONS
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    }, 
    getPrinterOptionByKey(state) {
      let types = state.PRINTER_OPTIONS
      return key =>{
        if(!types || !key){return null}
        return types.find(item=>{
          return item.key == key
        })
      }
    },
    getComputerUsageOptionByCode(state) {
      let types = state.COMPUTER_USAGE_OPTION
      return code =>{
        if(!types || !code){return null}
        return types.find(item=>{
          return item.code == code
        })
      }
    }, 
    getComputerUsageOptionByKey(state) {
      let types = state.COMPUTER_USAGE_OPTION
      return key =>{
        if(!types || !key){return null}
        return types.find(item=>{
          return item.key == key
        })
      }
    }, 
  }, 
  mutations: {
    updateAppName(state,payload){
      state.appName = payload
    },
    updateUpTime(state,payload){
      state.UP_TIME = payload
    },
    updateLoggedTime(state,payload){
      state.LOGGED_TIME = payload
    },
  },
  actions: {
    setAppName({commit},payload){
      commit('updateAppName',payload)
    },
    setUpTime({commit},payload){
      commit('updateUpTime',payload)
    },
    setLoggedTime({commit},payload){
      commit('updateLoggedTime',payload)
    },
  }
}
