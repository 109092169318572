
<template>
    <div>   
        <v-container grid-list-xl> 
            <div>  
                <br>
                <h1 class="grey--text text--darken-1">Cashup</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                <h4 class="grey--text text--darken-1">Order Details</h4>
                                Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order. This includes inspecting the quality, condition, and quantity of any incoming goods, and allocating them to a space in the warehouse.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.cashup_close"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br> 
                    <v-row> 
                        <v-col cols="12" >
                             <input ref="btn_submit_order" type="submit" v-show="false"> 
                            <v-card v-if="ThisDayShift?ThisDayShift.total_closing_cash?true:false:false">
                                 
                            </v-card>
                            <v-card :min-height="vsh-170" outlined rounded="sm" > 
                                <v-layout fill-height column class="ma-0 "> 
                                    <v-toolbar  rounded="" outlined    color="primary" class="m-2" dark>  
                                        <template > 
                                            <v-toolbar-title> 
                                                <v-list color="transparent" v-if="ThisDayShift" >
                                                    <v-list-item>
                                                        <v-list-item-action><v-icon size="35">mdi-cash-register</v-icon></v-list-item-action>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ThisCashup?ThisCashup.teller_name:''}} 
                                                                ({{ ThisCashup?ThisCashup.till_name:'' }})
                                                            </v-list-item-title> 
                                                            <v-list-item-subtitle v-if="ThisDayShift">
                                                                <strong>{{ ThisDayShift?ThisDayShift.outlet_name:'...' }}:</strong>
                                                                {{MBS.date.moment(ThisDayShift.operating_date).format("dddd, Do, MMMM YYYY")}}
                                                                (<span class="secondary--text font-weight-bold">
                                                                    {{ ThisCashup?ThisCashup.closed?"Closed":"Running..":'---' }} 
                                                                </span>)
                                                            </v-list-item-subtitle>  
                                                        </v-list-item-content>                                                                                                             
                                                    </v-list-item>
                                                </v-list>
                                            </v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <v-divider vertical></v-divider> 

                                            <v-btn disabled
                                                icon @click="ON_PDF()">
                                                <v-icon>mdi-file-pdf-box </v-icon>
                                            </v-btn>
                                            <v-btn disabled
                                                icon @click="ON_EXCEL()">
                                                <v-icon>mdi-microsoft-excel </v-icon>
                                            </v-btn>
                                            <v-btn disabled
                                                icon @click="ON_EMAIL()">
                                                <v-icon>mdi-email-outline</v-icon>
                                            </v-btn>
                                            
                                            <v-btn  
                                                icon @click="ON_PRINTING()">
                                                <v-icon>mdi-printer</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-toolbar>
                                    <v-card color="transparent" :min-height="vsh-320" flat tile class="mb-5">
                                        <mbs-epson-cashup-temp1 v-if="ThisCashup" 
                                            :data="ThisCashup"
                                            :cashup="ThisCashup"  
                                            :day_shift="ThisDayShift"
                                             />   
                                        <div v-else>No cashup</div>
                                    </v-card> 
                                </v-layout> 
                            </v-card>   
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container> 
        
        <mbs-auto-print
            v-if="printing"
            @finish_print="FINISH_PRINT"
            :show="true"  
            :auto_print="auto_print"
            :auto_printer_name="auto_printer_name"
            :type="print_type" 
            :title="print_title" 
            :data="print_data" 
            /> 
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = "Z-READ"  
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','cash_key','type'], 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null,  
                DATA:DATA, 
                day_shift_key:null, 
                cashup_key:null, 
                show_input:true, 
                
                printing:false,
                print_type:'cashup',
                print_title:'CASHUP',
                print_data:null,
                auto_print:false,
                auto_printer_name:null,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.input.day_shift_key = this.day_key  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
                this.ON_SELECT_SHIFT()
                this.SHOW_INPUT()
                this.input.day_shift_key = this.day_key  
                this.input.cashup_key = this.cash_key  
 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////   
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
                Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
            }), 
            PaymentsData(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            CashupData(){ 
                let Cashups = this.Cashups
                let JoinedCashups = this.JoinedCashups
                let items = JoinedCashups?JoinedCashups:Cashups   
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },
            DayShiftsData(){ 
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts
                let items = JoinedDayShifts?JoinedDayShifts:DayShifts   
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },
            DayShiftsCashups(){
                let days = this.DayShiftsData
                if(!days){return null}
                let cashups = []
                days.forEach(element => {
                    if (element.joined_cashups) {
                        cashups = [...cashups,...element.joined_cashups]
                    }
                });  
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },
            
            

            ThisDayShift(){  
                let day_shift_key = this.day_shift_key
                let days = this.DayShiftsData
                if(!days||!day_shift_key){return null}
                let item =  days.find(day=>day.key==day_shift_key)
                let joined = this.MBS.actions.JOIN_DAY_SHIFT(item) 
                return joined
            },   
            ThisCashup(){  
                let cashup_key = this.cashup_key
                let cashups = this.DayShiftsCashups 
                if(!cashups||!cashup_key){return null}
                let item =  cashups.find(cash=>cash.key==cashup_key)   
                return item
            },   
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            TillsData(){ 
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData 
                let cashup = this.LocalCashup 
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })
                return filled
            },
            CurrentReceiptPrinter(){
                let till = this.CurrentTill 
                let printer = till?till.printer:null
                let joined = this.MBS.actions.JOIN_PRINTER(printer) 
                return joined
            },
            SelectItems(){ 
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups
                return {
                    DayShifts:DayShifts, 
                    Cashups:Cashups, 
                }
            },
            DisabledFields(){
                return ['cashup_key','description']
                let shift = this.ThisDayShift
                let settings = this.CompanySettings  
                let edit_closing_cash = settings?settings.edit_closing_cash:null
                let total_closing_cash = shift?shift.total_closing_cash:null
                
                if (!edit_closing_cash) {
                    return ['closing_cash','operating_date_','outlet_name','expected_cash']
                }
                if (total_closing_cash) {
                }
                return null
            },  
            
        },
        methods: {    
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            FINISH_PRINT(action,payload){
                try { 
                    let canceled = payload?payload.canceled:null
                    if (canceled) {
                        
                    }else{
                        setTimeout(() => {
                                // this.MBS.actions.go(-1)   
                        }, 1); 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FINISH_PRINT',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SHOW_INPUT(){
                try { 
                    this.show_input = false
                    let cashup = this.ThisCashup
                    if (cashup) {
                        this.input.description = cashup.description?cashup.description:'--'
                        setTimeout(() => {
                            this.show_input = true
                        }, 500);
                    }
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ADD_ITEM(item_key){
                try {
                    let items = this.JOINED_ITEMS
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            SUBMIT_ORDER(order){
                try {  
                    if (!order) {
                        console.log(order,'order error');
                    }  
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                        data:order 
                    }).then(res=>{
                        this.MBS.events.$emit("SUBMIT_DAY_SHIFTS",PAGE_NAME)
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.DAYS_SHIFTS.value,"Closing")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                        this.ON_PRINTING()  
                        setTimeout(() => {
                            this.MBS.actions.go(-1)   
                        }, 500);
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT_SHIFT(){
                try {  
                    let input = this.input
                    this.cashup_key = input?input.cashup_key:null
                    this.day_shift_key = input?input.day_shift_key:null 
                    console.log(input,'input.....'); 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  

            READ_REPORT(){
                try {  
                    this.MBS.actions.go(-1)
                    setTimeout(() => {
                        let salesReport = this.MBS.actions.COMPANY_LINK("/day-shift-report/"+this.day_shift_key,true)
                        this.MBS.actions.go(salesReport) 
                    }, 5);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'READ_REPORT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            // ON_PRINTING(){
            //     try { 
            //         let lastSale = this.ThisDayShift 
            //         this.autoPrint = false
            //         setTimeout(() => {
            //             this.itemPrint = lastSale
            //             this.typePrint = 'z_read'
            //             this.autoPrint = true  
            //             console.log('8888888888888888');
            //         }, 5);
                     
            //     } catch (error) {
            //         this.MBS.actions.error({
            //             error:error,
            //             from:'ON_EXCEL',
            //             page:PAGE_NAME, 
            //         }) 
            //     }
                
            // }, 
            async ON_PRINTING(){
                try { 
                    let cashup = this.ThisCashup 
                    let day_shift = this.ThisDayShift 
                    // let res = await this.MBS.actions.EPSON_CASHUP_REQUEST({
                    //     cashup:cashup,
                    //     day_shift:day_shift,
                    //     printer:{
                    //         paper_size:48,
                    //         printer_name:"POS80"
                    //     }
                    // }) 

                    // let print = res?res.data:null
                    // console.log(res,'res........');

                    // let print_res = await this.MBS.actions.EPSON_PRINT_LINES(print) 
                    // console.log(print_res,'print_res........');

                    let printer = this.CurrentReceiptPrinter
                    let printer_name = printer?printer.name:null
                    let printer_type_name = printer?printer.printer_type_name:null
                    
                     
                    this.printing = false
                    setTimeout(() => {
                        if (printer_type_name) {
                            // this.auto_print = true
                        }
                        this.auto_printer_name = printer_name
                        this.print_data = cashup 
                        this.printing = true   
                    }, 5); 
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 

            
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(value){   
                if (value) {
                    // if (value.day_shift_key != this.day_shift_key) {
                    //     this.day_shift_key = value.day_shift_key
                    // }  
                    this.ON_SELECT_SHIFT(value)
                } 
            },
            ThisDayShift(value){    
                this.ON_SELECT_SHIFT(value)
            },
            ThisCashup(value){    
                this.SHOW_INPUT() 
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
