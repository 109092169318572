const production = true;
const POS_TYPE = {
    // name:"Beta Version",
    name:"POS",
    // name:"Pharmacy Version",
    // name:"Supermarket Version",
    // name:"Hotel Version",
    // name:"Hospital",
}
const key1 = {   
    apiKey: "AIzaSyCLhKuTKynjOAsjBxoqaiRXEyq1gIP3lyY",
    authDomain: "mbspos.firebaseapp.com",
    databaseURL: "https://mbspos-default-rtdb.firebaseio.com",
    projectId: "mbspos",
    storageBucket: "mbspos.appspot.com",
    messagingSenderId: "841888713206",
    appId: "1:841888713206:web:00c5196cf9333dc2309fd7",
    measurementId: "G-ZDW2ET0178"
};
const key2 = {
    apiKey: "AIzaSyCg5v2TJsDFvr-W1sGXjbTeSaSvw6Ae-cY",
    authDomain: "kip-count.firebaseapp.com",
    databaseURL: "https://kip-count-default-rtdb.firebaseio.com",
    projectId: "kip-count",
    storageBucket: "kip-count.appspot.com",
    messagingSenderId: "636237405752",
    appId: "1:636237405752:web:8cd901f1f50e424ebfd0cd",
    measurementId: "G-HBJZS6VMZF"
};
const SERVER_LINK = "https://us-central1-mbspos.cloudfunctions.net/"
const SERVER_LINK2 = "https://us-central1-kip-count.cloudfunctions.net/"

const ADMIN_PHONE = "+265993259202"
const APP ={ 
    VERSION:'0.0.239',
    VERSION_NAME:POS_TYPE.name,
    NAME:"KipCount",
    SERVER:"MPOS",
    SERVER2:"DBC",
    SERVER_LINK:production?SERVER_LINK2:SERVER_LINK, 
    SERVER_VERSION:62,
    CODE_VERSION:12,
    EMAIL_SERVER:"@kipcount.com",
    POWERED_BY:"Powered by Kipcount",

    ACTION_REFRESH_TIME:100,
    ALLOWED_HOLD_TIME:10000,
    LINK:"https://mbspos.web.app/",
    EMAIL:"regisjearmbera@gmail.com",
    ADMIN_EMAIL:ADMIN_PHONE+"@kipcount.com", 
    ADMIN_PHONE:ADMIN_PHONE, 
    DEFAULT_PASSWORD:'123456789',
    CC_EMAIL:"",
    key:production?key2:key1,
    // key:key1,
    // key:key2,
    USER_TIMELINE:{
        current:'CURRENT_USER',
        action_request:'ACTION_REQUEST_USER',
        logged_out:'LOGGED_OUT_USER',
    }
};  
const CODE = {
    timestamp:"TIMESTAMP", 
    system:"SYSTEM_CODE",
}  
const PAY_DOMAINS ={   
    ngenius:"https://api-gateway.sandbox.ngenius-payments.com/",  
    standardbank:"https://api-gateway.sandbox.standardbank.co.mw/",  
};
const PAY_OUTLETS = { 
    m1BusLimited:"35f5f96a-a32b-42e4-af19-38206c5bc8df",

    future_web_app:"df391b6a-362b-49b8-8c81-6e2339e916ae",
    future_mobile_app:"2e37a034-e5bc-472c-be11-d71418c04494",
    future_m1_web_app:"3a430460-f6db-48bf-8026-4348d248dc7d",
    future_m1_mobile_app:"610a92b4-e20c-4b69-80d5-df9962866362",
} 
const BANKS = {
    standard_bank:{
        name:"Standard Bank",
        value:"standard_bank",
        description:"",
        type:'bank',
        link:'/bank'
    },  
}
const PAY_API ={ 
    KEY:'OTZjMzEyZjMtMGY2OC00ODE5LWE4NGQtMjkyNGE2NGRjODUwOmZjMzYxYjc1LTAzYjctNDRjMi04YzlhLThlYWNkNzUxYzhmZg==',
    outlet:PAY_OUTLETS, 
    realmName:"StandardBankMalawiSandbox", 
    LINKS:{
        domain:PAY_DOMAINS,
        accessToken:PAY_DOMAINS.ngenius+"identity/auth/access-token",
        outlets:PAY_DOMAINS.ngenius+"transactions/outlets/"
    }, 
    banks:BANKS,
    orderStates:{
        PURCHASE:"PURCHASE",
        PURCHASED:"PURCHASED",
        AWAIT_3DS:"AWAIT_3DS",
    }
};  
const ITEMS ={ 
    ERRORS: {
        names: 'Errors',
        name: 'Error',
        values: 'errors',
        value: 'error',
    },
    NOTIFICATIONS: {
        names: 'Notifications',
        name: 'Notification',
        values: 'notifications',
        value: 'notification',
    },
    ONLINE_STATUSES: {
        names: 'Online Statuses',
        name: 'Online Status',
        values: 'online_statuses',
        value: 'online_status',
    },
    USERS: {
        names: 'Users',
        name: 'User',
        values: 'users',
        value: 'user',
    },
    USER_STATUSES: {
        names: 'User Statuses',
        name: 'User Status',
        values: 'user_statuses',
        value: 'user_status',
    },
    USER_LIVES: {
        names: 'User Lives',
        name: 'User Live',
        values: 'user_lives',
        value: 'user_live',
    },
    USER_DETAILS: {
        names: 'User Details',
        name: 'User Detail',
        values: 'user_details',
        value: 'user_detail',
    },
    USER_GROUPS: {
        names: 'User groups',
        name: 'User group',
        values: 'user_groups',
        value: 'user_group',
    },
    VISITERS: {
        names: 'Visiters',
        name: 'Visiter',
        values: 'visiters',
        value: 'visiter',
    },
    PERMISSIONS: {
        names: 'Permissions',
        name: 'Permission',
        values: 'permissions',
        value: 'permission',
    },
    MESSAGES: {
        names: 'Messages',
        name: 'Message',
        values: 'messages',
        value: 'message',
    },
    LOCATIONS: {
        names: 'Locations',
        name: 'Location',
        values: 'locations',
        value: 'location',
    },
    COUNTRIES: {
        names: 'Countries',
        name: 'Country',
        values: 'countries',
        value: 'country',
    },
    REGIONS: {
        names: 'Regions',
        name: 'Regions',
        values: 'regions',
        value: 'regions',
    },
    DISTRICTS: {
        names: 'Districts',
        name: 'District',
        values: 'districts',
        value: 'district',
    },
    BANKS: {
        names: 'Banks',
        name: 'Bank',
        values: 'banks',
        value: 'bank',
    },
    PAYMENTS: {
        names: 'Payments',
        name: 'Payment',
        values: 'payments',
        value: 'payment',
    },
    PAYMENT_METHODS: {
        names: 'Payment Methods',
        name: 'Payment Method',
        values: 'payment_methods',
        value: 'payment_method',
    },
    DEFAULT_PAYMENT_METHODS: {
        names: 'Default Payment Methods',
        name: 'Default Payment Method',
        values: 'default_payment_methods',
        value: 'default_payment_method',
    },
    MONEY_ACCOUNTS: {
        names: 'Money Accounts',
        name: 'Money Account',
        values: 'money_accounts',
        value: 'money_account',
    },
    ON_ACCOUNTS: {
        names: 'On Accounts',
        name: 'On Account',
        values: 'on_accounts',
        value: 'on_account',
    },
    DEFAULT_MONEY_ACCOUNTS: {
        names: 'Default Money Accounts',
        name: 'Default Money Account',
        values: 'default_money_accounts',
        value: 'default_money_account',
    },
    DEFAULT_BILL_METHODS: {
        names: 'Default Bill Methods',
        name: 'Default Bill Methods',
        values: 'default_bill_methods',
        value: 'default_bill_method',
    },
    ACCESS_KEYS: {
        names: 'Access Keys',
        name: 'Access Key',
        values: 'access_keys',
        value: 'access_key',
    },
    ORDERS: {
        names: 'Orders',
        name: 'Order',
        values: 'orders',
        value: 'order',
    }, 
    ID_TYPES: {
        names: 'ID Types',
        name: 'ID Type',
        values: 'id_types',
        value: 'id_type',
    },
    DELIVERING_STEPS: {
        names: 'Delivering Steps',
        name: 'Delivering Step',
        values: 'delivering_steps',
        value: 'delivering_step',
    },
    ACTION_HOLDERS: {
        names: 'Action Holders',
        name: 'Action Holder',
        values: 'action_holders',
        value: 'action_holder',
    },
    REFUND_POLICE: {
        names: 'Refund Polices',
        name: 'Refund Police',
        values: 'refund_polices',
        value: 'refund_police',
    },
    CANCELLATION_FEES: {
        names: 'Cancellation Fees',
        name: 'Cancellation Fee',
        values: 'cancellation_fees',
        value: 'cancellation_fee',
    },
    MEASUREMENT_UNITS: {
        names: 'Measurement Units',
        name: 'Measurement Unit',
        values: 'measurement_units',
        value: 'measurement_unit',
    },
    ITEMS: {
        names: 'Items',
        name: 'Item',
        values: 'items',
        value: 'item',
    },
    ITEM_CATEGORIES: {
        names: 'Item Categories',
        name: 'Item Category',
        values: 'item_categories',
        value: 'item_category',
    },
    ITEM_DEPARTMENTS: {
        names: 'Item Departments',
        name: 'Item Department',
        values: 'item_departments',
        value: 'item_department',
    },
    ITEM_KITS: {
        names: 'Item Kits',
        name: 'Item Kit',
        values: 'item_kits',
        value: 'item_kit',
    },
    ITEM_ORDERS: {
        names: 'Item Orders',
        name: 'Item Order',
        values: 'item_orders',
        value: 'item_order',
    },
    LOCAL_ITEM_ORDERS: {
        names: 'Local Item Orders',
        name: 'Local Item Order',
        values: 'local_item_orders',
        value: 'local_item_order',
    },
    ITEM_ORDER_OPTIONS: {
        names: 'Item Order Options',
        name: 'Item Order Option',
        values: 'item_order_options',
        value: 'item_order_option',
    },
    SUPPLIERS: {
        names: 'Suppliers',
        name: 'Supplier',
        values: 'suppliers',
        value: 'supplier',
    },
    SUPPLIER_CATEGORIES: {
        names: 'Supplier Categories',
        name: 'Supplier Category',
        values: 'supplier_categories',
        value: 'supplier_category',
    },
    CUSTOMERS: {
        names: 'Customers',
        name: 'Customer',
        values: 'customers',
        value: 'customer',
    },
    CUSTOMER_GROUPS: {
        names: 'Customer Groups',
        name: 'Customer Group',
        values: 'customer_groups',
        value: 'customer_group',
    },
    PATIENTS: {
        names: 'Patients',
        name: 'Patient',
        values: 'patients',
        value: 'patient',
    },
    PATIENT_ORDERS: {
        names: 'Patient Orders',
        name: 'Patient Order',
        values: 'patient_orders',
        value: 'patient_order',
    },
    PATIENT_HISTORIES: {
        names: 'Patients Histories',
        name: 'Patient History',
        values: 'patient_histories',
        value: 'patient_history',
    },
    DOCTORS: {
        names: 'Doctors',
        name: 'Doctor',
        values: 'doctors',
        value: 'doctor',
    },
    COMPANIES: {
        names: 'Companies',
        name: 'Company',
        values: 'companies',
        value: 'company',
    },
    COMPANY_QUICK_ACTIONS: {
        names: 'Company Quick Actions',
        name: 'Company Quick Action',
        values: 'company_quick_actions',
        value: 'company_quick_action',
    },
    MY_COMPANIES: {
        names: 'My Companies',
        name: 'My Company',
        values: 'my_companies',
        value: 'my_company',
    },
    COMPANY_DATA: {
        names: 'Company Data',
        name: 'Company Data',
        values: 'company_data',
        value: 'company_data',
    },
    COMPANY_TYPES: {
        names: 'Company Types',
        name: 'Company Type',
        values: 'company_types',
        value: 'company_type',
    },
    COMPANY_SIZES: {
        names: 'Company Sizes',
        name: 'Company Size',
        values: 'company_sizes',
        value: 'company_size',
    },
    COMPANY_USERS: {
        names: 'Company Users',
        name: 'Company User',
        values: 'company_users',
        value: 'company_user',
    },
    COMPANY_USER_GROUPS: {
        names: 'Company User Groups',
        name: 'Company User Group',
        values: 'company_user_groups',
        value: 'company_user_group',
    },
    COMPANY_EXPENSES: {
        names: 'COMPANY Expenses',
        name: 'COMPANY Expense',
        values: 'company_expenses',
        value: 'company_expense',
    }, 
    EXPENSES: {
        names: 'Expenses',
        name: 'Expense',
        values: 'expenses',
        value: 'expense',
    }, 
    EXPENSE_ACCOUNTS: {
        names: 'Expense Accounts',
        name: 'Expense Account',
        values: 'expense_accounts',
        value: 'expense_account',
    }, 
    INCOMES: {
        names: 'Incomes',
        name: 'Income',
        values: 'incomes',
        value: 'income',
    }, 
    RECEIVINGS: {
        names: 'Receivings',
        name: 'Receiving',
        values: 'receivings',
        value: 'receiving',
    },
    TILLS: {
        names: 'Tills',
        name: 'Till',
        values: 'tills',
        value: 'till',
    },
    DAYS_SHIFTS: {
        names: 'Days Shifts',
        name: 'Days Shift',
        values: 'days_shifts',
        value: 'days_shift',
    },
    DAYS_SHIFTS_SALES: {
        names: 'Days Shift Sales',
        name: 'Days Shift Sale',
        values: 'days_shift_sales',
        value: 'days_shift_sale',
    },
    DAY_SALES: {
        names: 'Day Sales',
        name: 'Day Sale',
        values: 'day_sales',
        value: 'day_sale',
    },
    LOCAL_DAYS_SHIFTS: {
        names: 'Local Days Shifts',
        name: 'Local Days Shift',
        values: 'local_days_shifts',
        value: 'local_days_shift',
    },
    CASHUPS: {
        names: 'Cashups',
        name: 'Cashup',
        values: 'cashups',
        value: 'cashup',
    },
    LOCAL_CASHUPS: {
        names: 'Local Cashups',
        name: 'Local Cashup',
        values: 'local_cashups',
        value: 'local_cashup',
    },
    STOCKS: {
        names: 'Stocks',
        name: 'Stock',
        values: 'stocks',
        value: 'stock',
    },
    STOCK_OUTLETS: {
        names: 'Stock Outlets',
        name: 'Stock Outlet',
        values: 'stock_outlets',
        value: 'stock_outlet',
    },
    STOCK_ORDERS: {
        names: 'Stock Orders',
        name: 'Stock Order',
        values: 'stock_orders',
        value: 'stock_order',
    },
    STOCK_ORDER_ITEMS: {
        names: 'Stock Order Items',
        name: 'Stock Order Item',
        values: 'stock_order_items',
        value: 'stock_order_item',
    },
    STOCK_RECEIVES: {
        names: 'Stock Receives',
        name: 'Stock Receive',
        values: 'stock_receives',
        value: 'stock_receive',
    },
    STOCKS_RETURN: {
        names: 'Stocks Return',
        name: 'Stocks Return',
        values: 'stocks_return',
        value: 'stocks_return',
    },
    STOCK_TRANSFERS: {
        names: 'Stock Transfers',
        name: 'Stock Transfer',
        values: 'stock_transfers',
        value: 'stock_transfer',
    },
    STOCK_ADJUSTMENTS: {
        names: 'Stock Adjustments',
        name: 'Stock Adjustment',
        values: 'stock_adjustments',
        value: 'stock_adjustment',
    },
    LOCAL_SELECTED_SALES_ITEMS: {
        names: 'Local Selected Sales Items',
        name: 'Local Selected Sales Item',
        values: 'local_selected_sales_items',
        value: 'local_selected_sales_item',
    },
    LOCAL_SALES: {
        names: 'Local Sales',
        name: 'Local Sale',
        values: 'local_sales',
        value: 'local_sale',
    },
    LOCAL_SALE_ORDERS: {
        names: 'Local Sale Orders',
        name: 'Local Sale Order',
        values: 'local_sale_orders',
        value: 'local_sale_order',
    },
    LOCAL_SALE_ORDER_ITEMS: {
        names: 'Local Sale Order Items',
        name: 'Local Sale Order Item',
        values: 'local_sale_order_items',
        value: 'local_sale_order_item',
    },
    SALES: {
        names: 'Sales',
        name: 'Sale',
        values: 'sales',
        value: 'sale',
    },
    SALES_RETURN: {
        names: 'Sales Return',
        name: 'Sale Return',
        values: 'sales_return',
        value: 'sale_return',
    },
    LOCAL_SALES_RETURN: {
        names: 'Local Sales Return',
        name: 'Local Sale Return',
        values: 'local_sales_return',
        value: 'local_sale_return',
    },
    WAREHOUSES: {
        names: 'Warehouses',
        name: 'Warehouse',
        values: 'warehouses',
        value: 'warehouse',
    },
    WAREHOUSE_SESSIONS: {
        names: 'Warehouse Sessions',
        name: 'Warehouse Session',
        values: 'warehouse_sessions',
        value: 'warehouse_session',
    },
    LOCAL_WAREHOUSE_SESSIONS: {
        names: 'Local Warehouse Sessions',
        name: 'Local Warehouse Session',
        values: 'local_warehouse_sessions',
        value: 'local_warehouse_session',
    },
    WAREHOUSE_ORDERS: {
        names: 'Warehouse Orders',
        name: 'Warehouse Order',
        values: 'warehouse_orders',
        value: 'warehouse_order',
    }, 
    LOCAL_WAREHOUSE_ORDERS: {
        names: 'Local Warehouse Orders',
        name: 'Local Warehouse Order',
        values: 'local_warehouse_orders',
        value: 'local_warehouse_order',
    }, 
    QUOTATIONS_AND_INVOICES: {
        names: 'Quotations and Invoices',
        name: 'Quotation and Invoice',
        values: 'quotations_and_invoices',
        value: 'quotation_and_invoice',
    },
    QUOTATIONS: {
        names: 'Quotations',
        name: 'Quotation',
        values: 'quotations',
        value: 'quotation',
    }, 
    LOCAL_QUOTATIONS: {
        names: 'Local Quotations',
        name: 'Local Quotation',
        values: 'local_quotations',
        value: 'local_quotation',
    }, 
    INVOICES: {
        names: 'Invoices',
        name: 'Invoice',
        values: 'invoices',
        value: 'invoice',
    }, 
    REQUEST_FOR_QUOTATIONS: {
        names: 'Request for Quotations',
        name: 'Request for Quotation',
        values: 'request_for_quotations',
        value: 'request_for_quotation',
    }, 
    SALES: {
        names: 'Sales',
        name: 'Sale',
        values: 'sales',
        value: 'sale',
    }, 
    SALE_ORDERS: {
        names: 'Sale Orders',
        name: 'Sale Order',
        values: 'sale_orders',
        value: 'sale_order',
    },
    SALE_ORDER_ITEMS: {
        names: 'Sale Order Items',
        name: 'Sale Order Item',
        values: 'sale_order_items',
        value: 'sale_order_item',
    },
    BRANDS: {
        names: 'Brands',
        name: 'Brand',
        values: 'brands',
        value: 'brand',
    },
    MANUFACTURERS: {
        names: 'Manufacturers',
        name: 'Manufacturer',
        values: 'Manufacturers',
        value: 'Manufacturer',
    },
    CARDS: {
        names: 'Cards',
        name: 'Card',
        values: 'cards',
        value: 'card',
    }, 
    CARD_PAYMENTS: {
        names: 'Card Payments',
        name: 'Card Payment',
        values: 'card_payments',
        value: 'card_payment',
    }, 
    CARD_TYPES: {
        names: 'Card Types',
        name: 'Card Type',
        values: 'card_types',
        value: 'card_type',
    }, 
    GIFT_CARDS: {
        names: 'Gift Cards',
        name: 'Gift Card',
        values: 'gift_cards',
        value: 'gift_card',
    }, 
    MAGNETIC_CARDS: {
        names: 'Magnetic Cards',
        name: 'Magnetic Card',
        values: 'magnetic_cards',
        value: 'magnetic_card',
    }, 
    SWIPING_CARDS: {
        names: 'Swiping Cards',
        name: 'Swiping Card',
        values: 'swiping_cards',
        value: 'swiping_card',
    }, 
    TAXES: {
        names: 'Taxes',
        name: 'Tax',
        values: 'taxes',
        value: 'tax',
    }, 
    VAT: {
        names: 'VAT',
        name: 'VAT',
        values: 'vat',
        value: 'vat',
    }, 
    REPORTS: {
        names: 'Reports',
        name: 'Report',
        values: 'reports',
        value: 'report',
    }, 
    BILLS: {
        names: 'Bills',
        name: 'Bill',
        values: 'bills',
        value: 'bill',
    }, 
    PURCHASES: {
        names: 'Purchases',
        name: 'Purchase',
        values: 'purchases',
        value: 'purchase',
    }, 
    DEBTORS: {
        names: 'Debtors',
        name: 'Debtor',
        values: 'debtors',
        value: 'debtor',
    }, 
    CREDITORS: {
        names: 'Creditors',
        name: 'Creditor',
        values: 'creditors',
        value: 'creditor',
    }, 
    PAYABLES: {
        names: 'Payables',
        name: 'Payable',
        values: 'payables',
        value: 'payable',
    }, 
    RECEIVABLES: {
        names: 'Receivables',
        name: 'Receivable',
        values: 'receivables',
        value: 'receivable',
    }, 
    ASSETS: {
        names: 'Assets',
        name: 'Asset',
        values: 'assets',
        value: 'asset',
    }, 
    ASSET_TYPES: {
        names: 'Asset Types',
        name: 'Asset Type',
        values: 'asset_types',
        value: 'asset_type',
    },
    CASHBOOKS: {
        names: 'Cashbooks',
        name: 'Cashbook',
        values: 'cashbooks',
        value: 'cashbook',
    },  
    OPENING_STOCK: {
        names: 'Opening Stocks',
        name: 'Opening Stock',
        values: 'opening_stocks',
        value: 'opening_stock',
    },  
    OPENING_BALANCE: {
        names: 'Opening Balances',
        name: 'Opening Balance',
        values: 'opening_balances',
        value: 'opening_balance',
    },   
    CLOSING_BALANCE: {
        names: 'Closing Balances',
        name: 'Closing Balance',
        values: 'closing_balances',
        value: 'closing_balance',
    }, 
    DEVICES: {
        names: 'Devices',
        name: 'Device',
        values: 'devices',
        value: 'device',
    }, 
    DEVICES_TYPES: {
        names: 'Device Types',
        name: 'Device Type',
        values: 'device_types',
        value: 'device_type',
    }, 
    LOCAL_DEVICES: {
        names: 'Local Devices',
        name: 'Local Device',
        values: 'local_devices',
        value: 'local_device',
    }, 
    COMPUTERS: {
        names: 'Computers',
        name: 'Computer',
        values: 'computers',
        value: 'computer',
    }, 
    COMPUTER_TYPES: {
        names: 'Computer Types',
        name: 'Computer Type',
        values: 'computer_types',
        value: 'computer_type',
    }, 
    CONNECTED_PRINTERS: {
        names: 'Connected Printers',
        name: 'Connected Printer',
        values: 'connected_printers',
        value: 'connected_printer',
    }, 
    PRINTERS: {
        names: 'Printers',
        name: 'Printer',
        values: 'printers',
        value: 'printer',
    }, 
    PRINTER_TYPES: {
        names: 'Printer Types',
        name: 'Printer Type',
        values: 'printer_types',
        value: 'printer_type',
    }, 
    SCALES: {
        names: 'Scales',
        name: 'Scale',
        values: 'scales',
        value: 'scale',
    },
    VFD_DISPLAYS: {
        names: 'VFD Displays',
        name: 'VFD Display',
        values: 'vfd_displays',
        value: 'vfd_display',
    },  
    TAX_GROUPS: {
        names: 'Tax Groups',
        name: 'Tax Group',
        values: 'tax_groups',
        value: 'tax_group',
    },  
    TRACKINGS: {
        names: 'Trackings',
        name: 'Tracking',
        values: 'trackings',
        value: 'tracking',
    }, 
    TRACKING_KEYS: {
        names: 'Tracking Keys',
        name: 'Tracking Key',
        values: 'tracking_keys',
        value: 'tracking_key',
    },  
    TRACKING_UPDATES: {
        names: 'Tracking Updates',
        name: 'Tracking Update',
        values: 'tracking_updates',
        value: 'tracking_update',
    },   
    EXPIRIES: {
        names: 'Expiries',
        name: 'Expiry',
        values: 'expiries',
        value: 'expiry  ',
    }, 
    OLD_DATA: {
        names: 'Old Data',
        name: 'Old Data',
        values: 'old_data',
        value: 'old_data  ',
    }, 
    COMPANY_SETTINGS: {
        names: 'Company Settings',
        name: 'Company Setting',
        values: 'company_settings',
        value: 'company_setting  ',
    },  
    STOCK_TAKING_ORDERS: {
        names: 'Stock Taking Orders',
        name: 'Stock Taking Order',
        values: 'stock_taking_orders',
        value: 'stock_taking_order',
    }, 
    STOCK_TAKINGS: {
        names: 'Stock Takings',
        name: 'Stock Taking',
        values: 'stock_takings',
        value: 'stock_taking',
    },
    LOCAL_JOINED_STOCK_TAKING: {
        names: 'Local Joined Stock Takings',
        name: 'Local Joined Stock Taking',
        values: 'local_joined_stock_takings',
        value: 'local_joined_stock_taking',
    },  
    LOCAL_STOCK_TAKING_ITEMS: {
        names: 'Local Stock Takings Items',
        name: 'Local Stock Taking Items',
        values: 'local_stock_taking_items',
        value: 'local_stock_taking_items',
    }, 
    PAGE_HISTORIES: {
        names: 'Page Histories',
        name: 'Page History',
        values: 'page_histories',
        value: 'page_history',
    },   
};
const FIELDS = { 
    //locations
    countries:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},    
        {type:"text",value:"name",label:"Enter Name",label_top:"Country Full Name*",
            roles:{
                field_error:{
                    primary:ITEMS.COUNTRIES.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    regions:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"country_code",label:"Select Country",label_top:"Country(Optional)",
            select:{
                name:'Countries',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"countries",
                    name:"Add New Country",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"countries",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Country", 
                },{ 
                    item_values:"countries",
                    name:"Manage Countries",
                    link:"/cpanel/locations/countries/",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},   
        {type:"text",value:"name",label:"Enter Name",label_top:"Region Name*",
            roles:{
                field_error:{
                    primary:ITEMS.REGIONS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    districts:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"region_code",label:"Select Region",label_top:"Region(Optional)",
            select:{
                name:'Regions',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"regions",
                    name:"Add New Regions",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"regions",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Regions", 
                },{ 
                    item_values:"regions",
                    name:"Manage Countries",
                    link:"/cpanel/locations/regions/",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},   
        {type:"text",value:"name",label:"Enter Name",label_top:"District Name*",
            roles:{
                field_error:{
                    primary:ITEMS.DISTRICTS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 

    //company
    company_types:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},  
        {type:"text",value:"name",label:"Enter Name",label_top:"Company Type Name*",required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    company_size:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},  
        {type:"text",value:"name",label:"Enter Name",label_top:"Company Size Name*",required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 

    //people
    add_user:[ 
        {type:"autocomplete",value:"user",label:"Select User",label_top:"User Phone Number*",
        select:{
            name:'Users',
            value:(item)=>{return item},
            text:'phone_number'
        },
        search:{
            filter:(item, queryText, itemText) => { 
                let index = (a,b)=>{
                    if(!a||!b){return false}
                    a = ""+a; b = ""+b
                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                } 
                let first = index(itemText,queryText)
                let second = item?index(item.names,queryText):false
                let third = item?index(item.surname,queryText):false
                if(!item){return first} 
                return first || second  || third 
            },
            item_icon:{
                name:'mdi-account'
            },
            item_title:['phone_number'],
            item_subtitle:['names','surname']
        },
        action:[ 
            { 
                item_values:"users",
                name:"Register User",
                link:"/settings/roles/register-user",
                company_link:true,
                append:true
            }
        ],required:true,sm:12,md:6},  
        {type:"autocomplete",value:"company_user_group_code",label:"Select Role",label_top:"User Role*",select:{
            name:'UserGroups',
            value:'code',
            text:'name'
        },action:[
            { 
                action:"add_item",
                item_values:"user_groups",
                name:"Add New Role",
                append:true
            },{ 
                action:"add_item",
                item_values:"user_groups",
                action_name:"Add", 
                name:" as New Role", 
            },{ 
                item_values:"user_groups",
                name:"Manage User Roles",
                link:"/settings/roles/user-roles",
                company_link:true,
                append:true
            }
        ],required:true,sm:12,md:6},      
    ],
    edit_user:[ 
        {type:"autocomplete",value:"user",disabled:true,label:"Select User",label_top:"User Phone Number*",
        select:{
            name:'Users',
            value:"key",
            text:'phone_number'
        },
        search:{
            filter:(item, queryText, itemText) => { 
                let index = (a,b)=>{
                    if(!a||!b){return false}
                    a = ""+a; b = ""+b
                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                } 
                let first = index(itemText,queryText)
                let second = item?index(item.code,queryText):false
                if(!item){return first} 
                return first || second 
            },
            item_icon:{
                name:'mdi-account'
            },
            item_title:['name'],
            item_subtitle:['code']
        },
        action:[ 
            { 
                item_values:"users",
                name:"Register User",
                link:"/settings/roles/register-user",
                company_link:true,
                append:true
            }
        ],required:true,sm:12,md:6},  
        {type:"autocomplete",value:"company_user_group_code",label:"Select Role",label_top:"User Role*",select:{
            name:'UserGroups',
            value:'code',
            text:'name'
        },action:[
            { 
                action:"add_item",
                item_values:"user_groups",
                name:"Add New Role",
                append:true
            },{ 
                action:"add_item",
                item_values:"user_groups",
                action_name:"Add", 
                name:" as New Role", 
            },{ 
                item_values:"user_groups",
                name:"Manage User Roles",
                link:"/settings/roles/user-roles",
                company_link:true,
                append:true
            }
        ],required:true,sm:12,md:6},      
    ], 
    customers:[
        {steps:[
            {name:'Name'}, 
            {name:'Details'},
        ]},
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"customer_group_code",label:"Select Group",label_top:"Customer Group (Optional)",
            select:{
                name:'CustomerGroups',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"customer_groups",
                    name:"Add New Group",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"customer_groups",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Group", 
                },{ 
                    item_values:"customer_groups",
                    name:"Manage Groups",
                    link:"/office/people/customers/groups",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},    
        {type:"text",value:"name",label:"Enter Name",label_top:"Customer Full Name*",
            select:{
                name:'Customers',
                value:'name',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            roles:{
                field_error:{
                    primary:ITEMS.CUSTOMERS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:12,autofocus:true,step_name:"Name"},    
        // {type:"option_text",value:"phone_number",label:"Phone",label_top:"Customer Phone Number*",
        //     select:{
        //         name:'Customers',
        //         value:'name',
        //         text:'phone_number'
        //     },
        //     search:{
        //         filter:(item, queryText, itemText) => { 
        //             let index = (a,b)=>{
        //                 if(!a||!b){return false}
        //                 a = ""+a; b = ""+b
        //                 return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
        //             } 
        //             let first = index(itemText,queryText)
        //             let second = item?index(item.code,queryText):false
        //             if(!item){return first} 
        //             return first || second 
        //         },
        //         item_icon:{
        //             name:'mdi-account'
        //         },
        //         item_title:['name'],
        //         item_subtitle:['code']
        //     },
            
        // required:true,sm:12,md:6},    
        // {type:"text",value:"name",label:"Enter Name",label_top:"Customer Full Name*",required:true,sm:12,md:6},
        {type:"text",value:"phone_number",label:"Phone",label_top:"Customer Phone Number (Optional)",
            roles:{
                field_error:{
                    primary:ITEMS.CUSTOMERS.values,
                    min_size:null,
                    max_size:50,
                }
            },
        required:false,sm:12,md:6,step_name:"Details",autofocus:true},   
        {type:"text",value:"email_address",label:"Email",label_top:"Customer Email Address (Optional)",required:false,sm:12,md:6,step_name:"Details"},   
        {type:"text",value:"location_address",label:"Location",label_top:"Customer Address (Optional)",required:false,sm:12,md:12,step_name:"Details"}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},   
    ], 
    customer_groups:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Customer Group Name*",
            roles:{
                field_error:{
                    primary:ITEMS.CUSTOMER_GROUPS.values,
                    min_size:0,
                    max_size:13,
                }
            }
        ,required:true,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    suppliers:[
        {steps:[
            {name:'Name'}, 
            {name:'Details'},
        ]},
        {type:"text",value:"name",label:"Enter Name",label_top:"Supplier Full Name*",
            roles:{
                field_error:{
                    primary:ITEMS.SUPPLIERS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:12,autofocus:true,step_name:"Name"},
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"supplier_category_code",label:"Select Category",label_top:"Supplier Category (Optional)",
            select:{
                name:'SupplierCategories',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"supplier_categories",
                    name:"Add New Category",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"supplier_categories",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Category", 
                },{ 
                    item_values:"supplier_categories",
                    name:"Manage Categories",
                    link:"/office/people/suppliers/categories",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},    
        
        {type:"text",value:"phone_number",label:"Phone",label_top:"Supplier Phone Number (Optional)",
            roles:{
                field_error:{
                    primary:ITEMS.SUPPLIERS.values,
                    min_size:0,
                    max_size:13,
                }
            },
        required:false,sm:12,md:6,step_name:"Details",autofocus:true},   
        {type:"text",value:"email_address",label:"Email",label_top:"Supplier Email Address (Optional)",required:false,sm:12,md:6,step_name:"Details"},   
        {type:"text",value:"location_address",label:"Location",label_top:"Supplier Address (Optional)",required:false,sm:12,md:12,step_name:"Details"}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},   
    ], 
    supplier_categories:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Supplier Category Name*",
            roles:{
                field_error:{
                    primary:ITEMS.SUPPLIER_CATEGORIES.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    patients:[
        {steps:[
            {name:'Patient'}, 
            {name:'Details'},
            {name:'Next of Kin'},
        ]},
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6,step_name:"Patient"},    
        {type:"date",value:"date_of_birth",label:"Date",label_top:"Date of Birth",required:false,sm:12,md:6,step_name:"Patient"},   
        {type:"text",value:"names",label:"Enter Names",label_top:"Patient Names*",
            select:{
                name:'Patients',
                value:'names',
                text:'names'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            roles:{
                field_error:{
                    // primary:ITEMS.CUSTOMERS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6,autofocus:true,step_name:"Patient"}, 
        {type:"text",value:"surname",label:"Enter Surname",label_top:"Patient Surnames*",
            select:{
                name:'Patients',
                value:'surname',
                text:'surname'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            roles:{
                field_error:{
                    // primary:ITEMS.CUSTOMERS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6,autofocus:true,step_name:"Patient"},     
        {type:"select_gender",value:"gender",label:"Gender",label_top:"Patient Gender",required:false,sm:12,md:12,step_name:"Patient"},   
        
       
        
        {type:"text",value:"next_of_kin_relation",label:"Relation",label_top:"Relationship with Patient",required:false,sm:12,md:12,autofocus:true,step_name:"Next of Kin"}, 
        {type:"text",value:"next_of_kin_names",label:"Names",label_top:"Next of Kin Names (Optional)",required:false,sm:12,md:6,step_name:"Next of Kin"}, 
        {type:"text",value:"next_of_kin_surname",label:"Surname",label_top:"Next of Kin Surname (Optional)",required:false,sm:12,md:6,step_name:"Next of Kin"}, 
        {type:"text",value:"next_of_kin_phone_number",label:"Phone",label_top:"Next of Kin Phone Number (Optional)",required:false,sm:12,md:6,step_name:"Next of Kin"}, 
        {type:"text",value:"next_of_kin_email_address",label:"Email",label_top:"Next of Kin Email Address (Optional)",required:false,sm:12,md:6,step_name:"Next of Kin"}, 
        {type:"text",value:"next_of_kin_address",label:"Location Address",label_top:"Next of Kin Location Address (Optional)",required:false,sm:12,md:12,step_name:"Next of Kin"}, 


        {type:"text",value:"phone_number",label:"Phone",label_top:"Patient Phone Number (Optional)",
            roles:{
                field_error:{
                    primary:ITEMS.CUSTOMERS.values,
                    min_size:null,
                    max_size:50,
                }
            },
        required:false,sm:12,md:6,step_name:"Details",autofocus:true},   
        {type:"text",value:"email_address",label:"Email",label_top:"Patient Email Address (Optional)",required:false,sm:12,md:6,step_name:"Details"},   
        {type:"text",value:"location_address",label:"Location",label_top:"Patient Location Address (Optional)",required:false,sm:12,md:6,step_name:"Details"}, 
        {type:"select_marital_status",value:"marital_status",label:"Marital Status",label_top:"Married or Single*",required:true,sm:12,md:6,step_name:"Details"},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},   
    ],
    
    outlets:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Outlet Name*",
            roles:{
                field_error:{
                    primary:ITEMS.STOCK_OUTLETS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"text",value:"location_address",label:"Location",label_top:"Stock Location Address*",required:true,sm:12,md:6},  
        {type:"autocomplete",value:"outlet_type_code",label:"Select Type",label_top:"Outlet Type",
            select:{
                name:'OutletTypes',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-format-list-bulleted-type'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
             
        required:true,sm:12,md:12,step_name:"Name"}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 

    //Items
    items:[
        {steps:[
            {name:'Name'},
            {name:'Pricing'}, 
            {name:'Details'},
        ]},
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Name*",
        roles:{
            field_error:{
                primary:ITEMS.ITEMS.values,
                min_size:2, 
            }
        },
        required:true,sm:12,md:12,step_name:"Name",autofocus:true},
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: true,
                disabledIfCode:false
            },
            primary:{
                values:ITEMS.ITEMS.values
            },
            roles:{
                field_error:{
                    primary:ITEMS.ITEMS.values,
                    min_size:2,
                    max_size:20,
                }
            }, 
        required:true,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"category_code",label:"Select Category",label_top:"Item Category (Optional)",
            select:{
                name:'ItemCategories',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_categories",
                    name:"Add New Category",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_categories",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Category", 
                },{ 
                    item_values:"item_categories",
                    name:"Manage Categories",
                    link:"/catalog/item-categories",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"brand_code",label:"Select Brand",label_top:"Item Brand (Optional)",
            select:{
                name:'Brands',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"brands",
                    name:"Add New Brand",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"brands",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Brand", 
                },{ 
                    item_values:"brands",
                    name:"Manage Brands",
                    link:"/catalog/brands",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"manufacturer_code",label:"Select Manufacturer",label_top:"Item Manufacturer (Optional)",
            select:{
                name:'Manufacturers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"manufacturers",
                    name:"Add New Manufacture",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"manufacturers",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Manufacture", 
                },{ 
                    item_values:"manufacturers",
                    name:"Manage Manufacturers",
                    link:"/catalog/manufacturers",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},   
        {type:"autocomplete",value:"item_department_code",label:"Select Department",label_top:"Item Department (Optional)",
            select:{
                name:'ItemDepartments',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_departments",
                    name:"Add New Item Department",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_departments",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Manufacture", 
                },{ 
                    item_values:"item_departments",
                    name:"Manage Item Department",
                    link:"/catalog/item-departments",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:12,step_name:"Name"},   

        {type:"money",value:"cost_price",label:"Cost",label_top:"Cost Price*",required:true,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"wholesale_price",label:"Wholesale",label_top:"Wholesale Price*",required:false,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"retail_price",label:"Retail",label_top:"Retail Price*",required:true,sm:12,md:6,step_name:"Pricing"},    
        {type:"autocomplete",value:"tax_group_code",label:"Select Tax Group",label_top:"Item Tax Group *",
            select:{
                name:'TaxGroups',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-hand-coin',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"brands",
                //     name:"Add New Brand",
                //     company_link:true,
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"brands",
                //     action_name:"Add", 
                //     company_link:true,
                //     name:" as New Brand", 
                // },{ 
                //     item_values:"brands",
                //     name:"Manage Brands",
                //     link:"/catalog/brands",
                //     company_link:true,
                //     append:true
                // }
            ],
        required:true,sm:12,md:6,step_name:"Pricing"},   

        {type:"name",value:"pack_name",label:"Pack Name",required:false,sm:12,md:6,step_name:"Details"},  
        {type:"number",value:"quantity_per_pack",label:"Quantity Per Pack",required:false,sm:12,md:6,step_name:"Details"}, 
        // {type:"number",value:"opening_stock",label:"Enter Stock",label_top:"Opening Stock (Optional)",
        //     field_functions:{
        //         visible:(this_)=>{
        //             return this_.update_item?false:true 
        //         }
        //     }
        // ,required:false,sm:12,md:12,step_name:"Details"},
        {type:"add_items",value:"add_opening_stocks",label:"Add Opening Stock",label_top:"Stock Outlet(s)*",
            field_functions:{
                visible:(this_)=>{
                    return this_.update_item?false:true 
                }
            },
            select:{
                name:'Outlets',
                value:'stock_outlets',
                value_key:'outlet_code',
                text:'name'
            },
            fields:[
                {type:"autocomplete",value:"outlet_code",name:"Outlet Name",label:"Select Outlet",label_top:"Outlet*", 
                    select:{
                        name:'Outlets',
                        value:'code',
                        text:'name'
                    },
                    search:{
                        filter:(item, queryText, itemText) => { 
                            let index = (a,b)=>{
                                if(!a||!b){return false}
                                a = ""+a; b = ""+b
                                return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                            } 
                            let first = index(itemText,queryText)
                            let second = item?index(item.code,queryText):false
                            let third = item?index(item.item_category_name,queryText):false
                            if(!item){return first} 
                            return first || second || third
                        },
                        item_icon:{
                            name:'mdi-sitemap',
                            tile:true
                        },
                        item_title:['name'],
                        item_subtitle:['code']
                    },
                    action:[
                        { 
                            item_values:"outlets",
                            name:"Manage Outlet",
                            link:"/stock/outlets",
                            company_link:true,
                            append:true
                        }, 
                    ],
                required:true,sm:12,md:6},
                {type:"money",value:"stock",name:"Stock",label:"Enter Stock",label_top:"Stock Quantity",append_icon:"qty",required:true,sm:12,md:6}, 
            ],
        
        required:false,sm:12,md:12,step_name:"Details"}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},    
    ], 
    items_kit:[
        {steps:[
            {name:'Name'},
            {name:'Kit'},
            {name:'Pricing'}, 
            {name:'Details'},
        ]},
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Name*",
        roles:{
            field_error:{
                primary:ITEMS.ITEMS.values,
                min_size:2, 
            }
        },
        required:true,sm:12,md:12,step_name:"Name",autofocus:true},
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: true,
                disabledIfCode:false
            },
            primary:{
                values:ITEMS.ITEMS.values
            },
            roles:{
                field_error:{
                    primary:ITEMS.ITEMS.values,
                    min_size:2,
                    max_size:20,
                }
            }, 
        required:true,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"category_code",label:"Select Category",label_top:"Item Category (Optional)",
            select:{
                name:'ItemCategories',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_categories",
                    name:"Add New Category",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_categories",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Category", 
                },{ 
                    item_values:"item_categories",
                    name:"Manage Categories",
                    link:"/catalog/item-categories",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"brand_code",label:"Select Brand",label_top:"Item Brand (Optional)",
            select:{
                name:'Brands',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"brands",
                    name:"Add New Brand",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"brands",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Brand", 
                },{ 
                    item_values:"brands",
                    name:"Manage Brands",
                    link:"/catalog/brands",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},  
        {type:"autocomplete",value:"manufacturer_code",label:"Select Manufacturer",label_top:"Item Manufacturer (Optional)",
            select:{
                name:'Manufacturers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"manufacturers",
                    name:"Add New Manufacture",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"manufacturers",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Manufacture", 
                },{ 
                    item_values:"manufacturers",
                    name:"Manage Manufacturers",
                    link:"/catalog/manufacturers",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,step_name:"Name"},   

        {type:"add_items",value:"item_kits",label:"Add Kit",label_top:"Add Item(s)*",
            select:{
                name:'Items',
                value:'items',
                value_key:'item_key',
                text:'name'
            },
            fields:[
                {type:"autocomplete",value:"item_key",name:"Item Name",label:"Select Item",label_top:"Item*", 
                    select:{
                        name:'Items',
                        value:'key',
                        text:'name'
                    },
                    search:{
                        filter:(item, queryText, itemText) => { 
                            let index = (a,b)=>{
                                if(!a||!b){return false}
                                a = ""+a; b = ""+b
                                return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                            } 
                            let first = index(itemText,queryText)
                            let second = item?index(item.code,queryText):false
                            let third = item?index(item.item_category_name,queryText):false
                            if(!item){return first} 
                            return first || second || third
                        },
                        item_icon:{
                            name:'mdi-tag',
                            tile:true
                        },
                        item_title:['name'],
                        item_subtitle:['category_name','code']
                    },
                    action:[
                        { 
                            item_values:"items",
                            name:"Create Item",
                            link:"/stock/item",
                            company_link:true,
                            append:true
                        }, 
                    ],
                required:true,sm:12,md:6},
                {type:"money",value:"item_kit_quantity",name:"Quantity",label:"Enter Quantity",label_top:"Item Quantity",append_icon:"qty",required:true,sm:12,md:6}, 
            ],
        
        required:true,sm:12,md:12,step_name:"kit"}, 

        {type:"money",value:"cost_price",label:"Cost",label_top:"Cost Price*",required:true,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"wholesale_price",label:"Wholesale",label_top:"Wholesale Price*",required:true,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"retail_price",label:"Retail",label_top:"Retail Price*",required:true,sm:12,md:6,step_name:"Pricing"},    
        {type:"autocomplete",value:"tax_group_code",label:"Select Tax Group",label_top:"Item Tax Group *",
            select:{
                name:'TaxGroups',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-hand-coin',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"brands",
                //     name:"Add New Brand",
                //     company_link:true,
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"brands",
                //     action_name:"Add", 
                //     company_link:true,
                //     name:" as New Brand", 
                // },{ 
                //     item_values:"brands",
                //     name:"Manage Brands",
                //     link:"/catalog/brands",
                //     company_link:true,
                //     append:true
                // }
            ],
        required:true,sm:12,md:6,step_name:"Pricing"},   

        {type:"name",value:"pack_name",label:"Pack Name",required:false,sm:12,md:6,step_name:"Details"},  
        {type:"number",value:"quantity_per_pack",label:"Quantity Per Pack",required:false,sm:12,md:6,step_name:"Details"},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},    
    ],
    Add_items_kit:[
        {steps:[ 
            {name:'Kit'},
            {name:'Pricing'},  
        ]},
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: true,
                disabledIfCode:false
            },
            primary:{
                values:ITEMS.ITEMS.values
            },
            roles:{
                field_error:{
                    primary:ITEMS.ITEMS.values,
                    min_size:2,
                    max_size:20,
                }
            }, 
        disabled:true,sm:12,md:4,step_name:"kit"},  
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Name*",
        roles:{
            field_error:{
                primary:ITEMS.ITEMS.values,
                min_size:2, 
            }
        },
        required:true,sm:12,md:8,step_name:"kit",autofocus:true}, 

        {type:"add_items",value:"item_kits",label:"Add Kit",label_top:"Add Item(s)*",
            select:{
                name:'Items',
                value:'items',
                value_key:'item_key',
                text:'name'
            },
            fields:[
                {type:"autocomplete",value:"item_key",name:"Item Name",label:"Select Item",label_top:"Item*", 
                    select:{
                        name:'Items',
                        value:'key',
                        text:'name'
                    },
                    search:{
                        filter:(item, queryText, itemText) => { 
                            let index = (a,b)=>{
                                if(!a||!b){return false}
                                a = ""+a; b = ""+b
                                return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                            } 
                            let first = index(itemText,queryText)
                            let second = item?index(item.code,queryText):false
                            let third = item?index(item.item_category_name,queryText):false
                            if(!item){return first} 
                            return first || second || third
                        },
                        item_icon:{
                            name:'mdi-tag',
                            tile:true
                        },
                        item_title:['name'],
                        item_subtitle:['category_name','code']
                    },
                    action:[
                        { 
                            item_values:"items",
                            name:"Create Item",
                            link:"/stock/item",
                            company_link:true,
                            append:true
                        }, 
                    ],
                required:true,sm:12,md:6},
                {type:"money",value:"item_kit_quantity",name:"Quantity",label:"Enter Quantity",label_top:"Item Quantity",append_icon:"qty",required:true,sm:12,md:6}, 
            ],
        
        required:true,sm:12,md:12,step_name:"kit"}, 

        {type:"money",value:"cost_price",label:"Cost",label_top:"Cost Price*",required:true,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"wholesale_price",label:"Wholesale",label_top:"Wholesale Price*",required:false,sm:12,md:6,step_name:"Pricing"},  
        {type:"money",value:"retail_price",label:"Retail",label_top:"Retail Price*",required:true,sm:12,md:6,step_name:"Pricing"},    
        {type:"autocomplete",value:"tax_group_code",label:"Select Tax Group",label_top:"Item Tax Group *",
            select:{
                name:'TaxGroups',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-hand-coin',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"brands",
                //     name:"Add New Brand",
                //     company_link:true,
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"brands",
                //     action_name:"Add", 
                //     company_link:true,
                //     name:" as New Brand", 
                // },{ 
                //     item_values:"brands",
                //     name:"Manage Brands",
                //     link:"/catalog/brands",
                //     company_link:true,
                //     append:true
                // }
            ],
        required:true,sm:12,md:6,step_name:"Pricing"},   

        {type:"name",value:"pack_name",label:"Pack Name",required:false,sm:12,md:6,step_name:"Details"},  
        {type:"number",value:"quantity_per_pack",label:"Quantity Per Pack",required:false,sm:12,md:6,step_name:"Details"},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12,step_name:"Details"},    
    ], 
    edit_item_price:[ 
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: true,
                disabledIfCode:false
            },
            primary:{
                values:ITEMS.ITEMS.values
            },
            roles:{
                field_error:{
                    primary:ITEMS.ITEMS.values,
                    min_size:2,
                    max_size:20,
                }
            }, 
        disabled:true,sm:12,md:6,step_name:"kit"},  
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Name*",
        disabled:true,sm:12,md:6,step_name:"kit"},
        {type:"money",value:"cost_price",label:"Cost",label_top:"Cost Price*",autofocus:true,
            field_functions:{ 
                data_change:(this_,input,field)=>{
                    let new_input = {}
                    if (this_ && input && field) {
                        let cost_price = this_.toNumber(input.cost_price)
                        let retail_price = this_.toNumber(input.retail_price)
                        let wholesale_price = this_.toNumber(input.wholesale_price)
                        let retail_profit_margin = 30
                        let wholesale_profit_margin = 30
                        let retail_percentage = cost_price*retail_profit_margin
                        let wholesale_percentage = cost_price*retail_profit_margin
                        
                        input.retail_price = (cost_price+retail_percentage)
                        input.wholesale_price = (cost_price+wholesale_percentage )
                        
                        new_input.retail_price = ''+(cost_price+retail_percentage)
                        new_input.wholesale_price = ''+(cost_price+wholesale_percentage ) 
                    }
                    return{
                        input:input,
                        new_input:new_input,
                        field:field,
                        field_value:field?field.value:null,
                    }
                }
            }
        ,required:true,sm:12,md:12},  
        {type:"money",value:"retail_price",label:"Retail",label_top:"Retail Price*",
            field_functions:{
                disabled:(input)=>!input.cost_price
            }
        ,required:true,sm:12,md:6}, 
        {type:"money",value:"wholesale_price",label:"Wholesale",label_top:"Wholesale Price*",
            field_functions:{
                disabled:(input)=>!input.cost_price,
                data_change:(this_,input,field)=>{
                    if (this_,input,field) {
                        let cost_price = this_.toNumber(input.cost_price)
                        let retail_price = this_.toNumber(input.retail_price)
                        let wholesale_price = this_.toNumber(input.wholesale_price)
                        let retail_profit_margin = 30
                        let wholesale_profit_margin = 30
                        let retail_percentage = cost_price*retail_profit_margin
                        let wholesale_percentage = cost_price*retail_profit_margin
                        input.retail_price = cost_price+retail_percentage
                        input.wholesale_percentage = cost_price+wholesale_percentage 
                        console.log(cost_price,'c....');
                    }
                    return{
                        input:input,
                        field:field,
                        field_value:field?field.value:null,
                    }
                }
            }
        ,required:false,sm:12,md:6},  
           
          
    ],
    edit_items_department_and_category:[ 
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: true,
                disabledIfCode:false
            },
            primary:{
                values:ITEMS.ITEMS.values
            },
            roles:{
                field_error:{
                    primary:ITEMS.ITEMS.values,
                    min_size:2,
                    max_size:20,
                }
            }, 
        disabled:true,sm:12,md:6,step_name:"kit"},  
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Name*",
        disabled:true,sm:12,md:6,step_name:"kit"},
        {type:"autocomplete",value:"category_code",label:"Select Category",label_top:"Item Category (Optional)",
            select:{
                name:'ItemCategories',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_categories",
                    name:"Add New Category",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_categories",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Category", 
                },{ 
                    item_values:"item_categories",
                    name:"Manage Categories",
                    link:"/catalog/item-categories",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6,autofocus:false},   
        {type:"autocomplete",value:"item_department_code",label:"Select Department",label_top:"Item Department (Optional)",
            select:{
                name:'ItemDepartments',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_departments",
                    name:"Add New Item Department",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_departments",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Manufacture", 
                },{ 
                    item_values:"item_departments",
                    name:"Manage Item Department",
                    link:"/catalog/item-departments",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},  
    ], 
    item_categories:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Category Name*",
            roles:{
                field_error:{
                    primary:ITEMS.ITEM_CATEGORIES.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"item_department_code",label:"Select Department",label_top:"Item Department (Optional)",
            select:{
                name:'ItemDepartments',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-format-list-group',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_departments",
                    name:"Add New Department",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_departments",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Department", 
                },{ 
                    item_values:"item_departments",
                    name:"Manage Departments",
                    link:"/catalog/item-departments",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:12,autofocus:false},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    item_departments:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Item Department Name*",
            roles:{
                field_error:{
                    primary:ITEMS.ITEM_DEPARTMENTS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    item_order_options:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Order Option Name*",
            roles:{
                field_error:{
                    primary:ITEMS.ITEM_ORDER_OPTIONS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Outlet*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                // { 
                //     item_values:"stock_outlets",
                //     name:"Manage Outlets",
                //     link:"/stock/outlets",
                //     company_link:true,
                //     append:true
                // }
            ],
        required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    put_on_order_options:[     
        {type:"autocomplete",value:"customer_key",label:"",label_top:"Attach Customer*",
            select:{
                name:'Customers',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.phone_number,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['phone_number']
            },
            action:[
                {
                    icon:"person_add",
                    action:"add_item",
                    item_values:"customers",
                    name:"Add New Customer",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"customers",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Customer", 
                },{ 
                    item_values:"supplier_categories",
                    name:"Manage Customer",
                    link:"/office/people/customers",
                    company_link:true,
                    append:true
                } 
            ],
        required:true,sm:12,md:6,prepend_inner_icon:"mdi-account"},  
        {type:"time",onstart:'today',value:"estimated_time",label:"",label_top:"Estimated Time",required:true,sm:12,md:6,prepend_inner_icon:"mdi-clipboard-text-clock",append_icon:"mdi-timer-edit"}, 
        {type:"textarea",value:"description",label:"",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    brands:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true, 
            },
            primary:{
                values:ITEMS.BRANDS.values
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Brand Name*",
            roles:{
                field_error:{
                    primary:ITEMS.BRANDS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    manufacturers:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        {type:"text",value:"name",label:"Enter Name",label_top:"Manufacturer Name*",
            roles:{
                field_error:{
                    primary:ITEMS.MANUFACTURERS.values,
                    min_size:2,
                    max_size:50,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    expiries:[
        {type:"text",value:"code",label:"Code",label_top:"Item Barcode*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                updatable: false,
                disabledIfCode:false
            },
        required:true,sm:12,md:12},  
        {type:"date",onstart:'today',value:"manufacturing_date",label:"Enter Mfg. Date",label_top:"Item Manufacturing Date*",required:true,sm:12,md:6},
        {type:"date",onstart:'tomorrow',value:"expiring_date",label:"Enter Exp. Date",label_top:"Item Expiring Date*",required:true,sm:12,md:6},
        {type:"autocomplete",value:"item_code",label:"Select Item",label_top:"Expiring Item",
            select:{
                name:'Items',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-label',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"item_categories",
                    name:"Add New Category",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"item_categories",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Category", 
                },{ 
                    item_values:"item_categories",
                    name:"Manage Categories",
                    link:"/catalog/item-categories",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},
        {type:"money",value:"quantity",label:"Enter Quantity",label_top:"Expiring Item Quantity*",required:true,sm:12,md:6},
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],

    //orders 
    order_stock:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"quotation_code",label:"Select Quotation",label_top:"Quotation Number (Optional)",
            select:{
                name:'Quotations',
                value:'code',
                text:'quotation_number'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-check-outline',
                    tile:true
                },
                item_title:['quotation_number'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"quotation",
                    name:"Request for Quotation",
                    link:"/stock/quotations&invoices/request-quotation",
                    company_link:true,
                    append:true
                },{ 
                    item_values:"quotation",
                    name:"Add Received Quotation",
                    link:"/stock/quotations&invoices/receive-quotation",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Deliver to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     company_link:true,
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                //     company_link:true,
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date(Optional)",required:false,sm:12,md:6},  
        // {type:"autocomplete",value:"payment_method",label:"Select",label_top:"Payment Methods*",select:{
        //     name:'PaymentMethods',
        //     value:'code',
        //     text:'name'
        // },required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    receive_stock:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        auto_code:{
            values:CODE.system,  
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"invoice_code",label:"Select Invoice",label_top:"Invoice Number (Optional)",
            select:{
                name:'Invoices',
                value:'code',
                text:'invoice_number'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-certificate-outline',
                    tile:true
                },
                item_title:['invoice_number'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"invoice",
                    name:"Add Received Invoice",
                    link:"/stock/quotations&invoices/receive-invoice",
                    company_link:true,
                    append:true
                }, 
                { 
                    item_values:"invoice",
                    name:"Manage Invoices",
                    link:"/stock/quotations&invoices",
                    company_link:true,
                    append:true
                }, 
            ],
        required:false,sm:12,md:6},
         
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"deliver_to_outlet_code",label:"Select Outlet",label_top:"Deliver to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},  
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date (Optional)",required:false,sm:12,md:6},   
        {type:"autocomplete",value:"payment_method_code",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    opening_stock:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        auto_code:{
            values:CODE.system,  
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Stock Outlet*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"date",onstart:'today',label:"Date",label_top:"Date (Optional)",required:false,sm:12,md:6}, 
       
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    return_stock:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"order_code",label:"Select Stock Order Number",label_top:"Received Stock*", 
            select:{
                name:'ReceivedOrders',
                value:'code',
                text:'code'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-certificate-outline',
                    tile:true
                },
                item_title:['code'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"invoice",
                    name:"Add Received Stock",
                    link:"/stock/stock-control/receive-stock",
                    company_link:true,
                    append:true
                }, 
            ],
        required:true,sm:12,md:6},
         
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Delivered to*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"return_date",onstart:'today',label:"Date",label_top:"Return Date (Optional)",required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_code",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    receive_stock_with_invoice:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"order_code",label:"Select Order",label_top:"Stock Order Number (Optional)",
            select:{
                name:'OrderedStock',
                value:'code',
                text:'code'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.supplier_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-check-outline',
                    tile:true
                },
                item_title:['code'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"stock_order",
                    name:"Order Stock",
                    link:"/stock/stock-control/order-stock",
                    company_link:true,
                    append:true
                },{ 
                    item_values:"stock_order",
                    name:"Manage Stock",
                    link:"/stock/stock-control",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},
        {type:"text",value:"invoice_number",label:"Enter Invoice",label_top:"Invoice Number*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"autocomplete",value:"deliver_to_outlet_code",label:"Select Outlet",label_top:"Deliver to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date (Optional)",required:false,sm:12,md:6}, 
        {type:"date", value:"bill_date",label:"Enter Date",label_top:"Bill Date*",onstart:'today',required:true,sm:12,md:6},  
        {type:"autocomplete",value:"bill_terms",label:"Terms",label_top:"Bill Terms",
        select:{
            name:'BillTerms',
            value:'code',
            text:'name'
        },
        required:true,sm:12,md:6},   
        {type:"date", value:"bill_due_date",label:"Enter Date",label_top:"Bill Due Date*",onstart:'tomorrow',required:true,sm:12,md:6},  
        // {type:"autocomplete",value:"payment_method",label:"Select",label_top:"Payment Methods*",select:{
        //     name:'PaymentMethods',
        //     value:'code',
        //     text:'name'
        // },required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    transfer_stock:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*", 
            auto_code:{
                values:CODE.system,  
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"order_code",label:"Select Order Number",label_top:"Received Stock (Optional)", 
            select:{
                name:'ReceivedOrders',
                value:'code',
                text:'code'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-certificate-outline',
                    tile:true
                },
                item_title:['code'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"invoice",
                    name:"Add Received Stock",
                    link:"/stock/stock-control/receive-stock",
                    company_link:true,
                    append:true
                }, 
            ],
        required:false,sm:12,md:6},
         
        // {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
        //     select:{
        //         name:'Suppliers',
        //         value:'code',
        //         text:'name'
        //     },
        //     action:[
        //         {
        //             icon:"add",
        //             action:"add_item",
        //             item_values:"suppliers",
        //             name:"Add New Supplier",
        //             company_link:true,
        //             append:true
        //         },{ 
        //             action:"add_item",
        //             item_values:"suppliers",
        //             action_name:"Add", 
        //             name:" as New Supplier", 
        //             company_link:true,
        //         },{ 
        //             item_values:"suppliers",
        //             name:"Manage Suppliers",
        //             link:"/office/people/suppliers",
        //             company_link:true,
        //             append:true
        //         }
        //     ],
        // required:true,sm:12,md:6},  
        {type:"autocomplete",value:"transferred_outlet_code",label:"Outlet",label_top:"Transfer From*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            // action:[
            //     {
            //         icon:"add",
            //         action:"add_item",
            //         item_values:"stock_outlets",
            //         name:"Add New Outlet",
            //         append:true
            //     },{ 
            //         action:"add_item",
            //         item_values:"stock_outlets",
            //         action_name:"Add", 
            //         name:" as New Outlet", 
            //     },{ 
            //         item_values:"stock_outlets",
            //         name:"Manage Outlets",
            //         link:"/stock/outlets",
            //         company_link:true,
            //         append:true
            //     }
            // ],
        required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Transfer To*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            // action:[
            //     // {
            //     //     icon:"add",
            //     //     action:"add_item",
            //     //     item_values:"stock_outlets",
            //     //     name:"Add New Outlet",
            //     //     append:true
            //     // },{ 
            //     //     action:"add_item",
            //     //     item_values:"stock_outlets",
            //     //     action_name:"Add", 
            //     //     name:" as New Outlet", 
            //     // },
            //     { 
            //         item_values:"stock_outlets",
            //         name:"Manage Outlets",
            //         link:"/stock/outlets",
            //         company_link:true,
            //         append:true
            //     }
            // ],
        required:true,sm:12,md:6},    
        {type:"date", value:"transferred_date",onstart:'today',label:"Date",label_top:"Transferred Date (Optional)",required:false,sm:12,md:6}, 
        // {type:"autocomplete",value:"payment_method",label:"Select",label_top:"Payment Methods*",select:{
        //     name:'PaymentMethods',
        //     value:'code',
        //     text:'name'
        // },required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    adjust_stock:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*", 
            auto_code:{
                values:CODE.system,  
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Outlet to be Adjusted*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},     
        {type:"date", value:"adjusted_date",onstart:'today',label:"Date",label_top:"Adjusted Date (Optional)",required:false,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    opening_balance:[
        {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        auto_code:{
            values:CODE.system,  
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},      
        {type:"money", value:"amount",onstart:'today',label:"Enter Amount",label_top:"Opening Balance Amount*",required:false,sm:12,md:6}, 
        {type:"date", value:"date",onstart:'today',label:"Enter Date",label_top:"Opening Balance Date*",required:false,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    
    //
    request_quotation:[
        {type:"",value:"code",label:"code",label_top:"RFQ Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    receive_quotation:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"rfq_code",label:"Select RFQ",label_top:"Request for Quotation (Optional)",
            select:{
                name:'RequestForQuotations',
                value:'code',
                text:'code'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-clock-outline',
                    tile:true
                },
                item_title:['code'],
                item_subtitle:['supplier_name',]
            },
            action:[
                { 
                    item_values:"quotation",
                    name:"Request for Quotation",
                    link:"/stock/quotations&invoices/request-quotation",
                    company_link:true,
                    append:true
                },{ 
                    item_values:"quotation",
                    name:"Request for Quotation",
                    link:"/stock/quotations&invoices/request-quotation",
                    company_link:true,
                    append:false
                }
            ],
        required:false,sm:12,md:6},
        {type:"test",value:"quotation_number",label:"quotation",label_top:"Quotation Number*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"date",onstart:'tomorrow',value:"expire_date",label:"Expire Date",label_top:"Quotation Expire Date (Optional)",required:true,sm:12,md:6},  
        
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    receive_invoice:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"order_code",label:"Select Order",label_top:"Stock Order Number (Optional)",
            select:{
                name:'OrderedStock',
                value:'code',
                text:'code'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.supplier_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-file-check-outline',
                    tile:true
                },
                item_title:['code'],
                item_subtitle:['supplier_name','total_price_']
            },
            action:[
                { 
                    item_values:"stock_order",
                    name:"Order Stock",
                    link:"/stock/stock-control/order-stock",
                    company_link:true,
                    append:true
                },{ 
                    item_values:"stock_order",
                    name:"Manage Stock",
                    link:"/stock/stock-control",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},
        {type:"text",value:"invoice_number",label:"Enter Invoice",label_top:"Invoice Number*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"autocomplete",value:"deliver_to_outlet_code",label:"Select Outlet",label_top:"Deliver to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date (Optional)",required:false,sm:12,md:6}, 
        {type:"date", value:"bill_date",label:"Enter Date",label_top:"Bill Date*",onstart:'today',required:true,sm:12,md:6},  
        {type:"autocomplete",value:"bill_terms",label:"Terms",label_top:"Bill Terms",
        select:{
            name:'BillTerms',
            value:'code',
            text:'name'
        },
        required:true,sm:12,md:6},   
        {type:"date", value:"bill_due_date",label:"Enter Date",label_top:"Bill Due Date*",onstart:'tomorrow',required:true,sm:12,md:6},  
        // {type:"autocomplete",value:"payment_method",label:"Select",label_top:"Payment Methods*",select:{
        //     name:'PaymentMethods',
        //     value:'code',
        //     text:'name'
        // },required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],

    //warehouse
    open_warehouse:[  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Outlet *", 
            select:{
                name:'WarehouseOutlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.operating_date,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-warehouse',
                    tile:true
                },
                item_title:['name'],
                // 'outlet_name',
                item_subtitle:['location_address','code',]
            }, 
        required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    warehouse_receive_stock:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},  
        {type:"text",value:"invoice_number",label:"Enter Invoice",label_top:"Invoice Number*",required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"from_supplier_key",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"autocomplete",value:"to_outlet_code",label:"Select Outlet",label_top:"Deliver to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-store',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,disabled:true,sm:12,md:6},    
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date (Optional)",required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_key",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},   
        {type:"autocomplete",value:"bill_terms",label:"Terms",label_top:"Bill Terms",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let payment_method_key =input?input.payment_method_key:null
                    return payment_method_key =="bill"?true:false 
                }
            },
            select:{
                name:'BillTerms',
                value:'code',
                text:'name'
            },
        required:false,sm:12,md:6},   
        {type:"date", value:"bill_due_date",label:"Enter Date",label_top:"Bill Due Date*",onstart:'tomorrow',
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let payment_method_key =input?input.payment_method_key:null
                    return payment_method_key =="bill"?true:false 
                }
            },
        required:false,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    warehouse_transfer_stock:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},  
        {type:"autocomplete",value:"from_outlet_code",label:"Select Outlet",label_top:"Transfer From*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-store',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,disabled:true,sm:12,md:6}, 
        {type:"autocomplete",value:"transfer_option_key",label:"Select",label_top:"Transfer Option*",
            
            select:{
                name:'StockTransferOptions',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-human-dolly',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,disabled:false,sm:12,md:6},  
        {type:"autocomplete",value:"to_outlet_code",label:"Select Outlet",label_top:"Transfer to Outlet*",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let transfer_option_key =input?input.transfer_option_key:null
                    return transfer_option_key =="to_outlet"?true:false 
                }
            },
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-store',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,disabled:false,sm:12,md:6},  
        {type:"autocomplete",value:"to_supplier_key",label:"Select Supplier",label_top:"Supplier*",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let transfer_option_key =input?input.transfer_option_key:null
                    return transfer_option_key =="to_supplier"?true:false 
                }
            },
            select:{
                name:'Suppliers',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},     
        {type:"date", value:"transferring_date",onstart:'today',label:"Date",label_top:"Transferring Date (Optional)",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let transfer_option_key =input?input.transfer_option_key:null
                    return transfer_option_key?true:false 
                }
            }
        ,required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_key",label:"Select",label_top:"Payment Methods*",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let transfer_option_key =input?input.transfer_option_key:null
                    return transfer_option_key == "to_supplier"?true:false 
                }
            },
            select:{
                name:'PaymentMethods',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},   
        {type:"autocomplete",value:"bill_terms",label:"Terms",label_top:"Bill Terms",
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let payment_method_key =input?input.payment_method_key:null
                    return payment_method_key =="bill"?true:false 
                }
            },
            select:{
                name:'BillTerms',
                value:'code',
                text:'name'
            },
        required:false,sm:12,md:6},   
        {type:"date", value:"bill_due_date",label:"Enter Date",label_top:"Bill Due Date*",onstart:'tomorrow',
            field_functions:{
                visible:(this_)=>{
                    let input =this_?this_.input:null
                    let payment_method_key =input?input.payment_method_key:null
                    return payment_method_key =="bill"?true:false 
                }
            },
        required:false,sm:12,md:6},    
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    warehouse_send_quotation:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},   
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"date",onstart:'today',label:"Date",label_top:"Quotation Date (Optional)",required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_code",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    warehouse_request_quotation:[
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},   
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"delivery_date",onstart:'today',label:"Delivery Date",label_top:"Delivery Date (Optional)",required:false,sm:12,md:6}, 
        {type:"autocomplete",value:"transfer_to_outlet_code",label:"Select Outlet",label_top:"Transfer to*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-store',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_code",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 

    //SALES
    start_day:[
        // {type:"",value:"code",label:"code",label_top:"Order Number (Tracking Number)*",
        // auto_code:{
        //     values:CODE.system,  
        //     onstart:true,
        //     disabledIfCode:true
        // },required:true,sm:12,md:12},  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Selling Outlet*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"operating_date",onstart:'today',label:"Operating Date",label_top:"Business Day*",required:true,sm:12,md:6},   
        {type:"autocomplete",value:"cashup_option",label:"Select Option",label_top:"Cashup Option*",
            select:{
                name:'CashupOptions',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['description',]
            }, 
        required:true,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    sales_return:[
        {type:"",value:"code",label:"code",label_top:"Tracking Code*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12}, 
        {type:"autocomplete",value:"sale_order_key",label:"Select Receipt *", 
            select:{
                name:'SaleOrders',
                value:'key',
                text:'key'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['key'],
                item_subtitle:['customer_name','outlet_name']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"item_categories",
                //     name:"Add New Category",
                //     company_link:true,
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"item_categories",
                //     action_name:"Add", 
                //     company_link:true,
                //     name:" as New Category", 
                // },{ 
                //     item_values:"item_categories",
                //     name:"Manage Categories",
                //     link:"/catalog/item-categories",
                //     company_link:true,
                //     append:true
                // }
            ],
        required:true,sm:12,md:6},   
        {type:"text",value:"customer_name",label:"Customer Name",required:false,sm:12,md:6},  
        {type:"text",value:"teller_name",label:"Teller Name",required:false,sm:12,md:6}, 
        {type:"text",value:"till_name",label:"Till Name",required:false,sm:12,md:6},  
        {type:"text",value:"outlet_name",label:"Outlet Name",required:false,sm:12,md:6},  
        {type:"date",value:"return_date",onstart:'today',label:"Return Date",required:false,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    tills:[ 
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"text",value:"name",label:"Enter Name",label_top:"Till Number / Name*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Till location*", 
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"autocomplete",value:"computer_code",label:"Select Computer",label_top:"Add POS Computer (Optional)", 
            select:{
                name:'Computers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-devices',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['computer_usage_option_name',]
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"computers",
                    name:"Add New Computer",
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"computers",
                    action_name:"Add", 
                    name:" as New Computer", 
                },
                { 
                    item_values:"computers",
                    name:"Computer Settings",
                    link:"/settings/device/computer",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},  
        
        {type:"autocomplete",value:"vfd_display_code",label:"Select VFD",label_top:"Add a VFD Display (Optional)", 
            select:{
                name:'VFD_Displays',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"vfd_displays",
                    name:"Add New VFD Display",
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"vfd_displays",
                    action_name:"Add", 
                    name:" as New VFD Display", 
                },
                { 
                    item_values:"vfd_displays",
                    name:"VFD Display Settings",
                    link:"/settings/device/vfd_displays",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6}, 

        {type:"checkbox",value:"allow_Cashier_to_change_department",label:"Allow Changing Department",label_top:"Cashier Can Change Department", required:false,sm:12,md:6},  
        {type:"checkbox",value:"allow_multiple_departments",label:"Allow Multiple Departments",label_top:"Allow Choosing Departments", required:false,sm:12,md:6}, 
        {type:"autocomplete",multiple:(input)=>{ 
            if(!input){return false}
            return input.allow_multiple_departments?true:false
        },value:"selected_department_codes",label:"Select Department",label_top:"Add Default Department (Optional)", 
            select:{
                name:'ItemDepartments',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code','']
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"printers",
                //     name:"Add New Printer",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"printers",
                //     action_name:"Add", 
                //     name:" as New Printer", 
                // },
                { 
                    item_values:"item_department",
                    name:"Manage Departments",
                    link:"/stock/departments",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:12},  
        
         
        // {type:"autocomplete",multiple:(input)=>{ 
        //     if(!input){return false}
        //     return input.allow_multiple_printers?true:false
        // },value:"printer_code",label:"Select Printer",label_top:"Add Default printer (Optional)", 
        //     select:{
        //         name:'Printers',
        //         value:'code',
        //         text:'name'
        //     },
        //     search:{
        //         filter:(item, queryText, itemText) => { 
        //             let index = (a,b)=>{
        //                 if(!a||!b){return false}
        //                 a = ""+a; b = ""+b
        //                 return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
        //             } 
        //             let first = index(itemText,queryText)
        //             let second = item?index(item.code,queryText):false
        //             if(!item){return first} 
        //             return first || second 
        //         },
        //         item_icon:{
        //             name:'',
        //             tile:true
        //         },
        //         item_title:['name'],
        //         item_subtitle:['code','brand_name']
        //     },
        //     action:[
        //         {
        //             icon:"add",
        //             action:"add_item",
        //             item_values:"printers",
        //             name:"Add New Printer",
        //             append:true
        //         },{ 
        //             action:"add_item",
        //             item_values:"printers",
        //             action_name:"Add", 
        //             name:" as New Printer", 
        //         },
        //         { 
        //             item_values:"printers",
        //             name:"Printer Settings",
        //             link:"/settings/device/printers",
        //             company_link:true,
        //             append:true
        //         }
        //     ],
        // required:false,sm:12,md:12},  

        {type:"add_items",value:"assigned_printers",label:"Add Printer",label_top:"Add Till Default Printer(s)*",
            select:{
                name:'Printers',
                value:'printers',
                value_key:'printer_code',
                text:'name'
            },
            fields:[
                {type:"autocomplete",value:"printer_code",name:"Printer Name",label:"Select Printer",label_top:"Printer Name*", 
                    select:{
                        name:'Printers',
                        value:'code',
                        text:'name'
                    },
                    search:{
                        filter:(item, queryText, itemText) => { 
                            let index = (a,b)=>{
                                if(!a||!b){return false}
                                a = ""+a; b = ""+b
                                return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                            } 
                            let first = index(itemText,queryText)
                            let second = item?index(item.code,queryText):false
                            let third = item?index(item.item_category_name,queryText):false
                            if(!item){return first} 
                            return first || second || third
                        },
                        item_icon:{
                            name:'mdi-printer',
                            tile:true
                        },
                        item_title:['name'],
                        item_subtitle:['brand_name','code']
                    },
                    action:[
                        { 
                            item_values:"items",
                            name:"Create Item",
                            link:"/stock/item",
                            company_link:true,
                            append:true
                        }, 
                    ],
                required:true,sm:12,md:6},
                {type:"autocomplete",value:"printer_option",name:"Printer Option",label:"Select Option",label_top:"Printer Option*", 
                    select:{
                        name:'PrinterOptions',
                        value:'code',
                        text:'printer_option',
                        // text2:'printer_option',
                    },
                    search:{
                        filter:(item, queryText, itemText) => { 
                            let index = (a,b)=>{
                                if(!a||!b){return false}
                                a = ""+a; b = ""+b
                                return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                            } 
                            let first = index(itemText,queryText)
                            let second = item?index(item.code,queryText):false
                            let third = item?index(item.item_category_name,queryText):false
                            if(!item){return first} 
                            return first || second || third
                        },
                        item_icon:{
                            // name:'mdi-tag',
                            tile:true
                        },
                        item_title:['name'],
                        item_subtitle:['brand_name','code']
                    },
                    // action:[
                    //     { 
                    //         item_values:"items",
                    //         name:"Create Item",
                    //         link:"/stock/item",
                    //         company_link:true,
                    //         append:true
                    //     }, 
                    // ],
                required:true,sm:12,md:6},
                {type:"money",value:"printer_priority",name:"Priority",label:"Enter Quantity",label_top:"Printer Priority",append_icon:"qty",required:true,sm:12,md:6}, 
                {type:"yes_or_no",value:"connect_cash_drawer",name:"Cash Drawer",label:"Cash Drawer",label_top:"Connect Cash Drawer",append_icon:"qty",required:true,sm:12,md:6}, 
            ],
        
        required:true,sm:12,md:12,step_name:"kit"},
        {type:"checkbox",value:"allow_sell_without_printer",label:"Allow Sale Without Printer",label_top:"Always Sale", required:false,sm:12,md:6},  
        {type:"checkbox",value:"allow_choosing_printer",label:"Allow Choosing Printer",label_top:"Select & Change Printer", required:false,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    computers:[ 
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:6}, 
        {type:"text",value:"name",label:"Enter Name",label_top:"Computer Name*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"computer_type_code",label:"Select Type",label_top:"Computer Type*", 
            select:{
                name:'ComputerTypes',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // }, 
            ],
        required:true,sm:12,md:6},  
        {type:"autocomplete",value:"computer_usage_option_code",label:"Select Usage",label_top:"Computer Usage Option*", 
            select:{
                name:'ComputerUsageOption',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['description',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // }, 
            ],
        required:true,sm:12,md:6},   
        {type:"text",value:"brand_name",label:"Enter Brand Name",label_top:"Device Brand / Manufacture (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"printer_serial_number",label:"Enter Serial Number",label_top:"Device Serial Number (Optional)",required:false,sm:12,md:6},  
        {type:"text",value:"network_ip_address",label:"Enter IP Address",label_top:"Network IP Address (Optional)",required:false,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    printers:[ 
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"text",value:"name",label:"Enter Name",label_top:"Printer Name*",required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"printer_type_code",label:"Select Type",label_top:"Printer Type*", 
            select:{
                name:'PrinterTypes',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.description,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-printer',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['description',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // }, 
            ],
        required:true,sm:12,md:6},   
        {type:"text",value:"brand_name",label:"Enter Brand Name",label_top:"Printer Name / Manufacture (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"serial_number",label:"Enter Serial Number",label_top:"Printer Serial Number (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"paper_size",label:"Enter Paper Size",label_top:"Size / Characters Per Line (Default: 48)",required:false,sm:12,md:6}, 
        {type:"text",value:"network_ip_address",label:"Enter IP Address",label_top:"Network IP Address (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"com_port",label:"Enter COM Port",label_top:"Printer Comport (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"baud_rate",label:"Enter Baud Rate",label_top:"Printer Baud Rate (Optional)",required:false,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    scales:[ 
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"text",value:"name",label:"Enter Name",label_top:"Scale Name*",required:true,sm:12,md:6},  
        {type:"text",value:"brand_name",label:"Enter Brand Name",label_top:"Brand / Manufacture (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"serial_number",label:"Enter Serial Number",label_top:"Scale Serial Number (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"barcode_prefix",label:"Enter Prefix",label_top:"Barcode Prefix *",required:false,sm:12,md:6},  
        {type:"autocomplete",value:"barcode_role",label:"Select Role",label_top:"Barcode Role *", 
            select:{
                name:'BarcodeRoles',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-barcode-scan',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
        required:true,disabled:false,sm:12,md:6},  
        {type:"number",value:"code_length",label:"Item Code Length",label_top:"Item Code Precision Length*",required:false,sm:12,md:6},  
        {type:"number",value:"decimal_length",label:"Decimal Length",label_top:"Decimal Precision Length*",required:false,sm:12,md:6},  
        {type:"text",value:"network_ip_address",label:"Enter IP Address",label_top:"Network IP Address (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"com_port",label:"Enter COM Port",label_top:"Scale Comport (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"baud_rate",label:"Enter Baud Rate",label_top:"Scale Baud Rate (Optional)",required:false,sm:12,md:6}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    vfd_displays:[ 
        {type:"",value:"code",label:"code",label_top:"Tracking Number*",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12}, 
        {type:"text",value:"name",label:"Enter Name",label_top:"VFD Display Name*",required:true,sm:12,md:12},  
        {type:"text",value:"brand_name",label:"Enter Brand Name",label_top:"Brand / Manufacture (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"serial_number",label:"Enter Serial Number",label_top:"VFD Display Serial Number (Optional)",required:false,sm:12,md:6}, 
         
        {type:"text",value:"network_ip_address",label:"Enter IP Address",label_top:"Network IP Address (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"com_port",label:"Enter COM Port",label_top:"VFD Display Comport (Optional)",required:false,sm:12,md:6}, 
        {type:"text",value:"baud_rate",label:"Enter Baud Rate",label_top:"VFD Display Baud Rate (Optional)",required:false,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    start_stock_taking:[  
        {type:"autocomplete",value:"outlet_code",label:"Select Outlet",label_top:"Selling Outlet*",
            select:{
                name:'Outlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            },
            action:[
                // {
                //     icon:"add",
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     name:"Add New Outlet",
                //     append:true
                // },{ 
                //     action:"add_item",
                //     item_values:"stock_outlets",
                //     action_name:"Add", 
                //     name:" as New Outlet", 
                // },
                { 
                    item_values:"stock_outlets",
                    name:"Manage Outlets",
                    link:"/stock/outlets",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"operating_date",onstart:'today',label:"Operating Date",label_top:"Business Day*",required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    join_stock_taking:[  
        {type:"select",value:"stock_taking_order_key",label:"Select Stock Take",label_top:"Stock Take *", 
            select:{
                name:'StockTakingOrders',
                value:'key',
                text:'outlet_name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.operating_date,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['outlet_name'],
                item_subtitle:['operating_date',]
            },
            action:[ 
                {
                    icon:"add",
                    item_values:"start_stock",
                    action:"start_stock_taking",
                    item_values:"stock_takes",
                    name:"Start a Stock Take",
                    link:"",
                    company_link:true,
                    no_data:true
                }, 
                { 
                    item_values:"start_stock",
                    name:"Stock Items", 
                    link:"/stock/items",
                    company_link:true,
                    no_data:true
                }
            ],
        required:true,sm:12,md:12},    
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    start_selling:[  
        {type:"autocomplete",value:"day_shift_and_till_key",label:"Select Till",label_top:"Outlet / Till / Operating Date*", 
            select:{
                name:'Tills',
                value:'day_shift_and_till_key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.operating_date,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['name'],
                // 'outlet_name',
                item_subtitle:['operating_date_','status',]
            },
            action:[ 
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"tills",
                    name:"Start New Day Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    no_data:true
                }, 
                { 
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    no_data:true
                }
            ],
        required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],  
    add_till_day_shift:[  
        {type:"autocomplete",value:"till_key",label:"Select Till",label_top:"Operating Date Till*", 
            select:{
                name:'Tills',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.operating_date,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['name'],
                // 'outlet_name',
                item_subtitle:['operating_date_','status',]
            },
            action:[ 
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"tills",
                    name:"Start New Day Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    no_data:true
                }, 
                { 
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    no_data:true
                }
            ],
        required:true,sm:12,md:12} ,
        {type:"money",value:"floating_cash",label:"",label_top:"Floating Cash*",required:true,sm:12,md:12}, 

    ], 
    open_cashups:[  
        {type:"autocomplete",value:"day_shift_and_till_key",label:"Select Till",label_top:"Outlet / Till / Operating Date*", 
            select:{
                name:'Tills',
                value:'day_shift_and_till_key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.operating_date,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['outlet_name','operating_date_',]
            },
            action:[ 
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"tills",
                    name:"Start New Day Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    no_data:true
                }, 
                { 
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    no_data:true
                }
            ],
        required:true,sm:12,md:12},   
        {type:"money",value:"opening_cash",label:"",label_top:"Opening Cash*",required:true,sm:12,md:6},  
        {type:"money",value:"expected_cash",label:"",label_top:"Expected",disabled:true,required:true,sm:12,md:6},  
        // {type:"autocomplete",value:"selling_model",label:"Select Model",label_top:"Selling Model*", 
        // select:{
        //     name:'SellingModels',
        //     value:'name',
        //     text:'name'
        // }, 
        // required:true,sm:12,md:12}, 
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    close_cashups:[  
        {type:"autocomplete",value:"till_key",label:"Select Till",label_top:"Till Number", 
            select:{
                name:'Tills',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['outlet_name','code',]
            },
            action:[ 
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"tills",
                    name:"Add New Till",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"tills",
                    action_name:"Add", 
                    name:" as New Till", 
                    company_link:true,
                },
                { 
                    item_values:"tills",
                    name:"Manage Tills",
                    link:"/office/tills&cashups/tills",
                    company_link:true,
                    append:true
                }
            ],
        required:true,disabled:true,sm:12,md:12},   
        {type:"money",value:"closing_cash",label:"Enter Closing Cash",label_top:"Total Cash Sales + Float *",required:true,sm:12,md:6},  
        {type:"money",value:"expected_cash",label:"Cash",label_top:"Expected Cash",disabled:true,required:true,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    manage_day_shift:[  
        {type:"autocomplete",value:"day_shift_key",label:"Select Shift",label_top:"Day Shift", 
            select:{
                name:'DayShifts',
                value:'key',
                text:'outlet_name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['operating_date'],
                item_subtitle:['outlet_name','status',],
            },
            action:[  
                { 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Start Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    append:true
                },{ 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    append:true
                }
            ],
        required:true,disabled:false,sm:12,md:12},   
        {type:"text",value:"operating_date_",label:"",label_top:"Operating Date",disabled:true,required:true,sm:12,md:6},  
        {type:"text",value:"outlet_name",label:"",label_top:"Outlet Name",disabled:true,required:true,sm:12,md:6},  
        // {type:"money",value:"closing_cash",label:"Closing Cash",label_top:"Total Cash Sales + Payments",disabled:true,required:true,sm:12,md:6},  
        // {type:"money",value:"expected_cash",label:"Expected Cash",label_top:"Closing Cash + Floating Cash",disabled:true,required:true,sm:12,md:6},  
        // {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    close_day_shift:[  
        {type:"autocomplete",value:"day_shift_key",label:"Select Shift",label_top:"Day Shift", 
            select:{
                name:'DayShifts',
                value:'key',
                text:'outlet_name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['operating_date'],
                item_subtitle:['outlet_name','status',],
            },
            action:[  
                { 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Start Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    append:true
                },{ 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    append:true
                }
            ],
        required:true,disabled:false,sm:12,md:12},   
        {type:"text",value:"operating_date_",label:"",label_top:"Operating Date",disabled:true,required:true,sm:12,md:6},  
        {type:"text",value:"outlet_name",label:"",label_top:"Outlet Name",disabled:true,required:true,sm:12,md:6},  
        {type:"money",value:"closing_cash",label:"Closing Cash",label_top:"Total Cash Sales + Payments",disabled:true,required:true,sm:12,md:6},  
        {type:"money",value:"expected_cash",label:"Expected Cash",label_top:"Closing Cash + Floating Cash",disabled:true,required:true,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    cashup_close:[  
        {type:"autocomplete",value:"cashup_key",label:"Select Cashup",label_top:"Cashup", 
            select:{
                name:'Cashups',
                value:'key',
                text:'till_name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['till_name'],
                item_subtitle:['teller_name','close_date_time_']
            },
            // action:[  
            //     { 
            //         icon:"add",
            //         item_values:"day_shifts",
            //         name:"Start Shift",
            //         link:"/sale/start-day",
            //         company_link:true,
            //         append:true
            //     },{ 
            //         icon:"add",
            //         item_values:"day_shifts",
            //         name:"Manage Day Shifts",
            //         link:"/sale/days-shifts",
            //         company_link:true,
            //         append:true
            //     }
            // ],
        required:true,disabled:false,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    open_z_read:[  
        {type:"autocomplete",value:"day_shift_key",label:"Select Shift",label_top:"Day Shift", 
            select:{
                name:'DayShifts',
                value:'key',
                text:'outlet_name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-cash-register',
                    tile:true
                },
                item_title:['operating_date'],
                item_subtitle:['outlet_name',]
            },
            action:[  
                { 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Start Shift",
                    link:"/sale/start-day",
                    company_link:true,
                    append:true
                },{ 
                    icon:"add",
                    item_values:"day_shifts",
                    name:"Manage Day Shifts",
                    link:"/sale/days-shifts",
                    company_link:true,
                    append:true
                }
            ],
        required:true,disabled:false,sm:12,md:12},       
    ], 

    stock_level:[  
        {type:"number",value:"stop_selling_level",label:"Stop Selling Level",label_top:"Will not sell when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"ordering_remind_level",label:"Ordering Remind Level",label_top:"Remind when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"full_level",label:"Full Level",label_top:"Stock is full when above*",required:true,sm:12,md:12},   
    ],
    stock_pricing:[  
        {type:"money",value:"retail_profit_margin",label:"Enter Retail Profit",label_top:"Retail Profit Margin in Percentage",append_icon:"mdi-percent-outline",required:true,sm:12,md:6},   
        {type:"money",value:"wholesale_profit_margin",label:"Enter Wholesale Profit",label_top:"Wholesale Profit Margin in Percentage",append_icon:"mdi-percent-outline",required:true,sm:12,md:6}, 
        {type:"money",value:"retail_allowed_discount",label:"Enter Retail Discount",label_top:"Retail Allowed Discount in Percentage",append_icon:"mdi-percent-outline",required:true,sm:12,md:6},   
        {type:"money",value:"wholesale_allowed_discount",label:"Enter Wholesale Discount",label_top:"Wholesale Allowed Discount in Percentage",append_icon:"mdi-percent-outline",required:true,sm:12,md:6},     
    ],
    outlet_stock_level:[  
        {type:"autocomplete",value:"stock_outlet_code",label:"Select Outlet",label_top:"Stock Outlet", 
            select:{
                name:'StockOutlets',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-store',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            }, 
        required:true,disabled:false,sm:12,md:12},   
        {type:"number",value:"stop_selling_level",label:"Stop Selling Level",label_top:"Will not sell when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"ordering_remind_level",label:"Ordering Remind Level",label_top:"Remind when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"full_level",label:"Full Level",label_top:"Stock is full when above*",required:true,sm:12,md:12},   
    ],
    item_stock_level:[  
        {type:"autocomplete",value:"item_key",label:"Select Item",label_top:"Stock Item", 
            select:{
                name:'Items',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    let third = item?index(item.outlet_name,queryText):false
                    if(!item){return first} 
                    if(!third){return first || second } 
                    return first || second || third 
                },
                item_icon:{
                    name:'mdi-tag',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code',]
            }, 
        required:true,disabled:false,sm:12,md:12},   
        {type:"number",value:"stop_selling_level",label:"Stop Selling Level",label_top:"Will not sell when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"ordering_remind_level",label:"Ordering Remind Level",label_top:"Remind when stock is below*",required:true,sm:12,md:6},   
        {type:"number",value:"full_level",label:"Full Level",label_top:"Stock is full when above*",required:true,sm:12,md:12},   
    ],
    //
    swiping_cards:[ 
        {type:"",value:"code",label:"code",label_top:"Card Number*", 
            // auto_code:{
            //     values:CODE.system, 
            //     onstart:true,
            //     updatable: false,
            //     disabledIfCode:false
            // },
            roles:{
                field_error:{
                    primary:ITEMS.CARDS.values,
                    min_size:2,
                    max_size:200,
                }
            },
        required:true,sm:12,md:6}, 
        {type:"money",value:"loaded_amount",label:"Enter Amount",label_top:"Card Amount*",required:true,sm:12,md:6},   
        {type:"date",value:"card_expire_date",label:"Enter date",label_top:"Card Expire Date*",required:true,sm:12,md:12},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],
    card_add_user:[
        {type:"",value:"code",label:"code",label_top:"Card Number*", 
            auto_code:{
                values:CODE.system,  
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},  
        {type:"autocomplete",value:"card_customer_key",label:"Select User",label_top:"Card User*",  
            select:{
                name:'Customers',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.phone_number,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['phone_number']
            },
            action:[
                {
                    icon:"person_add",
                    action:"add_item",
                    item_values:"customers",
                    name:"Add New Customer",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"customers",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Customer", 
                }, 
            ],
        required:true,sm:12,md:6},     
        {type:"date", value:"card_expire_date",label:"Date",label_top:"Card Expire Date*",disabled:true,required:false,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ],  

    //accounts
    payables:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Payable Account Name*",required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    receivables:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Receivable Account Name*",required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    assets:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Asset Name*",required:true,sm:12,md:6},  
        {type:"autocomplete",value:"asset_type_code",label:"Select Type",label_top:"Asset Type (Optional)",
            select:{
                name:'AssetTypes',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"asset_types",
                    name:"Add New Asset Type",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"asset_types",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Asset Type", 
                },{ 
                    item_values:"asset_types",
                    name:"Manage Asset Types",
                    link:"/office/accounts/assets/types",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    asset_types:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Asset Type Name*",required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    sale_on_account:[  
        {type:"autocomplete",value:"customer_key",label:"Select Customer",label_top:"Customer*",
            select:{
                name:'Customers',
                value:'key',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.phone_number,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['phone_number']
            },
            action:[
                {
                    icon:"person_add",
                    action:"add_item",
                    item_values:"customers",
                    name:"Add New Customer",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"customers",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Customer", 
                }, 
            ],
        required:true,sm:12,md:12,prepend_inner_icon:"mdi-account"},  
        {type:"autocomplete",value:"receivable_code",label:"Select Account",label_top:"Receivable Account*",
            select:{
                name:'ReceivableAccounts',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"receivables",
                    name:"Add New Receivable",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"receivables",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Receivable", 
                },{ 
                    item_values:"receivables",
                    name:"Manage Receivables",
                    link:"/office/accounts/receivable",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6,prepend_inner_icon:"mdi-account-cash"},  
        {type:"autocomplete",value:"selected_printer_name",label:"Select",label_top:"Printer*",
            select:{
                name:'AllPrinters',
                value:'name',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            }, 
        required:true,sm:12,md:6,prepend_inner_icon:"mdi-printer"},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    expense_accounts:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Expense Account Name*",required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Enter a note",label_top:"Description (Optional)",required:false,sm:12,md:12},   
    ], 
    expenses:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        // {type:"text",value:"name",label:"Enter Expense Name",label_top:"What you are paying for*",required:true,sm:12,md:6},  
        {type:"autocomplete",value:"expense_account_code",label:"Select account",label_top:"Expense account *",
            select:{
                name:'ExpenseAccounts',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    // name:'mdi-account',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"expense_accounts",
                    name:"Add New Expense Account",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"expense_accounts",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Expense Account", 
                },{ 
                    item_values:"expense_accounts",
                    name:"Manage Expense Accounts",
                    link:"/office/accounts/expenses",
                    company_link:true,
                    append:true
                }
            ],
        required:false,sm:12,md:6},  
        {type:"money",value:"amount",label:"Amount",label_top:"How much you are paying*",required:true,sm:12,md:6},   
        {type:"autocomplete",value:"supplier_code",label:"Select Supplier",label_top:"Supplier that you are paying*",
            select:{
                name:'Suppliers',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-account'
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"suppliers",
                    name:"Add New Supplier",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"suppliers",
                    action_name:"Add", 
                    name:" as New Supplier", 
                    company_link:true,
                },{ 
                    item_values:"suppliers",
                    name:"Manage Suppliers",
                    link:"/office/people/suppliers",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},    
        {type:"date", value:"expense_date",label:"Enter Date",label_top:"Expense Date*",onstart:'today',required:true,sm:12,md:6}, 
        {type:"autocomplete",value:"payment_method_code",label:"Select",label_top:"Payment Methods*",
            select:{
                name:'PaymentMethods',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.money_account_name,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'mdi-cash',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['money_account_name']
            },
        required:true,sm:12,md:12},  
        {type:"textarea",value:"description",label:"Expense Description",label_top:"What you are paying for*",required:true,sm:12,md:12},   
    ], 
    payment_methods:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:12},     
        
        {type:"text",value:"name",label:"Enter Name",label_top:"Payment Method Name*",required:true,sm:12,md:6},  
        {type:"autocomplete",value:"money_account_code",label:"Select account",label_top:"Money Account *",
            select:{
                name:'MoneyAccounts',
                value:'code',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.code,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    // name:'mdi-account',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['code']
            },
            action:[
                {
                    icon:"add",
                    action:"add_item",
                    item_values:"money_accounts",
                    name:"Add New Money Account",
                    company_link:true,
                    append:true
                },{ 
                    action:"add_item",
                    item_values:"money_accounts",
                    action_name:"Add", 
                    company_link:true,
                    name:" as New Money Account", 
                },{ 
                    item_values:"money_accounts",
                    name:"Manage Money Accounts",
                    link:"/office/accounts/money-accounts",
                    company_link:true,
                    append:true
                }
            ],
        required:true,sm:12,md:6},   
        {type:"textarea",value:"description",label:"Enter Description",label_top:"Account description",required:false,sm:12,md:12},   
    ], 
    money_accounts:[
        {type:"",value:"code",label:"Code",label_top:"Tracking Number*",
            auto_code:{
                values:CODE.system, 
                onstart:true,
                disabledIfCode:true
            },
        required:true,sm:12,md:6},     
        
        {type:"text",value:"bank_name",label:"Name",label_top:"Bank / Wallet Name*",required:true,sm:12,md:6},    
        {type:"text",value:"name",label:"Enter Name",label_top:"Account Name*",required:true,sm:12,md:12},    
        {type:"text",value:"account_number",label:"Enter Number",label_top:"Account Number*",required:true,sm:12,md:6},    
        {type:"text",value:"account_branch",label:"Enter Branch",label_top:"Branch Name*",required:false,sm:12,md:6},    
        {type:"textarea",value:"description",label:"Enter Description",label_top:"Account description",required:false,sm:12,md:12},   
    ], 

    //cpanel
    user_groups:[
        {type:"",name:"code",value:"code",label:"Code",
        auto_code:{
            values:CODE.system, 
            onstart:false,
            disabledIfCode:true
        },required:true,sm:12,md:6}, 
        {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:6},  
    ],  
    permissions:[
        {type:"",name:"code",value:"code",label:"Code",
        auto_code:{
            values:CODE.system, 
            onstart:true,
            disabledIfCode:true
        },required:true,sm:12,md:12},  
        {type:"autocomplete",name:"name",value:"name",label:"name",required:true,sm:12,md:12,
            select:{
                name:'app_links',
                value:'name',
                text:'name'
            },
            search:{
                filter:(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                        if(!a||!b){return false}
                        a = ""+a; b = ""+b
                        return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                    } 
                    let first = index(itemText,queryText)
                    let second = item?index(item.link,queryText):false
                    if(!item){return first} 
                    return first || second 
                },
                item_icon:{
                    name:'',
                    tile:true
                },
                item_title:['name'],
                item_subtitle:['path']
            },
        },  
        {type:"select",name:"type",value:"permission_type",label:"Action Type",required:true,sm:12,md:6,select:{
            name:'permission_type',
            value:'name',
            text:'name'
        }},  
        {type:"select",name:"type",value:"access_type",label:"Access Type",required:true,sm:12,md:6,select:{
            name:'access_type',
            value:'name',
            text:'name'
        }},  
    ], 
}
const data_path= (APP.SERVER+"/").toUpperCase()
const data_path_st= (APP.SERVER+"/SETTINGS/").toUpperCase()
const data_path_st_locations = data_path_st+(ITEMS.LOCATIONS.values.toUpperCase())+"/"
const company_data_path= (APP.SERVER+"/"+ITEMS.COMPANY_DATA.values+"/").toUpperCase()
const company_data_path_x = company_data_path+"xxxx/"
const company_data_path_settings = company_data_path+"xxxx/SETTINGS/"
const company_data_path_accounts = company_data_path+"xxxx/ACCOUNTS/"
const company_data_path_hospital = company_data_path+"xxxx/HOSPITAL/"
const PATHS = {
    users:(ITEMS.USERS.values+"/").toUpperCase(),

    //SETTINGS
    user_groups:data_path_st+(ITEMS.USER_GROUPS.values.toUpperCase())+"/",
    permissions:data_path_st+(ITEMS.PERMISSIONS.values.toUpperCase())+"/",

    //Locations
    countries:data_path_st_locations+(ITEMS.COUNTRIES.values.toUpperCase())+"/",
    regions:data_path_st_locations+(ITEMS.REGIONS.values.toUpperCase())+"/",
    districts:data_path_st_locations+(ITEMS.DISTRICTS.values.toUpperCase())+"/",


    companies:data_path+(ITEMS.COMPANIES.values.toUpperCase())+"/",
    company_info:data_path+(ITEMS.COMPANIES.values.toUpperCase())+"/xxxx/",
    company_data:company_data_path,

    //COMPANY DATA KEY
    company_users:company_data_path_x+(ITEMS.COMPANY_USERS.values.toUpperCase())+"/",
    items:company_data_path_x+(ITEMS.ITEMS.values.toUpperCase())+"/",
    trackings:company_data_path_x+(ITEMS.TRACKINGS.values.toUpperCase())+"/",
    tracking_keys:company_data_path_x+(ITEMS.TRACKINGS.values.toUpperCase())+"/KEYS/", 
    tracking_updates:company_data_path_x+(ITEMS.TRACKINGS.values.toUpperCase())+"/UPDATES/", 
    expiries:company_data_path_x+(ITEMS.EXPIRIES.values.toUpperCase())+"/",
    old_data:company_data_path_x+(ITEMS.OLD_DATA.values.toUpperCase())+"/",
    company_quick_actions:company_data_path_x+(ITEMS.COMPANY_QUICK_ACTIONS.values.toUpperCase())+"/",

    //COMPANY SETTINGS
    company_user_groups:company_data_path_settings+(ITEMS.COMPANY_USER_GROUPS.values.toUpperCase())+"/",
    company_sizes:data_path_st+(ITEMS.COMPANY_SIZES.values.toUpperCase())+"/",
    company_types:data_path_st+(ITEMS.COMPANY_TYPES.values.toUpperCase())+"/",
    tills:company_data_path_settings+(ITEMS.TILLS.values.toUpperCase())+"/", 
    stock_outlets:company_data_path_settings+(ITEMS.STOCK_OUTLETS.values.toUpperCase())+"/", 
    invoices:company_data_path_settings+(ITEMS.INVOICES.values.toUpperCase())+"/",
    brands:company_data_path_settings+(ITEMS.BRANDS.values.toUpperCase())+"/",
    manufacturers:company_data_path_settings+(ITEMS.MANUFACTURERS.values.toUpperCase())+"/",
    suppliers:company_data_path_settings+(ITEMS.SUPPLIERS.values.toUpperCase())+"/",
    supplier_categories:company_data_path_settings+(ITEMS.SUPPLIER_CATEGORIES.values.toUpperCase())+"/",
    customers:company_data_path_settings+(ITEMS.CUSTOMERS.values.toUpperCase())+"/",
    customer_groups:company_data_path_settings+(ITEMS.CUSTOMER_GROUPS.values.toUpperCase())+"/", 
    item_categories:company_data_path_settings+(ITEMS.ITEM_CATEGORIES.values.toUpperCase())+"/",
    item_departments:company_data_path_settings+(ITEMS.ITEM_DEPARTMENTS.values.toUpperCase())+"/",
    item_kits:company_data_path_settings+(ITEMS.ITEM_KITS.values.toUpperCase())+"/",
    item_order_options:company_data_path_settings+(ITEMS.ITEM_ORDER_OPTIONS.values.toUpperCase())+"/",
    printers:company_data_path_settings+(ITEMS.PRINTERS.values.toUpperCase())+"/",
    devices:company_data_path_settings+(ITEMS.DEVICES.values.toUpperCase())+"/",
    cards:company_data_path_settings+(ITEMS.CARDS.values.toUpperCase())+"/",
    company_settings:company_data_path_settings+(ITEMS.COMPANY_SETTINGS.values.toUpperCase())+"/",
   
    //COMPANY ACCOUNTS
    days_shifts:company_data_path_accounts+(ITEMS.DAYS_SHIFTS.values.toUpperCase())+"/",
    days_shifts_sales:company_data_path_accounts+(ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase())+"/",
    cashups:company_data_path_accounts+(ITEMS.CASHUPS.values.toUpperCase())+"/",
    quotations_and_invoices:company_data_path_accounts+(ITEMS.QUOTATIONS_AND_INVOICES.values.toUpperCase())+"/",
    issued_quotations:company_data_path_accounts+(ITEMS.QUOTATIONS.values.toUpperCase())+"/ISSUED/",
    stock_orders:company_data_path_accounts+(ITEMS.STOCK_ORDERS.values.toUpperCase())+"/",
    sale_orders:company_data_path_accounts+(ITEMS.SALE_ORDERS.values.toUpperCase())+"/",
    assets:company_data_path_accounts+(ITEMS.ASSETS.values.toUpperCase())+"/",
    asset_types:company_data_path_accounts+(ITEMS.ASSET_TYPES.values.toUpperCase())+"/",
    payables:company_data_path_accounts+(ITEMS.PAYABLES.values.toUpperCase())+"/",
    receivables:company_data_path_accounts+(ITEMS.RECEIVABLES.values.toUpperCase())+"/",
    creditors:company_data_path_accounts+(ITEMS.CREDITORS.values.toUpperCase())+"/",
    debtors:company_data_path_accounts+(ITEMS.DEBTORS.values.toUpperCase())+"/",
    payments:company_data_path_accounts+(ITEMS.PAYMENTS.values.toUpperCase())+"/",
    payment_methods:company_data_path_accounts+(ITEMS.PAYMENT_METHODS.values.toUpperCase())+"/",
    money_accounts:company_data_path_accounts+(ITEMS.MONEY_ACCOUNTS.values.toUpperCase())+"/",
    expenses:company_data_path_accounts+(ITEMS.EXPENSES.values.toUpperCase())+"/",
    expense_accounts:company_data_path_accounts+(ITEMS.EXPENSE_ACCOUNTS.values.toUpperCase())+"/",
    item_orders:company_data_path_accounts+(ITEMS.ITEM_ORDERS.values.toUpperCase())+"/",

    //HOSPITAL
    patient_orders:company_data_path_hospital+(ITEMS.PATIENT_ORDERS.values.toUpperCase())+"/",
    patient_histories:company_data_path_hospital+(ITEMS.PATIENT_HISTORIES.values.toUpperCase())+"/",

    stock_taking_orders:company_data_path_accounts+(ITEMS.STOCK_TAKING_ORDERS.values.toUpperCase())+"/",
    stock_takings:company_data_path_accounts+(ITEMS.STOCK_TAKINGS.values.toUpperCase())+"/",
} 
const SYSTEM_DATA = [
    {...ITEMS.SUPPLIERS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"supplier_category_name",name:"Category",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"phone_number",name:"Phone",show:true}, 
        {value:"email_address",name:"Email",show:true}, 
        {value:"location_address",name:"location",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.SUPPLIER_CATEGORIES,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.CUSTOMERS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"customer_group_code",name:"Group",show:true}, 
        {value:"name",name:"Name",show:true}, 
        {value:"location_address",name:"location",show:true}, 
        {value:"phone_number",name:"Phone",show:true}, 
        {value:"email_address",name:"Email",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.CUSTOMER_GROUPS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.ITEMS,names:"Selling Items (Products)",headers:[  
        {value:"code",name:"Barcode",align:"center",show:true},      
        {value:"category_code",name:"Category Code",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"brand_code",name:"Brand Code",show:true}, 
        {value:"manufacturer_code",name:"Manufacturers Code",show:true}, 
        {value:"cost_price",name:"Cost Price",show:true}, 
        {value:"wholesale_price",name:"Wholesale Price",show:true}, 
        {value:"retail_price",name:"Retail Price",show:true}, 
        {value:"tax_group_code",name:"Tax Group Code ",show:true},  
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.ITEM_CATEGORIES,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.ITEM_DEPARTMENTS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.BRANDS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.MANUFACTURERS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.TILLS,headers:[  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"name",name:"Name",show:true}, 
        {value:"outlet",name:"Outlet",show:true}, 
        {value:"pc_serial_number",name:"PC",show:true}, 
        {value:"printer_serial_number",name:"Printer",show:true}, 
        {value:"description",name:"Description",show:true},  
    ]},
    {...ITEMS.CARDS,headers:[  
        {value:"key",name:"Key",show:true},  
        {value:"code",name:"code",align:"center",show:true},      
        {value:"active",name:"Active",show:true}, 
        {value:"card_type",name:"Card type",show:true}, 
        {value:"card_expire_date",name:"Expire Date",show:true}, 
        {value:"loaded_amount",name:"Loaded Amount",show:true}, 
    ]},
]
const ALERTS = {
    verify_phone_number:{
        name:"Verify your Phone Number",
        description:"For your account security issues.",
        type:'error',
        link:'/signup'
    },
    upload_profile_picture:{
        name:"Upload Profile Picture",
        description:"Profile Picture is Required.",
        type:'warning',
        link:'/account/profile/#profilePicture'
    },
    enter_date_of_birth:{
        name:"Enter Date of Birth",
        description:"Date of Birth is required for your profile.",
        type:'warning',
        link:'/account/profile/#userInfo'
    },
    enter_next_of_kin_info:{
        name:"Enter Next of Kin Info",
        description:"The next of kin info is required to finish setting up your profile.",
        type:'warning',
        link:'/account/profile/#nextOfKinInfo'
    },
    enter_id_info:{
        name:"Enter ID Info",
        description:"The ID info is required.",
        type:'warning',
        link:'/account/profile/#idInfo'
    },
    enter_location_address:{
        name:"Enter your Address",
        description:"The Location Address is required.",
        type:'warning',
        link:'/account/profile/#locationAddress'
    },
}
 
module.exports = {
    //
    ACTION_REFRESH_TIME:100,
    APP:APP,
    POS_TYPE:POS_TYPE,
    CODE:CODE,
    PAY_API:PAY_API,
    BANKS:BANKS,
    ITEMS:ITEMS, 
    FIELDS:FIELDS, 
    PATHS:PATHS, 
    SYSTEM_DATA:SYSTEM_DATA, 
    ALERTS:ALERTS, 
    production:production 
    //
};