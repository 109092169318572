<template>
    <v-dialog  
        z-index="100" 
        :style="' --zIndex:101;'"
        class="mbs-z-index" 
        v-model="mbs_dialog.show" 
        :max-width="mbs_dialog.size?mbs_dialog.size:500"                     
        :persistent='mbs_dialog.fixed'  >
        <v-card class="">
            <v-card-title class="primary headline">
                <v-layout >
                    
                    <div class="font-weight-bold b2--text"> 
                        <span >{{mbs_dialog.title}}</span> 
                    </div> 
                    <v-spacer></v-spacer> 
                    <v-btn class="ml-9" @click="BTN_NO(mbs_dialog.action.silent_btn_no)" icon dark><v-icon >close</v-icon></v-btn>
                </v-layout>
            </v-card-title>
             
            <v-card-text>
                <br> 
                <v-layout   justify-center v-if="mbs_dialog.icon"> 
                    <v-icon size="150">{{mbs_dialog.icon}}</v-icon>
                </v-layout>
                
                <form @submit.prevent="CONFIRM(input.password)"> 
                    <v-layout row wrap class="mx-0 my-6"  > 
                        <v-flex xs12 >
                            <div style="white-space: pre-line" class="mb-2 ml-">{{mbs_dialog.text}} </div> 
                        </v-flex> 
                        <v-flex xs12   class="my-3">
                            <v-layout row wrap class="ma-0 pr-1"> 
                                <v-btn v-if="mbs_dialog.btnNo"   @click="BTN_NO()"
                                        class="mr-2"
                                        color="primary" height="50"  >
                                    <div class="not-f5">
                                        <v-icon class="pr-1 pb-1" mbs_dialog.btnNoIcon>{{mbs_dialog.btnNoIcon}}</v-icon>
                                        {{mbs_dialog.btnNo?mbs_dialog.btnNo:"CANCEL"}}
                                    </div>
                                </v-btn> 
                                <v-flex  >
                                    <v-btn   
                                        type="submit" autofocus
                                        :loading="inputLoading"
                                        color="secondary" height="50" width="100%" >
                                        <div class="not-f5">
                                            <v-icon class="pr-1 pb-1" mbs_dialog.btnYesIcon>{{mbs_dialog.btnYesIcon}}</v-icon>
                                            {{mbs_dialog.btnYes?mbs_dialog.btnYes:'CONFIRM PASSWORD'}}
                                        </div>
                                    </v-btn>      
                                </v-flex>    
                            </v-layout>
                        </v-flex> 
                    </v-layout>  
                </form>
            </v-card-text>  
            
        </v-card>
    </v-dialog>
</template>

<script>
    let NAME = 'DIALOG'
export default {
    props:['notShow'], 
    data(){
        return{
            show:false,
            input:{},
            inputLoading:false,
            psw1:true
        } 
    }, 
    computed:{
        mbs_dialog(){
            return  this.$store.getters.getMBS_dialog
        }
    },
    methods:{
        BTN_NO(silent_btn_no){ 
            if (!silent_btn_no) {
                this.MBS.events.$emit('DIALOG_NO',{
                    ...this.mbs_dialog.action,
                    NO:true,
                    YES:false
                }) 
                this.MBS.events.$emit('DIALOG_YES',{
                    ...this.mbs_dialog.action,
                    NO:true,
                    YES:false
                })  
            }
            this.$store.dispatch('setMBS_dialog',{})
        },
        CONFIRM(){ 
            this.MBS.events.$emit('DIALOG_YES',{
                ...this.mbs_dialog.action,
                NO:false,
                YES:true
            }) 
            this.$store.dispatch('setMBS_dialog',{}) 
        }
    },
    watch:{
        mbs_dialog(value){ 
        },   
    } 
}
</script>
