import Vue from 'vue'

//  TOOLBARS
import navbar from '../components/mbs_toolbars/navbar' 
import toolbar from '../components/mbs_toolbars/toolbar' 
import toolbarItems from '../components/mbs_toolbars/toolbarItems' 
import toolbarLinks from '../components/mbs_toolbars/toolbarLinks' 
import pageTab from '../components/mbs_toolbars/pageTab'
import subToolbar from '../components/mbs_toolbars/subToolbar'
import alertToolbar from '../components/mbs_toolbars/alertToolbar'
//  SECTION
import header from '../components/mbs_sections/header' 
import headerImage from '../components/mbs_sections/headerImage' 
import headerImages from '../components/mbs_sections/headerImages' 
import footer from '../components/mbs_sections/footer'  
import showTab from '../components/mbs_sections/showTab' 
import pageSpinner from '../components/mbs_sections/pageSpinner' 
import itemManager from '../components/mbs_sections/itemManager'  
import inputFields from '../components/mbs_sections/inputFields'  
import keyboard from '../components/mbs_sections/keyboard'  
import keyboard2 from '../components/mbs_sections/keyboard2'  
import actionsField from '../components/mbs_sections/actionsField'  
import accessPage from '../components/mbs_sections/accessPage' 
import accessLogin from '../components/mbs_sections/accessLogin' 
import creditCardInput from '../components/mbs_sections/creditCardInput' 
// MAPS
// import googleMap from '../components/mbs_maps/googleMap' 
// import mapBox from '../components/mbs_maps/mapBox'
// MENUS
import excelExport from '../components/mbs_menus/excelExport' 
import autoPrint from '../components/mbs_menus/autoPrint' 
import dialog from '../components/mbs_menus/dialog' 
import dialog_confirm_password from '../components/mbs_menus/dialog_confirm_password' 
import dialogInput from '../components/mbs_menus/dialogInput' 
import progressDialog from '../components/mbs_menus/progressDialog'
import snackbar from '../components/mbs_menus/snackbar'
import backHome from '../components/mbs_menus/backHome'
import signWith from '../components/mbs_menus/signWith'
import dateSelect from '../components/mbs_menus/dateSelect'

// SELECTOR
import excelSelector from '../components/mbs_file_selector/excelSelector' 

// IMAGES
import imageCrop from '../components/mbs_images/imageCrop'



//  TOOLBARS
Vue.component('mbs-navbar',navbar) 
Vue.component('mbs-toolbar',toolbar) 
Vue.component('mbs-toolbar-items',toolbarItems) 
Vue.component('mbs-toolbar-links',toolbarLinks) 
Vue.component('mbs-page-tab',pageTab) 
Vue.component('mbs-sub-toolbar',subToolbar) 
Vue.component('mbs-alert-toolbar',alertToolbar) 
//  SECTION
Vue.component('mbs-header',header)
Vue.component('mbs-header-img',headerImage)
Vue.component('mbs-header-imgs',headerImages)
Vue.component('mbs-footer',footer) 
Vue.component('mbs-show-tab',showTab)
Vue.component('mbs-page-spinner',pageSpinner)
Vue.component('mbs-item-manager',itemManager) 
Vue.component('mbs-input-fields',inputFields) 
Vue.component('mbs-keyboard',keyboard) 
Vue.component('mbs-keyboard2',keyboard2) 
Vue.component('mbs-actions-field',actionsField) 
Vue.component('mbs-access-page',accessPage)
Vue.component('mbs-access-login',accessLogin)
Vue.component('mbs-credit-card',creditCardInput)
//MAP
// Vue.component('mbs-google-map',googleMap)
// Vue.component('mbs-map-box',mapBox)
//MENUS
Vue.component('mbs-excel-export',excelExport)
Vue.component('mbs-auto-print',autoPrint)
Vue.component('mbs-dialog',dialog)
Vue.component('mbs-dialog-confirm-password',dialog_confirm_password)
Vue.component('mbs-dialog-input',dialogInput)
Vue.component('mbs-dialog-progress',progressDialog)
Vue.component('mbs-snackbar',snackbar)
Vue.component('mbs-back-home',backHome)
Vue.component('mbs-sign-with',signWith)  
Vue.component('mbs-image-crop',imageCrop)
Vue.component('mbs-date-select',dateSelect)
//SECTOR
Vue.component('mbs-excel-selector',excelSelector)

 