
<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <v-card>
                    <mbs-page-tab 
                        :items="FULL_LINKS"/>  
                </v-card>
                <div class="px-1">
                    <br>
                    <form id="form_pricing" @submit.prevent="CONFIRM_SUBMITTING_PRICING(input_pricing)" >
                        <v-row>
                            <v-col cols="12" md="4">
                                <h1 class="grey--text text--darken-1">Pricing</h1>
                                <br>
                                <div class="text-justify">
                                    <!-- <h4 class="grey--text text--darken-1">Order Details</h4> -->
                                    Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order.  
                                </div>
                            </v-col>
                            <v-col cols="12" md="8" class="pt-9"  >   
                                <mbs-page-spinner v-if="loadStockLevels"
                                    height="220"/>  
                                <mbs-input-fields v-else
                                    v-model="input_pricing"
                                    :field_items="DATA.FIELDS.stock_pricing"
                                    :select_items="SelectItems" 
                                    /> 
                                <v-btn class="mt-3 "  
                                    :loading="inputLoading"  
                                    type="submit" width="100%" color="primary">UPDATE PRICING<v-icon class="pl-2">mdi-content-save-cog</v-icon></v-btn>
                            </v-col> 
                        </v-row> 
                    </form>
    
                    <br>
                    <hr>
                    
                    <form id="form_stock_levels" @submit.prevent="CONFIRM_SUBMITTING_STOCK_LEVELS(input_stock_levels)" >
                        <br>
                        <v-row>
                            <v-col cols="12" md="4">
                                <h1 class="grey--text text--darken-1">Stock Levels</h1>
                                <br>
                                <div class="text-justify">
                                    <!-- <h4 class="grey--text text--darken-1">Order Details</h4> -->
                                    Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order. This includes inspecting the quality, condition, and quantity of any incoming goods, and allocating them to a space in the warehouse.
                                </div>
                            </v-col>
                            <v-col cols="12" md="8" class="pt-9" >   
                                <mbs-page-spinner v-if="loadStockLevels"
                                    height="220"/>  
                                <mbs-input-fields v-else
                                    v-model="input_stock_levels"
                                    :field_items="DATA.FIELDS.stock_level"
                                    :select_items="SelectItems" 
                                    /> 
                                <v-btn class="mt-3 "  
                                    :loading="inputLoading"  
                                    type="submit" width="100%" color="primary">UPDATE STOCK LEVELS <v-icon class="pl-2">mdi-content-save-cog</v-icon></v-btn>
                            </v-col> 
                        </v-row> 
                    </form>
    
    
                    <br> 
                    <hr>
                    <br> 
                    <h1 class="grey--text text--darken-1 mb-2 text-center">CONFIG ONLY ITEM / OUTLET</h1> 
                    <v-row  > 
                        <v-col cols="12" > 
                            <v-card tile>
                                <v-list > 
                                    <!-- //Outlets -->
                                    <v-list-group >
                                        <template v-slot:activator>
                                            <v-list-item-action><v-icon>mdi-store</v-icon></v-list-item-action>
                                            <v-list-item-content>
                                                <div>{{MBS.actions.SIZE(StockOutletLevels)}} Outlet(s) with stock level</div>  
                                            </v-list-item-content> 
                                        </template> 
                                        <v-card class="mx-5" outlined flat tile>
                                            <div v-for="(outlet,index) in StockOutletLevels" :key="index" class="ml-2"> 
                                                <v-divider inset v-if="index!=0" ></v-divider> 
                                                <v-list-item dense @click="" >
                                                    <v-list-item-action > 
                                                        <v-avatar  tile 
                                                            size="35" rounded 
                                                            color="b2" class="white--text" > 
                                                            <v-icon  >mdi-store</v-icon>
                                                        </v-avatar>   
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title > 
                                                            {{outlet.name}}  
                                                        </v-list-item-title> 
                                                        <v-list-item-subtitle  >
                                                            <strong>
                                                                Stop Selling: {{outlet.stop_selling_level}} <span class="mx-2">|</span>
                                                                Ordering Remind: {{outlet.ordering_remind_level}} <span class="mx-2">|</span>
                                                                Full Level: {{outlet.full_level}}  
    
                                                            </strong>
                                                        </v-list-item-subtitle> 
                                                        <v-list-item-subtitle  >
                                                            <strong> 
                                                                R.P.M: {{outlet.retail_profit_margin}} <span class="mx-2">|</span>
                                                                W.P.M: {{outlet.wholesale_profit_margin}} <span class="mx-2">|</span>
                                                                R.A.D: {{outlet.retail_allowed_discount}} <span class="mx-2">|</span>
                                                                W.A.D: {{outlet.wholesale_allowed_discount}}  
                                                            </strong>
                                                        </v-list-item-subtitle> 
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-layout row wrap> 
                                                            <v-btn @click="UPDATE_OUTLET_STOCK_LEVELS(outlet)" icon outlined ><v-icon>edit</v-icon></v-btn>
                                                            <v-btn @click="CONFIRM_DELETE_OUTLET_STOCK_LEVELS(outlet)" icon outlined class="mx-2"><v-icon>mdi-delete</v-icon></v-btn>
                                                        </v-layout>
                                                    </v-list-item-action>
                                                </v-list-item>   
                                                
                                            </div>  
                                            <div  >
                                                <v-divider  ></v-divider> 
                                                <v-layout class="ma-0 mr- b1" align-center justify-center >
                                                    <!-- <v-spacer/>  -->
                                                    <v-btn :disabled="false" @click="ADD_OUTLET_STOCK_LEVELS()" class="mb-2 mt-4"    color="secondary" >
                                                        <div v-if="MBS.actions.SIZE(StockOutlets)==0">Add Outlet Stock Level</div>
                                                        <div v-else>Add another Outlet Stock Level</div> 
                                                        <v-icon class="ml-3">mdi-plus-circle</v-icon>
                                                    </v-btn>
                                                </v-layout> 
                                            </div>
                                        </v-card>
                                    </v-list-group>
                                    
                                    <!-- //Items -->
                                    <v-list-group >
                                        <template v-slot:activator>
                                            <v-list-item-action><v-icon>mdi-tag</v-icon></v-list-item-action>
                                            <v-list-item-content>
                                                <div>{{MBS.actions.SIZE(StockItemLevels)}} Items(s) with stock level</div>  
                                            </v-list-item-content> 
                                        </template> 
                                        <v-card class="mx-5" outlined flat tile>
                                            <div v-for="(item,index) in StockItemLevels" :key="index" class="ml-2"> 
                                                <v-divider inset v-if="index!=0" ></v-divider> 
                                                <v-list-item dense @click="" >
                                                    <v-list-item-action > 
                                                        <v-avatar  tile 
                                                            size="35" rounded 
                                                            color="b2" class="white--text" >
                                                            <v-icon  >mdi-tag</v-icon> 
                                                        </v-avatar>   
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title > 
                                                            {{item.name}}  
                                                        </v-list-item-title> 
                                                        <v-list-item-subtitle  >
                                                            <strong>
                                                                Stop Selling: {{item.stock_settings.stop_selling_level}} <span class="mx-2">|</span>
                                                                Ordering Remind: {{item.stock_settings.ordering_remind_level}} <span class="mx-2">|</span>
                                                                Full Level: {{item.stock_settings.full_level}}  
                                                            </strong>
                                                        </v-list-item-subtitle> 
                                                        <v-list-item-subtitle  >
                                                            <strong> 
                                                                R.P.M: {{item.stock_settings.retail_profit_margin}} <span class="mx-2">|</span>
                                                                W.P.M: {{item.stock_settings.wholesale_profit_margin}} <span class="mx-2">|</span>
                                                                R.A.D: {{item.stock_settings.retail_allowed_discount}} <span class="mx-2">|</span>
                                                                W.A.D: {{item.stock_settings.wholesale_allowed_discount}}  
                                                            </strong>
                                                        </v-list-item-subtitle> 
                                                    </v-list-item-content>
                                                    <v-list-item-action> 
                                                        <v-layout row wrap> 
                                                            <v-btn @click="UPDATE_ITEM_STOCK_LEVELS(item)" icon outlined ><v-icon>edit</v-icon></v-btn>
                                                            <v-btn @click="CONFIRM_DELETE_ITEM_STOCK_LEVELS(item)" icon outlined class="mx-2"><v-icon>mdi-delete</v-icon></v-btn>
                                                        </v-layout>
                                                    </v-list-item-action>
                                                </v-list-item>   
                                                
                                            </div>  
                                            <div  >
                                                <v-divider  ></v-divider> 
                                                <v-layout class="ma-0 mr- b1" align-center justify-center >
                                                    <!-- <v-spacer/>  -->
                                                    <v-btn :disabled="false" @click="ADD_ITEM_STOCK_LEVELS()" class="mb-2 mt-4"    color="secondary" >
                                                        <div v-if="MBS.actions.SIZE(StockItemLevels)==0">Add Item Stock Level</div>
                                                        <div v-else>Add another Item Stock Level</div> 
                                                        <v-icon class="ml-3">mdi-plus-circle</v-icon>
                                                    </v-btn>
                                                </v-layout> 
                                            </div>
                                        </v-card>
                                    </v-list-group>  
                                </v-list> 
                            </v-card> 
                        </v-col> 
                    </v-row> 
                    <br> <br> 

                </div>
            </div> 
        </v-container> 
        <div>  
            <!--outlet-->
            <v-dialog
                v-model="dialog_outlet_add_levels"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"><v-icon dark class="mr-2" size="40">mdi-store</v-icon>OUTLET STOCK LEVEL</div> 
                        <v-spacer></v-spacer> 
                        <v-btn dark icon class="ml-5" @click="dialog_outlet_add_levels=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <form @submit.prevent="CONFIRM_SUBMITTING_OUTLET_STOCK_LEVELS(input_outlet_levels)">
                            <div class="ma-5" v-if="dialog_outlet_add_levels">  
                                <div> 
                                    <mbs-input-fields  
                                        v-model="input_outlet_levels"
                                        :field_items="DATA.FIELDS.outlet_stock_level"
                                        :select_items="SelectItems" 
                                        />
                                    <mbs-input-fields 
                                        v-model="input_outlet_levels"
                                        :field_items="DATA.FIELDS.stock_pricing"
                                        :select_items="SelectItems" 
                                        /> 
                                </div>
                                <div class=" mt-5">Make sure you fill above form before continuing and updating.</div>  
                                <v-layout row wrap class="ma-0 mt-3">
                                    <v-btn large color="primary">CANCEL</v-btn>
                                    <v-flex class="pa-0 ml-1" > 
                                        <v-btn width="100%" :loading="inputLoading" type="submit" large color="secondary">UPDATE OUTLET STOCK LEVEL</v-btn>
                                    </v-flex>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            
            <!--items-->
            <v-dialog
                v-model="dialog_item_add_levels"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text"><v-icon dark class="mr-2" size="40">mdi-tag</v-icon>ITEM STOCK LEVEL</div> 
                        <v-spacer></v-spacer> 
                        <v-btn dark icon class="ml-5" @click="dialog_item_add_levels=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <form @submit.prevent="CONFIRM_SUBMITTING_ITEM_STOCK_LEVELS(input_item_levels)">
                            <div class="ma-5" v-if="dialog_item_add_levels">  
                                <div> 
                                    <mbs-input-fields  
                                        v-model="input_item_levels"
                                        :field_items="DATA.FIELDS.item_stock_level"
                                        :select_items="SelectItems" 
                                        />
                                        <!-- <br>
                                    <hr>
                                    <br> -->
                                    <mbs-input-fields 
                                        v-model="input_item_levels"
                                        :field_items="DATA.FIELDS.stock_pricing"
                                        :select_items="SelectItems" 
                                        /> 
                                </div>
                                <div class=" mt-5">Make sure you fill above form before continuing and updating.</div>  
                                <v-layout row wrap class="ma-0 mt-3">
                                    <v-btn large color="primary">CANCEL</v-btn>
                                    <v-flex class="pa-0 ml-1" > 
                                        <v-btn width="100%" :loading="inputLoading" type="submit" large color="secondary">UPDATE STOCK LEVEL</v-btn>
                                    </v-flex>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import DB from '../../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = "MANAGE-SHIFT"  
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','type'], 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                dialog_outlet_add_levels:false,
                dialog_item_add_levels:false,
                selected_items:null,
                inputLoading:false,
                loadStockLevels:false,
                input:{},  
                input_pricing:{},  
                input_stock_levels:{},  
                input_outlet_levels:{},  
                input_item_levels:{},  
                DATA:DATA,   
                 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000)  
                this.INITIATE_STOCK_SETTINGS(this.StockSettings)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            }, 
            FULL_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("stock",1,true) 
                return thisLinks
            },
            /////////   

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
            }), 
            
            ItemsData(){
                let Items = this.Items
                let JoinedItems = this.JoinedItems 
                return JoinedItems?JoinedItems:Items
            }, 
            StockOutletsData(){
                return this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets
            }, 
            StockSettings(){
                let settings = this.CompanySettings 
                return settings?settings.stock_settings:null
            }, 
            StockOutletLevels(){
                let StockSettings = this.StockSettings 
                let stock_outlets = this.StockOutletsData 
                if(!StockSettings){return null}
                let obj = StockSettings?StockSettings.outlets:null
                let outlets = this.MBS.actions.OBJECT_ITEMS(obj)
                if(!outlets){return null}
                let joined =[]
                outlets.forEach(element => {
                    let outlet = stock_outlets?stock_outlets.find(o=>o.code==element.key):null
                    if (outlet) {
                       joined.push({
                            ...element,
                            ...outlet,
                       }) 
                    }
                    
                });
                console.log(joined,'oooo.....');
                return joined
            }, 
            StockItemLevels(){ 
                let items = this.ItemsData
                if(!items){return null}
                let stock_levels_item = items.filter(item=>item.stock_settings)
                return stock_levels_item
            }, 

            SelectItems(){ 
                let Items = this.ItemsData 
                let StockOutlets = this.StockOutletsData 

                console.log(StockOutlets,'StockOutlets...');
                return {
                    Items:Items,  
                    StockOutlets:StockOutlets,  
                }
            }, 
            IS_SAME_LEVELS(){
                return (level1,level2)=>{
                    console.log(level1,'level1...');
                    console.log(level2,'level2...');
                   if(!level1 || !level2){return false}
                   if (
                        this.toNumber(level1.full_level) === this.toNumber(level2.full_level) &&
                        this.toNumber(level1.ordering_remind_level) === this.toNumber(level2.ordering_remind_level) && 
                        this.toNumber(level1.stop_selling_level) === this.toNumber(level2.stop_selling_level)  
                    ) {
                     return true
                   }
                   return false
                }
            }, 
        },
        methods: {    
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            INITIATE_STOCK_SETTINGS(stock_settings){
                try {
                    if (stock_settings) {
                        this.loadStockLevels = true
                        setTimeout(() => {
                            this.loadStockLevels = false
                            this.input_pricing.retail_profit_margin = stock_settings.retail_profit_margin
                            this.input_pricing.wholesale_profit_margin = stock_settings.wholesale_profit_margin
                            this.input_pricing.retail_allowed_discount = stock_settings.retail_allowed_discount 
                            this.input_pricing.wholesale_allowed_discount = stock_settings.wholesale_allowed_discount 
                            
                            this.input_stock_levels.full_level = stock_settings.full_level?stock_settings.full_level:''
                            this.input_stock_levels.ordering_remind_level = stock_settings.ordering_remind_level?stock_settings.ordering_remind_level:''
                            this.input_stock_levels.stop_selling_level = stock_settings.stop_selling_level ?stock_settings.stop_selling_level:''
                        }, 50);
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIATE_STOCK_SETTINGS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            
            ADD_OUTLET_STOCK_LEVELS(){
                try {
                    this.dialog_outlet_add_levels = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ADD_ITEM_STOCK_LEVELS(){
                try {
                    this.dialog_item_add_levels = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            UPDATE_OUTLET_STOCK_LEVELS(item){
                try {
                    if (item) {
                        this.input_outlet_levels = item
                        this.dialog_outlet_add_levels = true
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            UPDATE_ITEM_STOCK_LEVELS(item){
                try {
                    if (item) {
                        console.log(item);
                        this.input_item_levels = {
                            ...item.stock_settings,
                            item_key:item.key
                        }
                        this.dialog_item_add_levels = true
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_ITEM_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CONFIRM_SUBMITTING_PRICING(input){
                try {  
                    if (input) {
                        let data = {
                            retail_profit_margin:input.retail_profit_margin,
                            wholesale_profit_margin:input.wholesale_profit_margin,
                            retail_allowed_discount:input.retail_allowed_discount, 
                            wholesale_allowed_discount:input.wholesale_allowed_discount, 
                        }
                        let mbs_text = this.MBS.text.item_action("Pricing","Update")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm making change on Company Pricing.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=SUBMIT-STOCK-LEVELS",
                                data:data 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SUBMITTING_PRICING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_SUBMITTING_STOCK_LEVELS(input){
                try {  
                    if (input) {
                        console.log(input);
                        let data = {
                            full_level:input.full_level,
                            ordering_remind_level:input.ordering_remind_level,
                            stop_selling_level:input.stop_selling_level, 
                        }
                        let mbs_text = this.MBS.text.item_action("Stock Level","Update")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm making change on Company Stock Levels.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=SUBMIT-STOCK-LEVELS",
                                data:data 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SUBMITTING_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_SUBMITTING_OUTLET_STOCK_LEVELS(input){
                try {
                    console.log(input,'CONFIRM_SUBMITTING_OUTLET_STOCK_LEVELS.....');
                    if (input) {
                        let data = {
                            full_level:input.full_level,
                            ordering_remind_level:input.ordering_remind_level,
                            stop_selling_level:input.stop_selling_level, 
                            stock_outlet_code:input.stock_outlet_code, 

                            retail_profit_margin:input.retail_profit_margin,
                            wholesale_profit_margin:input.wholesale_profit_margin,
                            retail_allowed_discount:input.retail_allowed_discount, 
                            wholesale_allowed_discount:input.wholesale_allowed_discount, 
                        }
                        let mbs_text = this.MBS.text.item_action("Stock Level & Pricing","Update")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm making change on Company Outlet Stock Levels and Pricing.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=SUBMIT-OUTLET-STOCK-LEVELS",
                                data:data 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SUBMITTING_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_SUBMITTING_ITEM_STOCK_LEVELS(input){
                try { 
                    if (input) {
                        let data = {
                            full_level:input.full_level,
                            ordering_remind_level:input.ordering_remind_level,
                            stop_selling_level:input.stop_selling_level, 

                            retail_profit_margin:input.retail_profit_margin,
                            wholesale_profit_margin:input.wholesale_profit_margin,
                            retail_allowed_discount:input.retail_allowed_discount, 
                            wholesale_allowed_discount:input.wholesale_allowed_discount, 

                            item_key:input.item_key, 
                        }
                        let mbs_text = this.MBS.text.item_action("Stock Level & Pricing","Update")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm making change on Company Item Stock Levels and Pricing.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=SUBMIT-ITEM-STOCK-LEVELS",
                                data:data 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SUBMITTING_ITEM_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            SUBMITTING_STOCK_LEVELS(data){
                try { 
                    if (data) { 
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_settings+"/stock_settings",true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Uploading..."
                        })
                        this.$store.dispatch("fi_update",{
                            action:DATA.ITEMS.COMPANY_SETTINGS.values,
                            path:path,
                            data:data,
                        }).then(res=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(res,'res.......'); 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(error,'error...-....');
                        }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMITTING_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            SUBMITTING_OUTLET_STOCK_LEVELS(data){
                try {
                    if (data) { 
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_settings+"/stock_settings/outlets/"+data.stock_outlet_code,true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Uploading..."
                        })
                        this.$store.dispatch("fi_update",{
                            action:DATA.ITEMS.COMPANY_SETTINGS.values,
                            path:path,
                            data:data,
                        }).then(res=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(res,'res.......'); 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(error,'error...-....');
                        }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMITTING_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMITTING_ITEM_STOCK_LEVELS(data){
                try {
                    if (data) { 
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items+data.item_key+"/stock_settings",true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Uploading..."
                        })
                        this.$store.dispatch("fi_update",{
                            action:DATA.ITEMS.ITEMS.values,
                            path:path,
                            data:data,
                        })
                        .then(res=>{
                           return  this.$store.dispatch('fi_tracker',{
                            company:this.selectedCompany,
                            names:[
                                {data_save:false,on:false,get:false,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                            ]
                           }) 
                        })
                        .then(res=>{
                            this.inputLoading = false
                            this.dialog_item_add_levels = false
                            this.MBS.actions.progressDialog() 
                            console.log(res,'res.......'); 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(error,'error...-....');
                        }) 
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMITTING_ITEM_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  

            CONFIRM_DELETE_OUTLET_STOCK_LEVELS(item){
                try { 
                    if (item) { 
                        let mbs_text = this.MBS.text.item_action("Stock Level","Delete")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm Deleting Company Outlet Stock Levels.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-OUTLET-STOCK-LEVELS",
                                data:item 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CONFIRM_DELETE_ITEM_STOCK_LEVELS(item){
                try { 
                    if (item) { 
                        console.log(item,'item..........');
                        let mbs_text = this.MBS.text.item_action("Item Stock Level","Delete")
                        this.MBS.actions.dialogConfirmPassword({
                            show:true,
                            fixed:true,
                            icon:"mdi-content-save-cog",
                            title:mbs_text.title,
                            text:mbs_text.text+' Enter your password to confirm Deleting Company Item Stock Levels.',
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=DELETE-ITEM-STOCK-LEVELS",
                                data:item 
                            }
                        })  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_DELETE_ITEM_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            DELETE_OUTLET_STOCK_LEVELS(data){
                try { 
                    if (data) { 
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_settings+"/stock_settings/outlets/"+data.stock_outlet_code,true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Deleting..."
                        })
                        this.$store.dispatch("fi_delete",{
                            action:DATA.ITEMS.COMPANY_SETTINGS.values,
                            path:path, 
                        }).then(res=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(res,'res.......'); 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(error,'error...-....');
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_OUTLET_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            DELETE_ITEM_STOCK_LEVELS(data){
                try { 
                    if (data) {  
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items+data.key+"/stock_settings",true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.MBS.actions.progressDialog({
                            show:true,
                            text:"Deleting..."
                        })
                        this.$store.dispatch("fi_delete",{
                            action:DATA.ITEMS.COMPANY_SETTINGS.values,
                            path:path, 
                        })
                        .then(res=>{
                           return  this.$store.dispatch('fi_tracker',{
                            company:this.selectedCompany,
                            names:[
                                {data_save:false,on:false,get:false,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                            ]
                           }) 
                        })
                        .then(res=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(res,'res.......'); 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_outlet_add_levels = false
                            this.MBS.actions.progressDialog()
                            console.log(error,'error...-....');
                        }) 
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ITEM_STOCK_LEVELS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
  
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=SUBMIT-STOCK-LEVELS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMITTING_STOCK_LEVELS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=SUBMIT-OUTLET-STOCK-LEVELS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMITTING_OUTLET_STOCK_LEVELS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=SUBMIT-ITEM-STOCK-LEVELS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMITTING_ITEM_STOCK_LEVELS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=DELETE-OUTLET-STOCK-LEVELS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.DELETE_OUTLET_STOCK_LEVELS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM-STOCK-LEVELS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.DELETE_ITEM_STOCK_LEVELS(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(value){   
                 
            }, 
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
            StockSettings(value){
                this.INITIATE_STOCK_SETTINGS(value) 
            } 
        },
    }
</script>
 
