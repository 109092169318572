 
<template> 
    <v-card tile height="100%" v-show="!ACCESS" v-if="!ACCESS">
        
        <!-- //LOADING  -->
        <v-layout id="about" 
            column v-if="ACCESS_LOADING" 
            justify-center align-center fill-height> 
            <v-progress-circular 
                :value="20"
                size="220"
                color="b3"
                indeterminate>   
                <div v-if="show">
                <!-- <icon-zambia-map 
                :class="MBS.data.animate.zoom.in" 
                :color="MBS.data.color.b3" class="darken-3" :type="1" size="120" />  -->
                </div>
            </v-progress-circular> 
        </v-layout> 
        <!-- //ACCESS DENIED -->
        <v-layout fill-height  v-else>
            <!-- <mbs-access-login v-if="!us"/>  -->
            <mbs-access-login v-if="false"/> 
            <v-layout v-else id="about"
                column fill-height 
                justify-center 
                align-center> 
                <mbs-toolbar @navOpen="navOpen =! navOpen"/> 
                <v-avatar
                    :style="'--size:1px; --color:'+MBS.data.color.b2+';'"
                    class="mbs-border mb-9"
                    size="100" >
                    <v-icon color="b2" size="50">lock</v-icon>
                </v-avatar>
                <h1 id="active-citizen" class="pb-3">ACCESS DENIED</h1> 
                <div class="font-italic font-weight-bold c2--text" v-if="route.name!='Home'">{{route.name}}</div>
                <div class="text-center  " v-if="route.name!='Home'">
                    <v-layout justify-center  >
                    <v-col lg="5" md="6" sm="8" cols="11">
                        You do not have permission to access this page. Contact System Administrator to get permissions or go to the home page and browse other pages 
                    </v-col> 
                    </v-layout>
                </div>
                <br>
                <v-btn v-if="route.name!='Home'" to="/" depressed width="150" color="primary">Home..</v-btn>
                <!-- <div v-if="selectedCompany">{{selectedCompany}}</div> -->
                <!-- <div v-if="CompanyUserGroups">{{CompanyUserGroups}}...</div> -->
            </v-layout>
        </v-layout> 
    </v-card>
</template> 
<script> 
    import DATA from '../../plugins/DATA'
    let PAGE_NAME = 'ACCESS_PAGE' 

    export default {
        props:['value','backgroundColor','items','time'],
        data(){
            return{
                show:true,   
                ACCESS_LOADING:true,
                ACCESS:this.value,
                navOpen:false,
            }
        },
        created(){ 
             
        },
        mounted(){   
            this.ACCESS_PAGE(100)
            this.MBS.actions.toolbar() 
            this.MBS.actions.nav()
        },
        computed:{
            route(){
                return this.$route
            },
            vs(){ 
                return this.$vuetify.breakpoint
            }, 
            us(){
                return this.$store.getters.getUser
            }, 
            ud(){
                return this.$store.getters.getUserDetails
            }, 
            upTime(){
                return this.$store.getters.getUpTime
            }, 
            loggedTime(){
                return this.$store.getters.getLoggedTime
            }, 
            loading(){  
                return this.$store.getters.getLoading
            },
            processes(){  
                return this.$store.getters.getProcesses
            }, 
            responses(){  
                return this.$store.getters.getResponses
            }, 
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },  
            CompanyUserGroups(){ 
                return this.$store.getters.getCompanyUserGroups
            },   
        },
        methods:{ 
            ACCESS_PAGE(time=500){
                try {
                    setTimeout(() => {
                        let us = this.us
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses
                        let route = this.route
                        let fullPath = route?route.fullPath:null
                        let access = this.MBS.actions.GET_ACCESS() 
                        
                        
                        if (this.MBS.actions.SIZE(ps)>0) {
                            let logging = ps.find(process=>{
                                return process.name == 'logging'
                            })
                            let gettingUserDetails = ps.find(process=>{
                                return process.name == 'getting/'+DATA.ITEMS.USER_DETAILS.values
                            })
                            let gettingUserGroups = ps.find(process=>{
                                return process.name == 'getting/'+DATA.ITEMS.USER_GROUPS.values
                            })
                            let gettingPermissions = ps.find(process=>{
                                return process.name == 'getting/'+DATA.ITEMS.PERMISSIONS.values
                            })

                            if (logging) {
                                this.ACCESS_LOADING = true
                                this.MBS.actions.progressToolbar({show:true})
                            }else{
                                
                            }if (gettingUserDetails) {
                                this.ACCESS_LOADING = true
                                this.MBS.actions.progressToolbar({show:true})
                            }else{
                                
                            } if (gettingUserGroups) {
                                this.ACCESS_LOADING = true
                                this.MBS.actions.progressToolbar({show:true})
                            }else{
                                
                            } if (gettingPermissions) {
                                this.ACCESS_LOADING = true
                                this.MBS.actions.progressToolbar({show:true})
                            }else{
                                this.ACCESS_LOADING = false 
                                this.MBS.actions.progressToolbar()
                            }  
                        }else{
                            this.ACCESS_LOADING = false
                            this.MBS.actions.progressToolbar()
                        } 

                        if (this.ACCESS_LOADING) { 
                        } else {
                        } 
                        
                        if (fullPath =="/") {
                            this.ACCESS=true
                        }else if (fullPath =="/signup") {
                            this.ACCESS=true
                        }else if (fullPath =="/recover-password") {
                            this.ACCESS=true
                        }else if (access) {
                            this.ACCESS=true
                        } else { 
                            this.ACCESS=false    
                        } 
                        // console.log(ps,111111111);
                        // console.log(access,111111111);
                        // console.log(this.ACCESS,'this.ACCESS');
                        // console.log(this.ACCESS_LOADING,'this.ACCESS_LOADING');

                    }, time); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ACCESS_PAGE',
                        page:PAGE_NAME, 
                    })
                }
            },
        },
        watch:{ 
            ACCESS(value){
                this.$emit('input',value)
                if (!value) {
                    this.MBS.actions.toolbar() 
                    this.MBS.actions.nav()
                }
            },
            us(user){
                this.ACCESS_PAGE(10) 
            },
            ud(user){
                this.ACCESS_PAGE(1) 
            },
            route(value){ 
                if (!value.hash) { 
                    // this.ACCESS = true
                    // this.ACCESS_LOADING = true  
                } 
                this.ACCESS_PAGE()
            },
            loading(value){ 
                this.ACCESS_PAGE()
            },
            processes(value){ 
                this.ACCESS_PAGE(1)
            },
            responses(value){ 
                this.ACCESS_PAGE(1)
            },
            selectedCompany(value){  
                this.ACCESS_PAGE(1)
            },
            CompanyUserGroups(value){  
                this.ACCESS_PAGE(1)
            },
        } 

    }
</script>
<style>
  
</style> 