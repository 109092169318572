<template>
  <v-layout height="600" class="">    
    <v-layout v-if="false"> 
        <mbs-page-spinner :show="true" />  
    </v-layout>     
    <v-container v-else grid-list-lg> 
        <v-card :min-height="vsh-250" outlined color="">
            <mbs-page-tab 
                :items="OFFICE_LINKS"/> 
            <v-layout row wrap class="px-4 py-5"> 
                <!-- //others -->
                <v-flex xs6 sm4 lg3 >
                    <v-hover >
                        <template v-slot="{ hover  }">
                            <v-card  hover @click.prevent ="ADD_MONEY_ACCOUNT()"
                                rounded="xl"  :color="'secondary lighten-1'"  
                                height="100%" class="pa-2">   
                                <v-layout column fill-height class="ma-0">
                                    <v-spacer></v-spacer>
                                    <v-responsive  aspect-ratio="1.8"  >
                                        <v-layout column fill-height align-center justify-center>
                                            <v-icon color="white">mdi-plus-thick </v-icon>
                                            <div class="font-weight-bold white--text text--darken-1 not-f7 text-center">Create New Money Account</div>
                                        </v-layout>
                                    </v-responsive>    
                                </v-layout>
                            </v-card> 
                        </template>
                    </v-hover>
                </v-flex>
                <v-flex xs6 sm4 lg3 v-for="(link, index) in MY_LINKS" :key="index">
                    <v-hover>
                        <template v-slot="{ hover  }">
                            <v-card  
                                @click="MBS.actions.go(link.link)"
                                :elevation="hover ?12:''"
                                hover  rounded="lg"
                                height="100%"> 
                                <div v-if="MBS.actions.TEXT_UP(link.name) === MBS.actions.TEXT_UP('My profile')">
                                    <v-img v-if="ud?ud.photoURL:false" 
                                        aspect-ratio="1.8" 
                                        :src="ud.photoURL"
                                        lazy-src="https://picsum.photos/10/6?image=3"  >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                    /> 
                                            </v-row>
                                        </template>
                                    </v-img>
                                        
                                    <v-responsive  v-else   
                                        class="primary darken-1"
                                        aspect-ratio="1.8" > 
                                        <v-layout fill-height justify-center align-center>
                                            <v-avatar
                                                size="80"
                                                color="primary darken-2" > 
                                                <span class="white--text headline">
                                                    {{us.displayName ? us.displayName[0] : ""}}
                                                </span>  
                                            </v-avatar>
                                        </v-layout>
                                    </v-responsive> 
                                </div> 

                                <!--Not my profile--> 
                                <v-responsive v-else aspect-ratio="1.5"  >
                                    <v-card height="100%" :color="link.image_background_color" tile>
                                        <v-layout class="ma-0" align-center justify-center fill-height > 
                                            <v-card color="transparent" height="100%" :width="link.image_size?link.image_size+'%':'50%'" tile flat>
                                                <v-menu    offset-y>
                                                    <template v-slot:activator="{ on }">
                                                        <div>
                                                            <v-layout  class="py-5" > 
                                                                <v-spacer></v-spacer> 
                                                                <v-btn 
                                                                    dark
                                                                    icon
                                                                    v-on="on" >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn> 
                                                            </v-layout> 
                                                        </div>
                                                    </template> 
                                                    <v-list v-if="true"> 
                                                        <v-list-item @click="VIEW_ACCOUNT(link)">
                                                            <v-list-item-action><v-icon>mdi-eye</v-icon></v-list-item-action>
                                                            <v-list-item-title>View Account</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="EDIT_ACCOUNT(link)">
                                                            <v-list-item-action><v-icon>edit</v-icon></v-list-item-action>
                                                            <v-list-item-title>Edit Account</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="ARCHIVE_ACCOUNT(link)">
                                                            <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                            <v-list-item-title>Archive Account</v-list-item-title>
                                                        </v-list-item>
                                                        <!-- <v-list-item @click="DELETE_ACCOUNT(link)">
                                                            <v-list-item-action><v-icon>delete</v-icon></v-list-item-action>
                                                            <v-list-item-title>Delete Account</v-list-item-title>
                                                        </v-list-item> -->
                                                         
                                                    </v-list> 
                                                </v-menu>  
                                                <v-img  v-if="false"  
                                                    :src="link.image?link.image:MBS.data.noImg" >  
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                            indeterminate
                                                            color="grey lighten-5"
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>  
                                                <!-- <v-img  v-else   
                                                    class="  " 
                                                    aspect-ratio="1.5" > 
                                                    <v-layout column fill-height  >
                                                        <v-card-title class="ma-0 pa-0">
                                                            <v-spacer></v-spacer> 
                                                            <v-btn 
                                                                dark @click="addImage()" 
                                                                icon class="">
                                                                <v-icon>edit</v-icon>
                                                            </v-btn> 
                                                             <v-btn 
                                                                dark @click="addImage()" 
                                                                icon class="">
                                                                <v-icon>delete</v-icon>
                                                            </v-btn> 
                                                        </v-card-title>   
                                                    </v-layout>
                                                </v-img>  -->
                                            </v-card>
                                        </v-layout>  
                                    </v-card>
                                </v-responsive>
                                <v-card-title >
                                    <v-layout class="ma-0" >
                                        <div class="headline" >{{link.name}}</div>
                                        <v-spacer></v-spacer> 
                                        <!-- <v-tooltip bottom  > 
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon >
                                                    <v-icon color="primary">info_outline</v-icon>
                                                </v-btn> 
                                            </template>
                                            <span>More Info</span>
                                        </v-tooltip>  -->
                                    </v-layout>
                                </v-card-title> 
                            </v-card> 
                        </template>
                    </v-hover>
                </v-flex>
            </v-layout> 
        </v-card>

        <div>
            <mbs-item-manager
                :show="false" 
                :name_items="NAME_ITEMS"
                :name_item="NAME_ITEM"
                :value_items="VALUE_ITEMS"
                :value_item="VALUE_ITEM"
                :field_items="DATA.FIELDS.money_accounts"   
                :path_item="MoneyAccountsPath"  
                />
                <!-- :select_items="SelectInputFieldsItems"    -->
        </div>
    </v-container>  
  </v-layout> 
</template>

<script>
import DATA from '../../../../../plugins/DATA' 
import {mapState} from "vuex"
let PAGE_NAME = DATA.ITEMS.MONEY_ACCOUNTS.values 
    export default {
        name:PAGE_NAME, 
        data() {
        return {
            PAGE_SHOW:false, 
            NAME_ITEMS:DATA.ITEMS.MONEY_ACCOUNTS.names,
            NAME_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.name,
            VALUE_ITEMS:DATA.ITEMS.MONEY_ACCOUNTS.values,
            VALUE_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.value,  
            DATA:DATA, 
        }
        },
        mounted() {
            this.MBS.actions.header({
                show:true,
                type:'subToolbar', 
                
            })
            this.MBS.actions.subToolbar({
                show:true,
                height:70,
                flat:false,
                color:'primary',
                textColor:'white', 
                side:'right'
            }) 
            this.FEEDBACKS(500) 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                MoneyAccounts: state=> state.items[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                JoinedMoneyAccounts: state=> state.join[(DATA.ITEMS.MONEY_ACCOUNTS.values).toUpperCase()],
                DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
                DefaultMoneyAccount: state=> state.app[(DATA.ITEMS.DEFAULT_MONEY_ACCOUNTS.values).toUpperCase()],
            }),  
            MoneyAccountsData(){
                let items = []
                let DefaultMoneyAccount = this.DefaultMoneyAccount 
                let MoneyAccounts = this.JoinedMoneyAccounts?this.JoinedMoneyAccounts:this.MoneyAccounts
                if(DefaultMoneyAccount){
                    items = [...DefaultMoneyAccount]
                }
                if(MoneyAccounts){
                    items= [
                        ...items,
                        ...MoneyAccounts
                    ]
                }
                return items
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
             
            /////////
            MY_LINKS(){  
                let accounts = this.MoneyAccountsData 
                if(!accounts){return null}
                let allLinks = []
                accounts.forEach(element => {
                    allLinks.push({
                        ...element,
                        name:element.name,
                        link:this.MBS.actions.COMPANY_LINK("/office/accounts/money-accounts/"+element.code,true),
                        icon:'link',
                        // image:'/future_tours/svg/SVG/my_parcel.svg',
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            },
            OFFICE_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("accounts",2,true) 
                return thisLinks
            },
            MoneyAccountsPath(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.money_accounts,true) 
                return path
            }, 
        },
        methods: { 
            clicked(){},
            ADD_MONEY_ACCOUNT(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.MONEY_ACCOUNTS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_MONEY_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            EDIT_ACCOUNT(item){
                try {     
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"edit_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ARCHIVE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"archive_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ARCHIVE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            DELETE_ACCOUNT(item){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:this.NAME_ITEM,
                        action:"delete_item",
                        item:item
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DELETE_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            VIEW_ACCOUNT(item){
                try {    
                    this.MBS.actions.go(item.link)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'VIEW_ACCOUNT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            //LOAD
            EXIT_PAGE(){
                if (!this.us) {
                    this.MBS.actions.go("/")
                }else{
                    this.PAGE_SHOW = true
                }
            },
            FEEDBACKS(time=400){ 
                setTimeout(()=>{
                    let us = this.us 
                    let ps = this.processes
                    let ld = this.loading
                    let res = this.responses 

                    //CHECK USER STATE 
                    if (ps) {
                        let logging = ps.find(process=>{
                            return process.name == 'logging'
                        }) 

                        if (logging) {
                            this.PAGE_SHOW =false
                        }else{
                            this.EXIT_PAGE()
                        }     
                    }else{
                       this.EXIT_PAGE()
                    }

                     
                },time)
            }
        },
        watch: { 
            us(v){
                this.FEEDBACKS()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>
 
