<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MY_LINKS"/> 
                        <mbs-item-manager
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :filter_date="true"
                            :filter_date_value="'local_created_at'"
                            :filter_default_days="'90'"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :header_items="HEADER_ITEMS" 
                            :data_items="Invoices"  
                            :is_joined="false"   
                            :path_item="QUOTATIONS_AND_INVOICES_PATH"   
                            :total_item_values="TOTAL_ITEM_VALUES"    
                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                            @SELECT_ITEM_ACTION="ITEM_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
        <div>
            <!-- GIVEN AMOUNT -->
            <div v-if="dialog_pay_bill">
                <v-dialog 
                    v-model="dialog_pay_bill"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg">
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text">Receive Invoice Payment</div> 
                            <v-spacer></v-spacer>
                            <v-btn icon  dark @click="dialog_pay_bill=false"><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text v-if="selected_item">
                            <br>
                            <v-list v-if="selected_item" dense class="mb-5" >
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Customer</v-list-item-title>
                                        <v-list-item-subtitle>{{selected_item.customer_name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>Payee</v-list-item-title>
                                        <v-list-item-subtitle>{{selected_item.payee_name}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>Amount</v-list-item-title>
                                        <v-list-item-subtitle>{{selected_item.amount_}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>Paid</v-list-item-title>
                                        <v-list-item-subtitle>{{selected_item.total_paid_}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-title>Balance</v-list-item-title>
                                        <v-list-item-subtitle>{{selected_item.balance_}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>  
                            <v-divider class="mx-2 mb-5"></v-divider>
                            <v-layout justify-center align-center v-if="toNumber(input.amount)>toNumber(selected_item.balance)">
                                <v-card class="text-center px-5 py-2" outlined>
                                    <div class="font-weight-bold">Give Change</div>
                                    <div>MKW {{MBS.actions.money(toNumber(input.amount) - toNumber(selected_item.balance))}}</div> 
                                </v-card>
                            </v-layout>
                            <form @submit.prevent="MAKE_PAYMENT(selected_item,input.amount,paying_option)">
                                <v-layout row wrap class="ma-0 ">
                                    <v-flex xs12>
                                        <div class="ml-2">Payment Option</div>
                                        <v-autocomplete required
                                            v-model="paying_option"
                                            filled  
                                            :items="PaymentMethodsData"
                                            item-value="code"
                                            item-text="name"
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-autocomplete> 
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="ml-2">Pay amount</div>
                                        <v-text-field required
                                            v-model="input.amount"
                                            filled  
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field> 
                                    </v-flex>
                                    <v-flex xs6>
                                        <div class="ml-2">Balance</div>
                                        <v-text-field   
                                            :value="MBS.actions.money(selected_item.balance-toNumber(input.amount))"
                                            filled   disabled
                                            v-mask="currencyMask"
                                            hint="0.00" class="mx-2"
                                        ></v-text-field>  
                                    </v-flex> 
                                </v-layout> 
                                <v-layout class="ma-0">
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="toNumber(input.amount)>toNumber(selected_item.balance)" :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Receive All</v-btn>
                                    
                                    <v-btn v-else :loading="inputLoading" color="primary" large type="submit" class="mt-5 mb-2 mr-1">Receive Payment</v-btn>
                                    
                                    <v-btn v-if="toNumber(input.amount)>toNumber(selected_item.balance)"  :loading="inputLoading" color="secondary" large type="submit" class="mt-5 mb-2 mr-1">Receive & Give Change</v-btn>
                                </v-layout>
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>  
            </div>
            <div v-if="autoPrint">
                <mbs-auto-print 
                    :show="true"  
                    :type="typePrint"  
                    :com_printer="com_printer"  
                    :data="itemPrint" />  
            </div>
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.BILLS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.INVOICES.names,
                NAME_ITEM:DATA.ITEMS.INVOICES.name,
                VALUE_ITEMS:DATA.ITEMS.INVOICES.values,
                VALUE_ITEM:DATA.ITEMS.INVOICES.value,  
                dialog_pay_bill:false,
                input:{},
                input_amount:null,
                input_balance:null,
                inputLoading:null,
                selected_item:null,
                autoPrint:false, 
                   
                table_actions:[ 
                    //{type:"btn",action:"add_invoice",color:"secondary", outlined:false,  text:"Add Received Invoice/Bill"},  
                    // {type:"btn",action:"manage_invoices",color:"secondary", outlined:false,  text:"Manage Invoice/Bill "}, 
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"}, 
                ],
                
                TOTAL_ITEM_VALUES:[  
                    {name:'Total Amount',value:'amount',show_value:'amount_',money:true},
                    {name:'Total Paid',value:'total_paid',show_value:'total_paid_',money:true},
                    {name:'Total Balance',value:'balance',show_value:'balance_',money:true},
                ],
                paying_option:0,
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                SalesOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],  
            }), 
            PaymentMethodsData(){
                let items = this.JoinedPaymentMethods?this.JoinedPaymentMethods:this.PaymentMethods
                let options = [
                    {name:"Cash",code:0}
                ]
                if (items) {
                    options.push(...items)
                }
                return options
            },
            
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            /////////
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.payments,true) 
                return path
            },
            MY_LINKS(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("office",1,true) 
                return thisLinks
            }, 
            QUOTATIONS_AND_INVOICES_PATH(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                return path
            }, 
            TABLE_TABS(){
                return [
                    {
                        name:"Unpaid Invoices",show_if:(this_,item)=>item.balance>0,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                        }
                    },
                    {
                        name:"Paid Invoices",show_if:(this_,item)=>item.balance<=0,
                        indicator:{
                            color:'primary',
                            value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                        }
                    }, 
                ]
            },
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon',color:"secondary",size:35,show:true},   
                    {id:0,name:"Number",value:'no',show:false}, 
                    {id:0,name:"date",value:'local_created_at_',show:true},   
                    {id:0,name:"time",value:'created_at_time',show:false},   
                    {id:0,name:"Customer",value:"customer_name",show:true},     
                    {id:0,name:"Type",value:'order_type_name',show:true},     
                    {id:0,name:"payee",value:'receivable_name',show:true},     
                    {id:0,name:"code",align:"center",show:true},          
                    {id:0,name:"Due",value:"due_date",show:true},     
                    {id:0,name:"items",value:'total_quantity_',show:false},   
                    {id:0,name:"Amount",value:'amount_',align:'right',show:true},   
                    {id:0,name:"Paid",value:'total_paid_',align:'right',show:true},   
                    {id:0,name:"Balance",value:'balance_',align:'right',show:true},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Receive Payment",action:"pay_bill",icon:"mdi-cash",show_if:item=>item.balance>0}, 
                        {name:"View Invoice",action:"view_invoice",icon:"mdi-eye"}, 
                        {name:"View Statement",action:"view_statement",icon:"mdi-eye"}, 
                        // {name:"Archive Invoice",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },  
            SalesOrdersData(){
                let SaleOrder = this.SalesOrders
                console.log(SaleOrder,'Sales...');
                let JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrder
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            Invoices(){
                let orders = this.SalesOrdersData
                console.log(orders);
                if(!orders){return null}
                let filtered_items = orders.filter(order=>{
                    return !order.archive && order.account_type == 'on_account'
                })
                return filtered_items
            }   
        },
        methods: { 
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } if (action == "manage_invoices") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ITEM_ACTION(action,item){
                try {
                    if (action == "pay_bill") {  
                        this.input_amount = '' 
                        this.input = {}
                        this.selected_item = item

                        this.dialog_pay_bill = true
                    }else if (action == "view_invoice") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-invoice/"+receipt_key)
                    }else if (action == "view_statement") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-invoice-statement/"+receipt_key)
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ITEM_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            MAKE_PAYMENT(item,amount,payment_option){
                try {
                    let localCashup = this.LocalCashup           
                    let cashup_key = localCashup?localCashup.key:null
                    let day_shift_key = localCashup?localCashup.day_shift_key:null
                    console.log(localCashup,'localCashup...');
                    if (!cashup_key||!day_shift_key) {
                        let mbs_text = this.MBS.text.open_register_before_receive_payment("")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:"=GO",
                                to:this.MBS.actions.COMPANY_LINK("/sale",true)
                            }
                        }) 
                        return
                    }

                    amount = this.toNumber(amount)
                    let transaction_key = item?item.key:null
                    let transaction_type = item?item.order_type:null
                    let payment_details={
                        amount:amount,
                        transaction_key:transaction_key,
                        transaction_type:transaction_type,
                        transaction_type:transaction_type,
                        received_payment:true,
                        cashup_key:cashup_key,
                        day_shift_key:day_shift_key,
                    }
                    if (payment_option) {
                        payment_details.payment_option_code = payment_option
                    }

                    if (!transaction_key || !amount) {
                        console.log(item,amount,'payload required.......');
                    }else{
                        let mbs_text = this.MBS.text.item_action("Payment","Receive")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RECEIVE-PAYMENT",
                                data:payment_details
                            }
                        }) 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'MAKE_PAYMENT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            async RECEIPT_PRINTER(payload){
                let response = {
                    from:'RECEIPT_PRINTER',
                    page:PAGE_NAME,
                    payload:payload
                }
                return new Promise((resolve,reject)=>{
                    try { 
                        let printer = this.CurrentReceiptPrinter
                        let duplicate = payload?payload.duplicate:false
                        let order = payload?payload.order:null
                        let submit_order = payload?payload.submit_order:null
                        // if (printer) {
                        if (false) {
                            let com_port = printer.com_port
                            let baud_rate = printer.baud_rate
                            let url = printer.url
                            let params = {
                                action:"sales",
                                SaleOrder:order,
                                duplicate:duplicate,
                                com_port:com_port,
                                baud_rate:baud_rate,
                                url:url,
                            }  
                            if (!submit_order&&!duplicate) {
                                resolve({success:false})
                                return
                            }
                            this.MBS.actions.FISCAL_PRINT(params).then(res=>{
                                let data = res?res.data:null
                                let allReceiptCount = data?data.allReceiptCount:null
                                let fiscalReceiptCount = data?data.fiscalReceiptCount:null
                                let order_key =allReceiptCount?allReceiptCount+"/"+fiscalReceiptCount:null
                                if (order_key && order) {
                                    order.order_key = order_key
                                } 
                                resolve({
                                    success:true,
                                    fiscalReceiptCount:fiscalReceiptCount,
                                    allReceiptCount:allReceiptCount,
                                    order_key:order_key,
                                    order:order,
                                })
                            }).catch(error=>{
                                let message = error.message 
                                console.log(error,'error...ZZZ ');
                                if (message) {
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"PRINTING ERROR",
                                        text:message,
                                        btnYes:"OK", 
                                        action:{
                                            // code:PAGE_NAME+"=CLEAR-SALES"
                                        }
                                    }) 
                                } else {
                                    message = 'Kipcount Connecter is offline. Please make to start the connecter and try again.'
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:"PRINTING ERROR",
                                        text:message,
                                        btnYes:"OK", 
                                        action:{
                                            // code:PAGE_NAME+"=CLEAR-SALES"
                                        }
                                    }) 
                                }
                                response = {
                                    ...response,
                                    message:message,
                                    error:error
                                }
                                reject(response)
                            }) 
                        } else {
                            if (submit_order) {
                                resolve({success:true,order:order}) 
                            } else {
                                this.autoPrint = false
                                setTimeout(() => {
                                    this.itemPrint = order
                                    this.typePrint = 'payment'
                                    this.autoPrint = true  
                                }, 5); 
                                this.com_printer = true 
                                resolve({success:true}) 
                            }
                        } 
                        //upload sales
                        this.MBS.events.$emit("SUBMIT_SALES")
                    } catch (error) {
                        response = {
                            ...response,
                            error:error
                        }
                        this.MBS.actions.error(response) 
                        reject(response)
                    }  
                }) 
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.DAYS_SHIFTS_SALES.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail("Sales Transactions","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=RECEIVE-PAYMENT') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.inputLoading = true 
                                    this.MBS.actions.progressDialog({
                                        show:true,
                                        fixed:false,
                                        title:"Updating"
                                    }) 
                                    this.$store.dispatch("fi_add",{
                                        action:this.VALUE_ITEM,
                                        path:this.PATH_ITEM,
                                        data:action.data,
                                        us:this.us
                                    }).then(res=>{
                                        this.inputLoading = false
                                        this.dialog_pay_bill = false 
                                        this.MBS.actions.progressDialog() 
                                        this.input = {}
                                        let key = res?res.data?res.data.key:null:null 
                                        if (key) {
                                            this.RECEIPT_PRINTER({order:{key:key,reload:true}})
                                        }
                                        let mbs_text = this.MBS.text.item_action_success("Payment","Receiving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    }).catch(error=>{
                                        console.log(error,'error......');
                                        this.inputLoading = false
                                        this.MBS.actions.progressDialog() 
                                        let mbs_text = this.MBS.text.item_action_fail("Payment","Receiving")
                                        this.MBS.actions.dialog({
                                            show:true,
                                            fixed:true,
                                            title:mbs_text.title,
                                            text:mbs_text.text,
                                            btnYes:mbs_text.btnYes,
                                            btnNo:mbs_text.btnNo, 
                                        })
                                    })
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            }, 
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
